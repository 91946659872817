import styled from 'styled-components'

interface IStyledHrProps {
  color?: string
  width?: number
  height?: number
  hideOnTablet?: boolean
  hideOnDesktop?: boolean
}
const StyledHr = styled.div<IStyledHrProps>`
  height: ${props => props.height || 1}px;
  width: 100%;
  ${props => props.width && `width: ${props.width}px;`}
  border-bottom: ${props => props.height || 1}px solid ${props =>
  props.theme.colors[props.color || 'grey3']};
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    ${props => props.hideOnTablet && 'display: none;'}
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    ${props => props.hideOnDesktop && 'display: none;'}
  }
`

export default StyledHr
