import SVG from './SVG'
import * as T from './types'

const verticalViewBox = '0 0 10 6'
const horizontalViewBox = '0 0 6 10'

const Arrow = (props: T.ISVGProps) => (
  <SVG
    {...props}
    d="M9.14019 4.93995C9.23484 5.03383 9.28809 5.16163 9.28809 5.29495C9.28809 5.42827 9.23484 5.55607 9.14019 5.64995L8.94019 5.84995C8.84611 5.94761 8.71577 6.00191 8.58019 5.99995L1.42019 5.99995C1.2846 6.00191 1.15426 5.94761 1.06019 5.84995L0.860187 5.64995C0.765531 5.55606 0.712288 5.42827 0.712288 5.29495C0.712288 5.16163 0.765531 5.03383 0.860187 4.93995L4.65019 1.14995C4.74092 1.05327 4.8676 0.998429 5.00019 0.998429C5.13277 0.998429 5.25945 1.05327 5.35019 1.14995L9.14019 4.93995Z"
  />
)

const UpFilledArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.none}
    {...props}
    viewBox={verticalViewBox}
  />
)

const RightFilledArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.right}
    {...props}
    viewBox={horizontalViewBox}
  />
)

const DownFilledArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.flip}
    {...props}
    viewBox={verticalViewBox}
  />
)

const LeftFilledArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.left}
    {...props}
    viewBox={horizontalViewBox}
  />
)

export { UpFilledArrow, RightFilledArrow, DownFilledArrow, LeftFilledArrow }
