import fetch from 'isomorphic-unfetch'

const Sitemap = () => null

export const getServerSideProps = async ({ res }: any) => {
  const response = await fetch(`${process.env.API_URL}/sitemap`)
  const xml = await response.text()

  res.setHeader('Content-Type', 'text/xml')
  res.write(xml)
  res.end()

  return { props: {} }
}

export default Sitemap
