import { ParsedUrlQuery } from 'querystring'

// dependency of next/router
import { getAgreementFromListing } from 'utils/agreementHelpers'
import { formatAddress, getAgreementOverride } from 'utils/listingHelpers'

import * as T from 'types'

declare global {
  interface Window {
    Intercom: any
  }
}

interface IntercomListingParams {
  ClosingDate?: Date
  ClosingDateMinus3?: Date
  ClosingDateMinus9?: Date
  ListingAddress?: string
  ListingLink?: string
  DashboardLink?: string
  ExpirationDate?: Date
  ServiceLevel?: string
  ListingStatus?: string
  AllListingsLink?: string
  mlsLiveInNames?: string
  mlsName?: string
  mlsName2?: string
  mlsName3?: string
  EscrowAgentName?: string
  EscrowAgentEmail?: string
  TitleAgencyContactName?: string
  TitleAgencyContactEmail?: string
}

interface ISendIntercomData {
  (args: {
    type: string
    user?: T.IUser
    listing?: T.IListing
    contractDetails?: T.IContractDetails
    listingDocuments?: T.IListingDocument[]
    queryParams?: ParsedUrlQuery
  }): void
}
export const sendIntercomData: ISendIntercomData = ({
  type,
  user,
  listing,
  contractDetails,
  listingDocuments = [],
  queryParams,
}) => {
  if (typeof window === 'undefined' || !window.Intercom) return

  const additionalParamsFromQueryString = queryParams || {}

  if (user) {
    const listingParams: IntercomListingParams = {}

    if (listing) {
      const closingDate = contractDetails?.closingDate

      if (closingDate) {
        const date = new Date(closingDate)
        listingParams.ClosingDate = date
        listingParams.ClosingDateMinus3 = new Date(date.setDate(date.getDate() - 3))
        listingParams.ClosingDateMinus9 = new Date(date.setDate(date.getDate() - 9))
      }

      listingParams.ListingAddress = formatAddress(listing)
      listingParams.ListingLink = `${process.env.ADMIN_URL}/listing/${listing._id}`
      listingParams.DashboardLink = `${process.env.WEBSITE_URL}/listing/${listing._id}/dashboard`
      listingParams.AllListingsLink = `${process.env.ADMIN_URL}/listings?sellerId=${listing.userId._id}`

      const agreementOverrideDocument = getAgreementOverride(listingDocuments)
      const agreement = getAgreementFromListing(listing)

      const expirationDate
        = agreement?.dateOfExpiration || agreementOverrideDocument?.dateOfExpiration

      if (expirationDate) {
        const date = new Date(expirationDate)
        listingParams.ExpirationDate = date
      }

      let serviceLevel = listing.pricingPackage?.name

      if (serviceLevel === 'Basic' && listing.hasTransactionManagement) {
        serviceLevel += '+TM'
      }

      listingParams.ServiceLevel = serviceLevel
      listingParams.ListingStatus = listing.listingStatus.name

      const mlsList = listing.mlsList?.sort((a, b) => Number(b.selected) - Number(a.selected))
      const mlsNames = mlsList.map(mlsListItem => mlsListItem.mlsLiveIn)

      listingParams.mlsLiveInNames = mlsNames.join('; ')
      const mlsName = mlsList?.[0]?.mlsLiveIn

      if (mlsName) {
        listingParams.mlsName = mlsName
      }

      const mlsName2 = mlsList?.[1]?.mlsLiveIn

      if (mlsName2) {
        listingParams.mlsName2 = mlsName2
      }

      const mlsName3 = mlsList?.[2]?.mlsLiveIn

      if (mlsName3) {
        listingParams.mlsName3 = mlsName3
      }

      listingParams.EscrowAgentName = (contractDetails?.escrowAgentName ?? '') as string
      listingParams.EscrowAgentEmail = (contractDetails?.escrowAgentEmail ?? '') as string
      listingParams.TitleAgencyContactName = (contractDetails?.titleAgencyContactName
        ?? '') as string
      listingParams.TitleAgencyContactEmail = (contractDetails?.titleAgencyContactEmail
        ?? '') as string
    }

    window.Intercom(type, {
      app_id: process.env.INTERCOM_APP_ID,
      name: user.name,
      user_id: user._id,
      user_hash: user.intercomHash,
      email: user.email,
      created_at: user.created,
      ...listingParams,
      ...additionalParamsFromQueryString,
    })
  } else if (type === 'boot') {
    window.Intercom(type, {
      app_id: process.env.INTERCOM_APP_ID,
      ...additionalParamsFromQueryString,
    })
  } else window.Intercom(type, { ...additionalParamsFromQueryString })
}

export const sendIntercomEvent = (user: T.IUser) => {
  if (typeof window === 'undefined' || !window.Intercom) return

  window.Intercom('trackEvent', 'custom-package', { user })
}

export const sendIntercomNewListingEvent = (
  newListing: T.IListing,
  queryParams: ParsedUrlQuery,
) => {
  sendIntercomData({
    type: 'boot',
    user: newListing?.userId,
    listing: newListing,
    queryParams,
  })
}

// NB: This was used solely on the Pricing Packages page of old site when user clicked on a
// 'Contact Us' tooltip about setting a custom price for a listing. Feature doesn't exist on
// current site, but keeping code here for when it's needed in future for a different context.
export const prepopulateIntercomMessage = (message: string | undefined) => {
  if (typeof window === 'undefined' || !window.Intercom) return

  window.Intercom(
    'showNewMessage',
    message || "I'm interested in hearing more about custom packages! What can you tell me?",
  )
}

export const closeIntercomChatWindow = () => {
  if (typeof window === 'undefined' || !window.Intercom) return

  window.Intercom('hide')
}

export const shutdownIntercom = () => {
  if (typeof window === 'undefined' || !window.Intercom) return

  window.Intercom('shutdown')
}

export const hideIntercomLauncher = (hide: boolean) => {
  if (typeof window === 'undefined' || !window.Intercom) return

  window.Intercom('update', {
    hide_default_launcher: hide,
  })
}
