import { responseInterface } from 'swr'

import { IServerError } from 'types'

const INTERNAL_SERVER_ERR = 'Internal Server Error'

const getErrorMessage = <A>(
  response: responseInterface<A, any>,
  dataType?: string,
  plural = false,
) => {
  const serverError = response.data as IServerError
  const dataName = dataType || 'data'

  const adj = plural ? 'these' : 'this'

  let error = ''

  if (serverError?.status === '403') {
    error = 'You have reached a page that only the account holder has access to.'
  }

  if (!error) error = serverError?.message ?? ''

  if (!error && !!response.error) error = `There was an issue loading this ${adj} ${dataName}.`

  if (error === INTERNAL_SERVER_ERR) error = `There was an issue loading this ${adj} ${dataName}.`

  return error
}

export default getErrorMessage
