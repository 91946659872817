import SVG from './SVG'
import * as T from './types'

const Plus = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 14 14"
    d="M14 6.5V7.5C14 7.77614 13.7761 8 13.5 8H8V13.5C8 13.7761 7.77614 14 7.5 14H6.5C6.22386 14 6 13.7761 6 13.5V8H0.5C0.223858 8 0 7.77614 0 7.5V6.5C0 6.22386 0.223858 6 0.5 6H6V0.5C6 0.223858 6.22386 0 6.5 0H7.5C7.77614 0 8 0.223858 8 0.5V6H13.5C13.7761 6 14 6.22386 14 6.5Z"
  />
)

export default Plus
