/* eslint-disable import/extensions */
import * as Sentry from '@sentry/node'
import Cookies from 'js-cookie'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import 'swiper/css/swiper.css'
import 'themes/cssOverrides/hellosignOverrides.css'
import 'themes/cssOverrides/reactCalendarOverrides.css'
import 'themes/cssOverrides/stripeElementsOverrides.css'
import 'themes/cssOverrides/swiperOverrides.css'

import ABVariation from 'components/ABVariation'
import Layout from 'components/Layout'
import Providers from 'components/Providers'

import { page } from 'services/analytics'
import { updateLastLogin } from 'services/auth'
import { sendIntercomData } from 'services/intercom'
import { useDocuments } from 'services/swr/documents/useDocuments'
import { useUserListingsFullyPopulated } from 'services/swr/listings/useUserListingsFullyPopulated'
import { useContractDetails } from 'services/swr/useContractDetails'
import { useUser } from 'services/swr/useUser'
import { getABTestCookieValues, reconcileVwoData } from 'services/vwo'

import useRouterScroll from 'utils/useRouterScroll'

// CSS that can't be written as styled-components or inline/prop styles
import '../../public/global.css'
import Health from './health'
import Sitemap from './sitemap.xml'

// Sentry
Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
  release: 'homelister@0.0.1',
})

Router.events.on('routeChangeComplete', page)

const App = ({ Component, pageProps, router, err }: AppProps & { err: any }) => {
  // manage our scroll behavior when the route changes
  useRouterScroll({ router, behavior: 'auto' })

  const { user } = useUser()
  const { listings } = useUserListingsFullyPopulated()

  // Intercom
  const [firstLoad, setFirstLoad] = useState(true)

  const newestListing = listings?.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  )[0]

  const { listingDocuments } = useDocuments(newestListing?._id)
  const { contractDetails } = useContractDetails(newestListing?._id)

  useEffect(() => {
    if (!firstLoad) return

    const hasToken = !!Cookies.get('token')

    if (!user && !hasToken) {
      sendIntercomData({ type: 'boot', queryParams: router.query })
      setFirstLoad(false)
    }

    if (!user && hasToken) return // still waiting on user to load, effect will rerun when ready

    if (!user || !newestListing || !contractDetails) return // we don't have sufficient data to send yet

    if (user && user._id && hasToken) {
      sendIntercomData({
        type: 'boot',
        user,
        listing: newestListing,
        contractDetails,
        listingDocuments,
        queryParams: router.query,
      })
      updateLastLogin(user._id)
      setFirstLoad(false)
    }
  }, [user, newestListing])

  useEffect(() => {
    if (!router || firstLoad || process.env.NODE_ENV !== 'production') return

    if (!user) return sendIntercomData({ type: 'update' })

    sendIntercomData({ type: 'update', user, queryParams: router.query })
  }, [router.pathname])

  // A/B Testing Initialization - make sure we're using the persisted vwoUserId if it exists and
  // persisting it in the case it hasn't been persisted yet
  useEffect(() => {
    const cookieValues = getABTestCookieValues()
    reconcileVwoData(user, cookieValues)
  }, [user, router.pathname])

  if (router.pathname === '/sitemap.xml') return <Sitemap />

  if (router.pathname === '/health') return <Health />

  return (
    <Providers>
      <>
        <Head>
          <title>Homelister</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
        </Head>
        <Layout>
          {/* DO NOT REMOVE */}
          {/* @FIXME: Not currently used for VWO testings (our contract expired), but
            this subtly delays rendering and useWindowSize will be undefined on first
            render without it. All the locations in the app assume it will be
            defined on first render of the component */}
          <ABVariation>
            <Component
              {...pageProps}
              err={err}
            />
          </ABVariation>
        </Layout>
      </>
    </Providers>
  )
}

export default App
