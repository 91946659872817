import React, { ReactNode, createContext, useReducer } from 'react'

enum actions {
  FOCUS_EVENT = 'FOCUS_EVENT',
  ADD_DEBOUNCE_REF = 'ADD_DEBOUNCE_REF',
  DELETE_DEBOUNCE_REF = 'DELETE_DEBOUNCE_REF',
}

type DebounceRef = object | undefined

type Action =
  | { type: actions.FOCUS_EVENT; event: React.FocusEvent | boolean }
  | { type: actions.ADD_DEBOUNCE_REF; event: DebounceRef }
  | { type: actions.DELETE_DEBOUNCE_REF; event: DebounceRef }

type State = {
  focusEvent: React.FocusEvent | boolean
  debouncing: boolean
  debouncingRefs: Set<DebounceRef>
}
const initialState: State = {
  focusEvent: false,
  debouncing: false,
  debouncingRefs: new Set(),
}

type ProviderProps = { children: ReactNode }

const handleDebounceRef = (state: State, operation: 'add' | 'delete', id: DebounceRef) => {
  const debouncingRefs = new Set(state.debouncingRefs)
  debouncingRefs[operation](id)
  const debouncing = !!debouncingRefs.size
  return { ...state, debouncingRefs, debouncing }
}

function eventHandlerReducer(state: State, action: Action) {
  switch (action.type) {
    case actions.FOCUS_EVENT:
      return { ...state, focusEvent: action.event }
    case actions.ADD_DEBOUNCE_REF:
      return handleDebounceRef(state, 'add', action.event)
    case actions.DELETE_DEBOUNCE_REF:
      return handleDebounceRef(state, 'delete', action.event)
    default:
      throw new Error('Unhandled action type')
  }
}

interface IContext extends State {
  inputOnFocusEvent: (event: React.FocusEvent | boolean) => void
  addDebounceRef: (event: DebounceRef) => void
  deleteDebounceRef: (event: DebounceRef) => void
}

let value: IContext = {
  ...initialState,
  inputOnFocusEvent: () => {},
  addDebounceRef: () => {},
  deleteDebounceRef: () => {},
}

const EventHandlerContext = createContext<IContext>(value)

const EventHandlerProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(eventHandlerReducer, initialState)
  value = {
    ...state,
    inputOnFocusEvent: (event: React.FocusEvent | boolean) =>
      dispatch({
        type: actions.FOCUS_EVENT,
        event,
      }),
    addDebounceRef: (event: DebounceRef) => dispatch({ type: actions.ADD_DEBOUNCE_REF, event }),
    deleteDebounceRef: (event: DebounceRef) =>
      dispatch({ type: actions.DELETE_DEBOUNCE_REF, event }),
  }
  return <EventHandlerContext.Provider value={value}>{children}</EventHandlerContext.Provider>
}

export { EventHandlerContext, EventHandlerProvider }
