import SVG from '../SVG'
import * as T from '../types'

const FacebookCircle = (props: T.ISVGProps) => (
  <SVG
    {...props}
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 45 44"
    d="M44.3078 21.8326C44.3078 9.77663 34.5311 0 22.4752 0C10.4192 0 0.642578 9.77663 0.642578 21.8326C0.642578 33.8886 10.4192 43.6652 22.4752 43.6652C22.6034 43.6652 22.731 43.6625 22.859 43.6602V26.6644H18.1684V21.198H22.859V17.1753C22.859 12.51 25.707 9.97085 29.8685 9.97085C31.8614 9.97085 33.5744 10.1194 34.0737 10.1857V15.0615H31.2041C28.9401 15.0615 28.5017 16.1376 28.5017 17.7163V21.198H33.9152L33.2096 26.6644H28.5017V42.8217C37.6273 40.2055 44.3078 31.7981 44.3078 21.8326Z"
    title="Facebook Logo"
    titleId="facebookCircleTitle"
    description="A white Facebook Logo in a black circle"
    descriptionId="facebookCircleDescription"
  />
)

export default FacebookCircle
