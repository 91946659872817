import * as T from './types'

const CaretRight = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.52647 8.75368C9.98334 8.35515 9.98334 7.64503 9.52647 7.24651L2.11962 0.785536C1.47271 0.221244 0.462269 0.680686 0.462269 1.53912V14.4611C0.462269 15.3195 1.47271 15.7789 2.11962 15.2146L9.52647 8.75368Z"
      fill={props.fill}
    />
  </svg>
)

const CaretLeft = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.77002 8.22139C0.360054 7.82783 0.360053 7.17217 0.770019 6.77861L7.34728 0.464542C7.9829 -0.145639 9.03981 0.304838 9.03981 1.18593V13.8141C9.03981 14.6952 7.9829 15.1456 7.34728 14.5355L0.77002 8.22139Z"
      fill={props.fill}
    />
  </svg>
)

const CaretDown = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.5L5 5.5L10 0.5H0Z"
      fill={props.fill}
    />
  </svg>
)

export { CaretRight, CaretLeft, CaretDown }
