import { forwardRef, useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

interface ITopProps {
  opacity?: number
  isInListingFlow: boolean
}
const OverlayContainer = styled.div<ITopProps>`
  width: 100vw;
  ${props =>
    props.isInListingFlow && `max-width: ${props.theme.metrics.listingFlowPageMaxWidth}px`};
  position: absolute;
  top: 0;
  ${props => props.isInListingFlow && `top: -${props.theme.metrics.headerHeightPhone}px;`}
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.black};
  opacity: ${props => props.opacity || 0};
  z-index: ${props => props.theme.zIndices[props.isInListingFlow ? 'level10' : 'level7']};
  transition: opacity 150ms ease-out;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    ${props => props.isInListingFlow && `top: -${props.theme.metrics.headerHeight}px;`}
  }
`

interface IProps {
  isInListingFlow: boolean
}
const Overlay = forwardRef<HTMLDivElement | null, IProps>(({ isInListingFlow }, ref) => {
  const theme = useContext(ThemeContext)

  const [opacity, setOpacity] = useState(0)
  useEffect(() => {
    setOpacity(theme.metrics.defaultModalOverlayOpacity)
  }, [])

  return (
    <OverlayContainer
      opacity={opacity}
      ref={ref}
      isInListingFlow={isInListingFlow}
    />
  )
})

export default Overlay
