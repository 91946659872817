import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import HomeListerLogo from 'design-system/Logo/HomeListerLogo'

import Anchor from 'components/GlobalStyles/Anchor'
import HorizontalRule from 'components/GlobalStyles/Hr'
import RoundedButton from 'components/RoundedButton'
import { CaretLeft, CaretRight } from 'components/SVG/Caret'
import CloseIcon from 'components/SVG/DeleteX'
import MenuIcon from 'components/SVG/Menu'
import Spacer from 'components/Spacer'

import { useElement, useWindowSize } from 'services/hooks'
import usePreventScroll from 'services/hooks/usePreventScroll'

import * as T from 'types'

import Help from './Help'
import MyAccount from './MyAccount'
import MyListing from './MyListing'

interface IModalOpenProps {
  modalOpen?: boolean
}
const MobileMenuContainer = styled.div<IModalOpenProps>`
  position: absolute;
  right: 0;
  height: 100%;
  ${props =>
    props.modalOpen
    && `
    width: 100%;
    margin-right: 0;
    box-shadow: 0px 0px 48px ${props.theme.colors.transparentGrey23Alpha25};
    border-radius: 12px 0px 0px 12px;
    background-color: ${props.theme.colors.white};
    // phone landscape
    @media (orientation: landscape) {
      width: 375px;
      height: 100vh;
    }
  `}

  @media (min-width: ${props => props.theme.metrics.tablet}px) and (min-height: ${props =>
  props.theme.metrics.tablet}px) {
    width: 375px;
    height: auto;
  }
`

const PaddingContainer = styled.div<IModalOpenProps>`
  padding: 0 24px;
  ${props =>
    props.modalOpen
    && `
    padding: 0 24px;
  `}
`

const BottomSectionContainer = styled.div<IModalOpenProps>`
  ${props => !props.modalOpen && 'display: none;'}
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: none;
  }
`

interface IPaddingProps {
  padding?: boolean
}
const Section = styled.div<IPaddingProps>`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  height: 36px;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  transition: background-color 0.15s ease; /* hover color transition */

  &:hover,
  &:active {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blue};
    border-radius: 4px;

    & > span {
      font-weight: 900;
    }

    & > svg path {
      fill: ${props => props.theme.colors.white};
    }
  }
`

const LogoContainer = styled.div`
  display: flex;
  padding-top: 5px;
`

const MobileMenuText = styled.span`
  padding-top: 1px; /* font correction */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
`

const MobileMenuBody = styled.div`
  height: calc(100% - ${props => props.theme.metrics.headerHeightPhone}px);
  @media (orientation: landscape) {
    overflow: scroll;
  }
`

const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  padding: 6px 12px;
  width: 100%;
  color: ${props => props.theme.colors.grey23};
  white-space: nowrap;
`

// large touch target region for mobile
interface ITransparentProps {
  transparent?: boolean
}
const MobileMenuHeader = styled.div<IModalOpenProps & ITransparentProps>`
  height: ${props => props.theme.metrics.headerHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props =>
    props.transparent && !props.modalOpen
      ? props.theme.colors.white
      : props.theme.colors.neutral900};

  & > span {
    padding-top: 2px; /* font-correction */
  }

  @media (orientation: landscape) {
    ${props =>
    props.modalOpen
      && `
      border-bottom: 1px solid ${props.theme.colors.neutral900};`}
    justify-content: flex-end;
  }

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    justify-content: flex-end;
  }
`

const MenuIconContainer = styled.div<IModalOpenProps & ITransparentProps>`
  display: flex;
  align-items: center;
  padding: 10px 24px;
  border: 2px solid
    ${props =>
    props.transparent && !props.modalOpen
      ? props.theme.colors.white
      : props.theme.colors.neutral900};
  border-radius: 50px;
  height: 44px;
  cursor: pointer;
`

const MenuText = styled.div`
  font-size: 16px;
  font-weight: 800;
  padding-top: 4px;
  text-transform: uppercase;
`

const BackIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  & > svg {
    padding-bottom: 2px; /* svg vertical alignment with text */
  }

  &:hover {
    color: ${props => props.theme.colors.blue};
    & > svg path {
      fill: ${props => props.theme.colors.blue};
    }
  }
`

interface IProps {
  user: T.IUser | undefined
  logout(): void
  modalOpen: boolean
  toggleModal: () => void
  transparent?: boolean
}

const MobileMenu: FunctionComponent<IProps> = ({
  user,
  logout,
  modalOpen,
  toggleModal,
  transparent,
}) => {
  const theme = useContext(ThemeContext)
  const { width, height } = useWindowSize()
  const isLandscape = height && width && width > height
  const isMobile = width && width < theme.metrics.tablet

  const [menuRef, menuElement] = useElement<HTMLDivElement>()
  usePreventScroll(modalOpen, menuElement, 'headerMenu')

  const router = useRouter()
  const [helpOpen, setHelpOpen] = useState(false)

  const handleClickLogin = () => {
    toggleModal()
    router.push('/signin')
  }

  const handleClickGetStarted = () => {
    toggleModal()
    router.push('/listing/create')
  }

  const handleClickHelp = () => setHelpOpen(true)
  const handleClickBack = () => setHelpOpen(false)

  const toggleMenu = () => {
    if (helpOpen) setHelpOpen(false)

    toggleModal()
  }

  const renderHelpView = () => (
    <>
      <BackIconContainer onClick={handleClickBack}>
        <CaretLeft
          width={20}
          height={14}
          fill={theme.colors.black}
        />
        <Spacer size={8} />
        <MenuText>Back</MenuText>
      </BackIconContainer>
      <HorizontalRule />
      <Help />
    </>
  )

  return (
    <MobileMenuContainer
      modalOpen={modalOpen}
      ref={menuRef}
    >
      <PaddingContainer modalOpen={modalOpen}>
        <MobileMenuHeader
          modalOpen={modalOpen}
          transparent={transparent}
        >
          {modalOpen && isMobile && !isLandscape && (
            <LogoContainer>
              <Anchor href="/">
                <HomeListerLogo />
              </Anchor>
            </LogoContainer>
          )}
          <MenuIconContainer
            onClick={toggleMenu}
            modalOpen={modalOpen}
            transparent={transparent}
          >
            {modalOpen
              ? (
                <CloseIcon
                  width={12}
                  height={12}
                  fill={theme.colors.neutral900}
                />
              )
              : (
                <MenuIcon
                  width={20}
                  height={12}
                  fill={transparent ? theme.colors.white : theme.colors.neutral900}
                />
              )}
            <Spacer size={10} />
            <MenuText>{modalOpen ? 'Close' : 'MENU'}</MenuText>
          </MenuIconContainer>
        </MobileMenuHeader>
      </PaddingContainer>
      {modalOpen && (
        <MobileMenuBody>
          <PaddingContainer modalOpen={modalOpen}>
            {user && !helpOpen && (
              <>
                {!isLandscape && <HorizontalRule />}
                <Spacer size={10} />
                <Text>{`Hello ${user.name}`}</Text>
                <MyListing toggleModal={toggleModal} />
                <Spacer size={10} />
                <HorizontalRule />
                <Spacer size={10} />
                <MyAccount
                  logout={logout}
                  profileOnly
                />
                <Spacer size={10} />
              </>
            )}
            {helpOpen
              ? (
                renderHelpView()
              )
              : (
                <>
                  {!isLandscape && <HorizontalRule />}
                  <Spacer size={10} />
                  <Section>
                    <Link
                      href="/how-it-works"
                      passHref
                    >
                      <Anchor>
                        <Row onClick={toggleModal}>
                          <MobileMenuText>How It Works</MobileMenuText>
                        </Row>
                      </Anchor>
                    </Link>
                    <Spacer size={10} />
                    <Link
                      href="/pricing"
                      passHref
                    >
                      <Anchor>
                        <Row onClick={toggleModal}>
                          <MobileMenuText>Pricing</MobileMenuText>
                        </Row>
                      </Anchor>
                    </Link>
                    <Spacer size={10} />
                    <Link
                      href="/why-homelister"
                      passHref
                    >
                      <Anchor>
                        <Row onClick={toggleModal}>
                          <MobileMenuText>Our Story</MobileMenuText>
                        </Row>
                      </Anchor>
                    </Link>
                    <Spacer size={10} />
                    <Link
                      href="/listings/homes-for-sale"
                      passHref
                    >
                      <Anchor>
                        <Row onClick={toggleModal}>
                          <MobileMenuText>Homes For Sale</MobileMenuText>
                        </Row>
                      </Anchor>
                    </Link>
                    <Spacer size={10} />
                    <Row onClick={handleClickHelp}>
                      <MobileMenuText>Help</MobileMenuText>
                      <CaretRight
                        width={20}
                        height={14}
                        fill={theme.colors.blue4}
                      />
                    </Row>
                  </Section>
                  <Spacer size={10} />
                  <HorizontalRule />
                  <Spacer size={10} />
                  <Section>
                    {!user
                      ? (
                        <>
                          <Spacer size={6} />
                          <RoundedButton
                            text="Log in"
                            onClick={handleClickLogin}
                            background={theme.colors.white}
                            borderColor={theme.colors.blue4}
                            borderWidth={2}
                            color={theme.colors.blue4}
                            fontSize={14}
                            radius={50}
                            height={44}
                            fullWidth
                            bold
                          />
                          <Spacer size={16} />
                        </>
                      )
                      : (
                        <>
                          <MyAccount
                            logout={logout}
                            logoutOnly
                          />
                          <Spacer size={24} />
                        </>
                      )}
                  </Section>
                </>
              )}
          </PaddingContainer>
          {!user && (
            <BottomSectionContainer modalOpen={modalOpen}>
              <RoundedButton
                text="Get Started"
                onClick={handleClickGetStarted}
                background={theme.colors.blue4}
                hoverBackground={theme.colors.blue9}
                color={theme.colors.white}
                boxShadow={`0px 12px 16px ${theme.colors.transparentBlue4Alpha25}`}
                containerPadding="0 24px 32px" // needed so box shadow doesn't clip
                fontSize={14}
                radius={50}
                height={44}
                fullWidth
                bold
              />
            </BottomSectionContainer>
          )}
        </MobileMenuBody>
      )}
    </MobileMenuContainer>
  )
}

export default MobileMenu
