import { MutableRefObject, useEffect, useRef, useState } from 'react'

const useElement = <ElementType>(): [MutableRefObject<ElementType | null>, ElementType | null] => {
  const ref = useRef<ElementType | null>(null)
  const [element, setElement] = useState<ElementType | null>(null)

  useEffect(() => {
    setElement(ref.current)
  }, [ref.current])

  return [ref, element]
}

export default useElement
