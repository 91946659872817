import { FunctionComponent } from 'react'

import Body1 from './Body/Body1'
import Body2 from './Body/Body2'
import Body3 from './Body/Body3'
import Body4 from './Body/Body4'
import CallOut1 from './CallOut/CallOut1'
import CallOut2 from './CallOut/CallOut2'
import Caption from './Caption/Caption'
import Heading1 from './Heading/Heading1'
import Heading2 from './Heading/Heading2'
import Heading3 from './Heading/Heading3'
import Heading4 from './Heading/Heading4'
import Label from './Label/Label'
import Subheading1 from './Subheading/Subheading1'
import Subheading2 from './Subheading/Subheading2'
import Subheading3 from './Subheading/Subheading3'
import Title1 from './Title/Title1'
import { ITypographyComponent, ITypographyProps } from './utils/types'

export const typographyComponents: {
  [key in ITypographyComponent]: FunctionComponent<ITypographyProps>
} = {
  Body1,
  Body2,
  Body3,
  Body4,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Subheading1,
  Subheading2,
  Subheading3,
  Title1,
  CallOut1,
  CallOut2,
  Caption,
  Label,
}
