import { serialize } from 'cookie'
import { ServerResponse } from 'http'
import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'
import vwoSdk, { VWOLaunchConfig } from 'vwo-node-sdk'

import { IUser, IVariations, IVwoSettingsFile, IVwoUserExperiment } from 'types'
import { VwoGoalIdentifier } from 'types/enums'

import { updateUser } from './users'

/** Constants and helpers */

const EXPIRY_YEARS = 2

const AB_TYPE = 'VISUAL_AB'

const isBrowser = typeof window !== 'undefined'

export const appendDevToExperimentKey = (experiment: string) =>
  `${experiment}${process.env.IS_PROD === 'true' ? '' : '-dev'}`

const getCookieExpiry = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() + EXPIRY_YEARS))

const convertToObject = (cookieString: string): IVariations => {
  if (!cookieString) return {}

  try {
    return JSON.parse(cookieString) || ({} as IVariations)
  } catch (err) {
    console.error('Invalid variations cookie: ', err)
    return {}
  }
}

const mergeVariationValues = (variationsA: IVariations, variationsB: IVariations) =>
  Object.keys(variationsB).reduce((acc, key) => {
    if (!acc[key]) acc[key] = variationsB[key]

    return acc
  }, variationsA)

const convertPersistedVariationsToObject = (experiments: IVwoUserExperiment[]): IVariations =>
  experiments.reduce(
    (acc, experiment) => ({
      ...acc,
      [experiment.goalIdentifier]: experiment.assignment || null,
    }),
    {},
  )

interface IVwoDataValues {
  vwoUserId: string | undefined
  variations: IVariations
}
export const getABTestCookieValues = (): IVwoDataValues => ({
  vwoUserId: Cookies.get('vwoUserId'),
  variations: convertToObject(Cookies.get('vwoVariations') || ''),
})

export const getVwoData = (user?: IUser): IVwoDataValues => ({
  vwoUserId: Cookies.get('vwoUserId'),
  variations: mergeVariationValues(
    convertPersistedVariationsToObject(user?.vwoExperiments || []),
    convertToObject(Cookies.get('vwoVariations') || ''),
  ),
})

export const formatUserExperiments = (variations?: IVariations): IVwoUserExperiment[] => {
  if (
    !variations
    || !Object.keys(variations).length
    || !Object.values(variations).every(variation => variation !== undefined)
  ) {
    return []
  }

  return Object.keys(variations)
    .filter(key => Boolean(variations[key])) // remove null entries
    .map(key => ({
      goalIdentifier: key,
      assignment: variations[key] as string, // safe because we removed null entries
    }))
}

export const formatVariations = (experiments: IVwoUserExperiment[]) =>
  experiments.reduce((acc, experiment) => {
    acc[experiment.goalIdentifier] = experiment.assignment || null
    return acc
  }, {} as IVariations)

// this function is extra work, but ensures we never add additional new experiements to a user
// after they've signed up - we can only modify their existing assignments (e.g. from null to Control)
const mergeDifferentAssignments = (
  previousExperiments?: IVwoUserExperiment[], //
  latestExperiments?: IVwoUserExperiment[],
) => {
  let isModified = false
  previousExperiments?.map(experimentA => {
    const experimentB = latestExperiments?.find(
      experiment => experiment.goalIdentifier === experimentA.goalIdentifier,
    )

    if (experimentB?.assignment && experimentA.assignment !== experimentB?.assignment) {
      experimentA.assignment = experimentB?.assignment
      isModified = true
    }

    return experimentA
  })
  return { isModified, experiments: previousExperiments }
}

export const reconcileVwoData = (user?: IUser, cookies?: IVwoDataValues) => {
  if (!user) return // nothing to reconcile if user doesn't exist yet or hasn't been fetched

  if (!Object.keys(user).length) return // @FIXME - user is sometimes an empty object, not sure why

  // if only set in cookies, update the db user
  let valuesChanged = false

  const reconciledValues = {
    vwoUserId: user.vwoUserId,
    vwoExperiments: user.vwoExperiments,
  }

  if (!user.vwoUserId && cookies?.vwoUserId) {
    reconciledValues.vwoUserId = cookies.vwoUserId
    valuesChanged = true
    console.warn(`reconciled vwoUserId to cookie value: ${reconciledValues.vwoUserId}`)
  }

  const cookieVariationsArray = formatUserExperiments(cookies?.variations)

  const { isModified, experiments } = mergeDifferentAssignments(
    user.vwoExperiments,
    cookieVariationsArray,
  )

  if (isModified) {
    reconciledValues.vwoExperiments = experiments
    valuesChanged = true
    console.warn(
      `reconciled vwoExperiments to cookie value: ${JSON.stringify(
        reconciledValues.vwoExperiments,
      )}`,
    )
  }

  if (valuesChanged) updateUser(user._id, reconciledValues) // don't need to wait for this to succeed
}

/**
 * Mappings to correlate the route to an ongoing experiment
 *
 * NB: so far, we've only had one experiment per route, but this data structure could be refactored
 * to support multiple experiments per route by returning a string[]
 */

// to be used in getServerSideProps when user hits a page in the app via address bar or link
export const getCampaignUrlPartsToExperimentKeys = (): { [key: string]: string } => {
  const campaignUrlMapping: { [key: string]: string } = {
    // 'for-sale-by-owner': 'fsbo-signup-v2',
  }
  if (process.env.IS_PROD === 'true') return campaignUrlMapping

  return Object.entries(campaignUrlMapping).reduce(
    (acc, [campaign, experiment]) =>
      Object.assign(acc, { [campaign]: appendDevToExperimentKey(experiment) }),
    {},
  )
}

// To be used for client-activated AB tests
export const getRouteNameToExperimentKeys = (): { [key: string]: string } => {
  const campaignUrlMapping: { [key: string]: string } = {
    // examples - get this from next/router's router.route
    // '/listing/create': 'signup-page-v2',
  }
  if (process.env.IS_PROD === 'true') return campaignUrlMapping

  return Object.entries(campaignUrlMapping).reduce(
    (acc, [campaign, experiment]) =>
      Object.assign(acc, { [campaign]: appendDevToExperimentKey(experiment) }),
    {},
  )
}

export const getSignupListingFlowExperimentKeys = (): string[] => {
  const experimentKeys: string[] = [
    // 'example-test-name',
  ]
  return experimentKeys.map(appendDevToExperimentKey)
}

/**
 * Experiment Goal Arrays
 *
 * These helper arrays show which experiments are active for which kinds of goals, plus a catchall
 * for all ongoing experiments
 */

export const ongoingExperiments = [
  // 'example-test-name',
].map(appendDevToExperimentKey)

export const signupConversionExperiments: string[] = [
  // 'example-test-name',
].map(appendDevToExperimentKey)

export const packagePurchaseConversionExperiments: string[] = [
  // 'example-test-name',
].map(appendDevToExperimentKey)

export const addOnServicePurchaseConversionExperiments: string[] = [
  // 'example-test-name',
].map(appendDevToExperimentKey)

export const agreementSignatureConversionExperiments: string[] = [
  // 'example-test-name',
].map(appendDevToExperimentKey)

export const goalToOngoingExperimentsOfType: { [key in VwoGoalIdentifier]: string[] } = {
  [VwoGoalIdentifier.signup]: signupConversionExperiments,
  [VwoGoalIdentifier.basic]: agreementSignatureConversionExperiments,
  [VwoGoalIdentifier.premium]: packagePurchaseConversionExperiments,
  [VwoGoalIdentifier.platinum]: packagePurchaseConversionExperiments,
  [VwoGoalIdentifier.addOnService]: addOnServicePurchaseConversionExperiments,
}

/**
 * When a user signs up, we save the set of all currently running experiments. For the lifetime
 * of the user, these are the only post-signup experiments that will be active.
 */
export const getEligibleVwoCampaignsForSignup = async (): Promise<string[]> => {
  try {
    if (!process.env.VWO_SDK_KEY) throw new Error('VWO_SDK_KEY is not defined')

    const settingsFile = (await vwoSdk.getSettingsFile(
      '596669', // account id
      process.env.VWO_SDK_KEY, // sdk key
      // isBrowser ? vwoUserStorageService : undefined, // BROKEN
    )) as IVwoSettingsFile

    return settingsFile.campaigns
      .filter(campaign => campaign.type === AB_TYPE)
      .map(campaign => campaign.key)
  } catch (error) {
    console.error(error)
    return [] // upon error, we'll just not allow the user to be added to any campaigns
  }
}

const isUserEligibleForCampaign = (user: IUser, campaignKey: string) =>
  user?.vwoExperiments?.map(experiment => experiment.goalIdentifier).includes(campaignKey)

/*
 * Launches the SDK
 *
 * Additionally, we store the vwo settings in local storage on browser for faster launch on return
 * to the site, so here we check if we're on the browser and provide a service that retrieves
 * the pre-existing settings from local storage.
 *
 * Currently localStorage is disabled - we were advised by VWO this is not the ideal implementation
 * and to boot, trying it broke launching the SDK
 */
export const getVwoClientInstance = async () => {
  if (!process.env.VWO_SDK_KEY) throw new Error('VWO_SDK_KEY is not defined')

  const settingsFile = await vwoSdk.getSettingsFile(
    '596669', // account id
    process.env.VWO_SDK_KEY, // sdk key
    // isBrowser ? vwoUserStorageService : undefined, // BROKEN
  )

  const launchConfig: VWOLaunchConfig = { settingsFile }
  // if (isBrowser) launchConfig.userStorageService = vwoUserStorageService // BROKEN
  const vwoClientInstance = vwoSdk.launch(launchConfig)
  return vwoClientInstance
}

/** Get the variations for experiments a user is activated for  */
export const getVariationsForVwoUserId = async (vwoUserId: string, experiments: string[]) => {
  const vwoClientInstance = await getVwoClientInstance()

  const variations: IVariations = experiments.reduce((exps, exp) => {
    const variationName = vwoClientInstance.getVariationName(exp, vwoUserId)
    return Object.assign(exps, { [exp]: variationName })
  }, {})

  return variations
}

/**
 * Activation of a test will return which variation the user is in, assigning them to one
 * for the experiment if they hadn't been activated yet
 */
const activateNewExperiment = async (experimentKey: string, vwoUserId: string) => {
  const vwoClientInstance = await getVwoClientInstance()
  const variationName = vwoClientInstance.activate(experimentKey, vwoUserId)
  return variationName
}

interface IVwoData {
  vwoUserId: string
  variations: IVariations | undefined
}

export const activateVwoTestServerside = async (
  experimentKey: string,
  serverCookies?: { [key: string]: string },
  res?: ServerResponse,
  user?: IUser,
): Promise<IVwoData> => {
  let vwoUserId: string = ''
  let variations: IVariations = {}

  try {
    // Get the existing cookies
    const cookies: { [key: string]: string | undefined } | undefined = serverCookies

    // get or create vwo user
    const storedUserId = cookies?.vwoUserId as string | undefined
    vwoUserId = storedUserId || uuid()

    // if the user already exists, check if they're eligible for the experiment
    // for now, this is the set of ongoing experiments that existed the moment they signed up
    if (user && !isUserEligibleForCampaign(user, experimentKey)) return { vwoUserId, variations }

    // activate new experiment and add to variations
    const variationName = await activateNewExperiment(experimentKey, vwoUserId)
    variations = convertToObject(cookies?.vwoVariations || '')
    variations[experimentKey] = variationName

    // set necessary cookies on client
    if (res) {
      res.setHeader('Set-Cookie', [
        serialize('vwoUserId', vwoUserId, { expires: getCookieExpiry(), path: '/' }),
        serialize('vwoVariations', JSON.stringify(variations), {
          expires: getCookieExpiry(),
          path: '/',
        }),
      ])
    }
  } catch (err) {
    console.error(err)
  }

  return { vwoUserId, variations }
}

export const activateVwoTestClientside = async (
  experimentKey: string,
  user?: IUser,
): Promise<IVwoData> => {
  let vwoUserId: string = ''
  let variations: IVariations = {}

  try {
    const browserError = '`activateVwoTestClientside` is only available in the browser'
    if (!isBrowser) throw new Error(browserError)

    // get or create vwo user
    const persistedVwoUserId = user?.vwoUserId
    const storedUserId = Cookies.get('vwoUserId')
    vwoUserId = persistedVwoUserId || storedUserId || uuid()

    // get vwo variations
    const persistedVariations: IVariations = convertPersistedVariationsToObject(
      user?.vwoExperiments ?? [],
    )

    const cookieVariations = convertToObject(Cookies.get('vwoVariations') || '')
    variations = mergeVariationValues(persistedVariations, cookieVariations)

    // activate new experiment and add to variations
    // if the user already exists, check if they're eligible for the experiment
    // for now, this is the set of ongoing experiments that existed the moment they signed up
    console.warn({
      user,
      experimentKey,
      isUserEligibleForCampaign: !!user && isUserEligibleForCampaign(user, experimentKey),
    })
    if (!user || (user && isUserEligibleForCampaign(user, experimentKey))) {
      const variationName = await activateNewExperiment(experimentKey, vwoUserId)
      variations[experimentKey] = variationName
    }

    // set necessary cookies on client
    Cookies.set('vwoVariations', variations, { expires: getCookieExpiry(), path: '/' })
    if (!storedUserId) {
      Cookies.set('vwoUserId', vwoUserId, { expires: getCookieExpiry(), path: '/' })
    }
  } catch (err) {
    console.error(err)
  }

  return { vwoUserId, variations }
}

export const handleCampaignAssignmentSignupPersistence = async () => {
  let vwoUserId = ''
  let vwoExperiments: IVwoUserExperiment[] = []

  // pass the VWO id and campaign data if the user's been assigned to any a/b campaigns
  const { vwoUserId: cookieVwoUserId, variations: cookieVariations } = getABTestCookieValues()
  if (cookieVwoUserId) vwoUserId = cookieVwoUserId

  if (cookieVariations) vwoExperiments = formatUserExperiments(cookieVariations)

  // some VWO tests begin at the moment a user signs up (like ones that modify multile pagees
  // on the listing flow) - activate them here and add to the create user params
  const signupCampaignKeys = getSignupListingFlowExperimentKeys()

  if (signupCampaignKeys?.length) {
    // we intentionaly wish to run this code in series, so we don't hit any VWO API limits
    // eslint-disable-next-line no-restricted-syntax
    for (const campaignKey of signupCampaignKeys) {
      // eslint-disable-next-line no-await-in-loop
      const { variations: newVariations } = await activateVwoTestClientside(campaignKey)
      vwoExperiments = formatUserExperiments(newVariations)
    }
  }

  // add the remaining potential experiments a user can be activated for
  const eligibleCampaigns = await getEligibleVwoCampaignsForSignup()
  eligibleCampaigns.forEach(campaign => {
    if (!vwoExperiments.find(exp => exp.goalIdentifier === campaign)) {
      vwoExperiments.push({ goalIdentifier: campaign })
    }
  })

  return { vwoUserId, vwoExperiments }
}
