import styled from 'styled-components'

interface IInDropDownProps {
  inDropDown?: boolean
}
export const ContentContainer = styled.div<IInDropDownProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: ${props => (props.inDropDown ? '24px' : '0')};
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.colors.grey27};
  margin-left: 8px;
`

export const IconContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`

export const TitleText = styled.div`
  padding-top: 2px; /* font-correction */
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: left;
  width: 100%;
  white-space: nowrap;
`

export const LinkText = styled.div`
  padding-top: 2px; /* font-correction */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1.5px;
  text-decoration-line: underline;
  white-space: nowrap;
`

export const ItemRow = styled.div<IInDropDownProps>`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  min-height: 36px;
  cursor: pointer;
  transition: background-color 0.15s ease; /* hover color transition */

  &:hover {
    background: ${props => props.theme.colors.blue};
    border-radius: 4px;
  }
  &:hover ${TitleText}, :hover ${LinkText} {
    color: ${props => props.theme.colors.white};
  }
  &:hover svg path {
    fill: ${props => props.theme.colors.white};
  }
`
