import { forwardRef } from 'react'
import styled from 'styled-components'

import NoInternetIcon from 'components/SVG/illustration/NoInternet'
import SearchingIcon from 'components/SVG/illustration/SearchingInternet'

import { useMonitorInternetConnection, useWindowSize } from 'services/hooks'

import ExtraLargeHeader from './ExtraLargeHeader'
import Loader from './Loader'
import Spacer from './Spacer'

interface ITopProps {
  top: number
}
interface IMissingInternetProp {
  missingInternet: boolean
}
const Overlay = styled.div<ITopProps & IMissingInternetProp>`
  display: ${props => (props.missingInternet ? 'flex' : 'none')};
  width: 100vw;
  height: calc(100vh - ${props => props.theme.metrics.headerHeightPhone}px);
  position: absolute;
  top: ${props => props.top}px;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
  z-index: ${props => props.theme.zIndices.level10};
  overflow: hidden;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: calc(100vh - ${props => props.theme.metrics.headerHeight}px);
  }
`

const MaxWidthContainer = styled.div`
  max-width: 700px;
`

const SavingContainer = styled.div`
  display: flex;
  align-items: center;
`

const SavingText = styled.div`
  padding-top: 2px; /* font correction */
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.colors.purple};
`

const MissingInternetOverlay = forwardRef<HTMLDivElement>((props, ref) => {
  const { missingInternet, retrying } = useMonitorInternetConnection()

  const { scrollY } = useWindowSize()

  return (
    <Overlay
      missingInternet={missingInternet || retrying}
      top={scrollY || 0}
    >
      <MaxWidthContainer ref={ref}>
        <ExtraLargeHeader
          title={retrying ? 'Searching...' : 'Check your Connection'}
          subtitle={
            retrying
              ? ''
              : 'Looks like your wifi is down or you’re not within range. Please refresh and we’ll try to save your lastest input.'
          }
          noMargin
        />
      </MaxWidthContainer>
      <Spacer size={35} />
      {!retrying && (
        <NoInternetIcon
          width={544}
          height={344}
        />
      )}
      {retrying && (
        <>
          <SearchingIcon
            width={561}
            height={493}
          />
          <Spacer size={50} />
          <SavingContainer>
            <Loader
              height={15}
              width={15}
            />
            <Spacer size={7.5} />
            <SavingText>Saving your latest contents</SavingText>
          </SavingContainer>
        </>
      )}
    </Overlay>
  )
})

export default MissingInternetOverlay
