import styled from 'styled-components'

const Anchor = styled.a.attrs({ rel: 'noopener noreferrer' })`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
`

export default Anchor
