export const interleaveArrays = (arr1: any[], arr2: any[]) =>
  arr1.map((elem, idx) => [elem, arr2[idx]]).reduce((a, b) => a.concat(b))

export const interleaveArrayWithElement = (arr: any[], newElem: any, removeLast = true) => {
  const interleavedArr = arr.map(elem => [elem, newElem]).reduce((a, b) => a.concat(b))

  if (removeLast) interleavedArr.pop()

  return interleavedArr
}
