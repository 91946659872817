import { NextRouter } from 'next/router'
import { useEffect } from 'react'

/**
 * React hook that forces a scroll reset to a particular set of coordinates in the document
 * after `next/router` finishes transitioning to a new page client side. It smoothly scrolls back to
 * the top by default.
 */
interface IProps {
  router: NextRouter
  behavior?: 'smooth' | 'auto'
  left?: number
  top?: number
}

const useRouterScroll = (props: IProps) => {
  const { behavior = 'smooth', left = 0, top = 0 } = props
  const { router } = props
  useEffect(() => {
    // Scroll to given coordinates when router finishes navigating
    // This fixes an inconsistent behaviour between `<Link/>` and `next/router`
    // See https://github.com/vercel/next.js/issues/3249
    const handleRouteChangeComplete = () => {
      if (typeof window === 'undefined' || !window?.scrollTo) return

      window.scrollTo({ top, left, behavior })
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    // If the component is unmounted, unsubscribe from the event
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [behavior, left, top])
}

export default useRouterScroll
