import './utils/colorString'

export type ColorName =
  | 'black'
  | 'white'
  | 'neutral25'
  | 'neutral50'
  | 'neutral100'
  | 'neutral200'
  | 'neutral300'
  | 'neutral400'
  | 'neutral500'
  | 'neutral600'
  | 'neutral700'
  | 'neutral800'
  | 'neutral900'
  | 'black'
  | 'blue25'
  | 'blue50'
  | 'blue100'
  | 'blue200'
  | 'blue300'
  | 'blue400'
  | 'blue500'
  | 'blue600'
  | 'blue700'
  | 'blue800'
  | 'blue900'
  | 'blue1000'
  | 'teal25'
  | 'teal50'
  | 'teal100'
  | 'teal200'
  | 'teal300'
  | 'teal400'
  | 'teal500'
  | 'teal600'
  | 'teal700'
  | 'teal800'
  | 'teal900'
  | 'gold25'
  | 'gold50'
  | 'gold100'
  | 'gold200'
  | 'gold300'
  | 'gold400'
  | 'gold500'
  | 'gold600'
  | 'gold700'
  | 'gold800'
  | 'gold900'
  | 'gold1000'
  | 'green50'
  | 'green100'
  | 'green200'
  | 'green300'
  | 'green400'
  | 'green500'
  | 'green600'
  | 'green700'
  | 'green800'
  | 'green900'
  | 'yellow50'
  | 'yellow100'
  | 'yellow200'
  | 'yellow300'
  | 'yellow400'
  | 'yellow500'
  | 'yellow600'
  | 'yellow700'
  | 'yellow800'
  | 'yellow900'
  | 'red50'
  | 'red100'
  | 'red200'
  | 'red300'
  | 'red400'
  | 'red500'
  | 'red600'
  | 'red700'
  | 'red800'
  | 'red900'

export type TextColorName =
  | 'white'
  | 'neutral300'
  | 'neutral400'
  | 'neutral500'
  | 'neutral600'
  | 'neutral900'
  | 'gold600'
  | 'blue500'
  | 'blue600'
  | 'blue700'
  | 'teal500'

// @TEMP - this is disabled until we migrate all the old color values to the new theme.
// once we do that, we can get full type safety on the colors.
// export type IColors = { [key in ColorName]: string }

export type IColors = { [key: string]: string }

const colors: IColors = {
  // Design System Colors
  // Link: https://www.figma.com/file/6zHr43vLq5VILtfkBCKGpu/%F0%9F%8E%A8-HomeLister-Design-System?node-id=503%3A3662

  // neutral
  white: '#FFFFFF',
  neutral25: '#f5f5f5',
  neutral50: '#eeeeee',
  neutral100: '#cacaca',
  neutral200: '#b1b1b1',
  neutral300: '#8d8d8d',
  neutral400: '#777777',
  neutral500: '#555555',
  neutral600: '#4d4d4d',
  neutral700: '#3c3c3c',
  neutral800: '#2f2f2f',
  neutral900: '#242424',

  // black
  black: '#000000',

  // blue
  blue25: '#f4fbff',
  blue50: '#e7f5fd',
  blue100: '#b6dff9',
  blue200: '#92d0f6',
  blue300: '#60bbf2',
  blue400: '#41adf0',
  blue500: '#1299ec',
  blue600: '#108bd7',
  blue700: '#0d6da8',
  blue800: '#0a5482',
  blue900: '#014670',
  blue1000: '#003251',

  // teal
  teal25: '#f4fdff',
  teal50: '#e6f9fe',
  teal100: '#b2ebfb',
  teal200: '#8de2f9',
  teal300: '#59d4f6',
  teal400: '#39ccf4',
  teal500: '#07bff1',
  teal600: '#06aedb',
  teal700: '#0588ab',
  teal800: '#046985',
  teal900: '#035065',

  // gold
  gold25: '#FFF7E6',
  gold50: '#fff8e9',
  gold100: '#ffe9b9',
  gold200: '#ffde98',
  gold300: '#ffcf68',
  gold400: '#ffc54b',
  gold500: '#ffb71e',
  gold600: '#ffa51e',
  gold700: '#e8a71b',
  gold800: '#b58215',
  gold900: '#8c6511',
  gold1000: '#6b4d0d',

  // green
  green50: '#ecf8ed',
  green100: '#c3e8c7',
  green200: '#a5ddac',
  green300: '#7ccd86',
  green400: '#63c46e',
  green500: '#3cb54a',
  green600: '#37a543',
  green700: '#2b8135',
  green800: '#216429',
  green900: '#194c1f',

  // yellow
  yellow50: '#fffde6',
  yellow100: '#fff7b0',
  yellow200: '#fff48a',
  yellow300: '#ffee54',
  yellow400: '#ffeb33',
  yellow500: '#ffe600',
  yellow600: '#e8d100',
  yellow700: '#b5a300',
  yellow800: '#8c7f00',
  yellow900: '#6b6100',

  // red
  red50: '#fff1f1',
  red100: '#f8c2c3',
  red200: '#f4a4a6',
  red300: '#ef7b7e',
  red400: '#ec6165',
  red500: '#e73a3e',
  red600: '#d23538',
  red700: '#a4292c',
  red800: '#7f2022',
  red900: '#61181a',

  // DEPRECATED - continue to use these colors if you see them in Figmas and the hex code is NOT
  // one of the colors in the above design system section pallettes

  // blacks
  black2: '#43484D',

  // blues
  blue: '#07BFF1',
  blue2: 'rgba(7, 191, 241, 0.25)',
  blue3: '#0046BE',
  blue4: '#1299EC',
  blue5: '#11B0E2',
  blue6: '#094BF1',
  blue7: '#00ABDA',
  blue8: '#2B3487',
  blue9: '#49BAFF',
  blue10: '#75C9FD',
  blue11: '#CCF4FF',
  blue12: '#003251',
  blue13: '#E0F8FF',
  blue14: '#172B4D',
  blue15: '#014670',
  blue16: '#39CCF4',
  blue18: '#0A5482',

  // greens
  green: '#3cb54a',
  green2: '#3CB47F',
  green3: '#05A40B',

  // greys
  grey: '#F0F0F0',
  grey2: '#F8F8F8',
  grey3: '#E3E3E3',
  grey4: '#E2E2E2',
  grey5: '#DDDDDD',
  grey6: '#878787',
  grey7: '#979797',
  grey8: '#B3BAC5',
  grey9: '#DFE1E6',
  grey10: '#F4F5F7',
  grey11: '#B2B2B2',
  grey13: '#C4C4C4',
  grey14: '#888888',
  grey15: '#EEEEEE',
  grey16: '#FAFAFA',
  grey17: '#666666',
  grey18: '#F7F7F7',
  grey19: '#FBFBFB',
  grey20: '#EDEDED',
  grey21: '#6F6F6F',
  grey22: '#F2F2F2',
  grey23: '#707070',
  grey24: '#F1F1F1',
  grey25: '#444444',
  grey26: '#999999',
  grey27: '#222222',
  grey28: '#E0E0E0',
  grey29: '#CCCCCC',
  grey30: '#D1D1D1',
  grey31: '#f5f5f5',
  grey32: '#777777',
  grey33: '#4D4D4D',
  grey34: '#8D8D8D',

  // purples
  purple: '#293189',
  purple2: '#202879',
  purple3: '#202774',

  // reds
  red: '#E73A3E',
  red2: '#B8002E',
  red3: '#AB002B',
  red4: '#FFF1F1',
  red5: '#E73A3E',
  red6: '#FFF1F1',
  red7: '#CACACA',

  // transparent
  transparent: 'rgba(0,0,0,0)',

  // transparent blacks
  transparentBlackAlpha05: 'rgba(0, 0, 0, 0.05)',
  transparentBlackAlpha10: 'rgba(0, 0, 0, 0.1)',
  transparentBlackAlpha15: 'rgba(0, 0, 0, 0.1)',
  transparentBlackAlpha20: 'rgba(0, 0, 0, 0.2)',
  transparentBlackAlpha25: 'rgba(0, 0, 0, 0.25)',
  transparentBlackAlpha35: 'rgba(0, 0, 0, 0.35)',
  transparentBlackAlpha50: 'rgba(0, 0, 0, 0.5)',
  transparentBlackAlpha60: 'rgba(0, 0, 0, 0.6)',

  // transparent greys
  transparentGreyAlpha25: 'rgba(227, 227, 227, 0.25)',
  transparentGrey23Alpha15: 'rgba(112, 112, 112, 0.15)',
  transparentGrey23Alpha16: 'rgba(112, 112, 112, 0.16)',
  transparentGrey23Alpha25: 'rgba(112, 112, 112, 0.25)',
  transparentGrey23Alpha50: 'rgba(112, 112, 112, 0.5)',
  transparentGrey24Alpha50: 'rgba(241, 241, 241, 0.5)',
  transparentGrey27Alpha50: 'rgba(34, 34, 34, 0.5)',
  transparentGrey30Alpha40: 'rgba(209, 209, 209, 0.4)',
  transparentGrey30Alpha50: 'rgba(241, 241, 241, 0.5)',

  // transparent blues
  transparentBlueAlpha10: 'rgba(7, 191, 241, 0.1)',
  transparentBlueAlpha24: 'rgba(7, 191, 241, 0.24)',
  transparentBlueAlpha25: 'rgba(7, 191, 241, 0.25)',
  transparentBlueAlpha90: 'rgba(7, 191, 241, 0.9)',
  transparentBlueAlpha50: 'rgba(7, 191, 241, 0.5)',

  transparentBlue4Alpha15: 'rgba(18, 153, 236, 0.15)',
  transparentBlue4Alpha25: 'rgba(18, 153, 236, 0.25)',
  transparentBlue4Alpha65: 'rgba(18, 153, 236, 0.65)',
  transparentBlue4Alpha80: 'rgba(18, 153, 236, 0.80)',

  transparentBlue11Alpha40: 'rgba(204, 244, 255, 0.4)',

  transparentBlue14Alpha04: 'rgba(23, 43, 77, 0.04)',
  transparentBlue14Alpha08: 'rgba(23, 43, 77, 0.08)',
  transparentBlue14Alpha50: 'rgba(23, 43, 77, 0.5)',

  transparentBlue17Alpha10: 'rgba(230, 249, 254, 0.1)',
  transparentBlue17Alpha75: 'rgba(230, 249, 254, 0.75)',

  transparentTealAlpha75: 'rgba(230, 249, 254, 0.75)',

  // transparent off whites
  transparentOffWhiteAlpha50: 'rgba(240, 240, 240, 0.5)',

  // transparent purples
  transparentPurpleAlpha06: 'rgba(41, 49, 137, 0.06)',
  transparentPurpleAlpha10: 'rgba(41, 49, 137, 0.1)',
  transparentPurpleAlpha12: 'rgba(41, 49, 137, 0.12)',
  transparentPurpleAlpha20: 'rgba(41, 49, 137, 0.2)',
  transparentPurpleAlpha25: 'rgba(41, 49, 137, 0.25)',

  // transparent reds
  transparentRedAlpha10: 'rgba(204, 0, 51, 0.1)',
  transparentRedAlpha50: 'rgba(231, 58, 62, 0.5)',

  // transparent whites
  transparentWhite: 'rgba(255,255,255,0)',
  transparentWhiteAlpha10: 'rgba(255,255,255, 0.1)',
  transparentWhiteAlpha20: 'rgba(255, 255, 255, 0.2)',
  transparentWhiteAlpha50: 'rgba(255, 255, 255, 0.5)',
  transparentWhiteAlpha60: 'rgba(255, 255, 255, 0.6)',
  transparentWhiteAlpha75: 'rgba(255, 255, 255, 0.75)',
  transparentWhiteAlpha87: 'rgba(255, 255, 255, 0.87)',

  // transparent greens
  transparentGreenAlpha10: 'rgba(60, 181, 74, 0.1)',

  // transparent yellow
  transparentYellow4Alpha10: 'rgba(255, 183, 30, 0.1)',
  transparentYellow4Alpha25: 'rgba(255, 183, 30, 0.25)',

  // yellows
  yellow: '#FFE400',
  yellow2: '#FEC200',
  yellow3: '#E1C900',
  yellow4: '#FFA51E',
  yellow5: '#FFB71E',
  yellow6: '#FFFBF2',
  yellow7: '#FFDB8F',
  yellow8: '#FFDE98',

  // social
  facebookBlue: '#4267B2',
  twitterBlue: '#55acee',
  emailGrey: '#7d7d7d',

  // drop shadow
  dropShadow6: 'rgba(112, 112, 112, 0.32)',
  dropShadow7: 'rgba(18, 153, 236, 0.16)',
}

export default colors
