import * as T from './types'

const EmailOutlined = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.80421 2.63478L10 8.09861L18.1958 2.63478C18.0396 2.12261 17.5633 1.75003 17 1.75003H3C2.43671 1.75003 1.96045 2.12261 1.80421 2.63478ZM18.25 4.40141L10.416 9.62403C10.1641 9.79198 9.8359 9.79198 9.58398 9.62403L1.75 4.40141V13C1.75 13.6904 2.30964 14.25 3 14.25H17C17.6904 14.25 18.25 13.6904 18.25 13V4.40141ZM0.25 3.00003C0.25 1.48125 1.48122 0.250031 3 0.250031H17C18.5188 0.250031 19.75 1.48125 19.75 3.00003V13C19.75 14.5188 18.5188 15.75 17 15.75H3C1.48122 15.75 0.25 14.5188 0.25 13V3.00003Z"
      fill={props.fill}
    />
  </svg>
)

export default EmailOutlined
