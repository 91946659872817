import NextLink from 'next/link'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { typographyComponents } from '../Typography'
import { ITypographyComponent } from '../Typography/utils/types'

interface IAnchorProps {
  disabled?: boolean
}
const Anchor = styled.a.attrs({ rel: 'noopener noreferrer' })<IAnchorProps>`
  cursor: pointer;
  ${props => props.disabled && 'pointer-events: none;'}

  :visited > * {
    color: ${props => props.theme.colors.blue700};
  }

  & > * {
    color: ${props => props.theme.colors.blue500};
    text-decoration: underline;

    ${props =>
    props.disabled
      && `
    color: ${props.theme.colors.neutral200};
    cursor: not-allowed;
  `}

    :hover,
    :focus,
    :active {
      color: ${props => props.theme.colors.blue400};
    }
  }
`

interface ILinkProps {
  href: string
  text: string
  type?: ITypographyComponent
  offsite?: boolean
  disabled?: boolean
}

const LinkText: FunctionComponent<ILinkProps> = ({ href, text, type, offsite, disabled }) => {
  const Text = typographyComponents[type || 'Body3']

  if (offsite || disabled) {
    return (
      <Anchor
        href={href}
        disabled={disabled}
        as={disabled ? 'span' : 'a'}
      >
        <Text tag="span">{text}</Text>
      </Anchor>
    )
  }

  return (
    <NextLink href={href}>
      <Anchor>
        <Text tag="span">{text}</Text>
      </Anchor>
    </NextLink>
  )
}

export default LinkText
