import { ReactNode, createContext, useReducer } from 'react'

import * as T from 'types'

enum actions {
  SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE',
  SET_FOCUSED_FIELD_BY_INDEX = 'SET_FOCUSED_FIELD_BY_INDEX',
  SET_MISSING_FIELD_BY_ID = 'SET_MISSING_FIELD_BY_ID',
  REMOVE_MISSING_FIELD_BY_ID = 'REMOVE_MISSING_FIELD_BY_ID',
  SET_MISSING_SUBSECTIONS = 'SET_REQUIRED_SUBSECTIONS',
  SET_MISSING_FIELDS_COUNT = 'SET_MISSING_FIELDS_COUNT',
}

type State = {
  currentFieldIndex: number
  currentRoute: string
  currentCompletion: number
  focusedFieldId: string
  missingFieldsArr: string[]
  missingSubsections: T.ISubsection[]
  missingFieldsTotalCount: number
  missingFieldsCount: number
}

type Action =
  | { type: actions.SET_CURRENT_ROUTE; currentRoute: string }
  | { type: actions.SET_FOCUSED_FIELD_BY_INDEX; currentFieldIndex: number }
  | { type: actions.SET_MISSING_FIELD_BY_ID; fieldId: string; currentRoute: string }
  | { type: actions.REMOVE_MISSING_FIELD_BY_ID; fieldId: string }
  | {
      type: actions.SET_MISSING_SUBSECTIONS
      subsections: T.ISubsection[]
      currentRoute: string
      percent: number
    }
  | { type: actions.SET_MISSING_FIELDS_COUNT; count: number; totalCount: number }

type ProviderProps = { children: ReactNode }

const initialState = {
  currentFieldIndex: 0,
  currentRoute: '',
  currentCompletion: 0,
  focusedFieldId: '',
  missingFieldsArr: [] as string[],
  missingSubsections: [] as T.ISubsection[],
  missingFieldsTotalCount: 0,
  missingFieldsCount: 0,
}

const reducer = (state: State, action: Action) => {
  let currentFieldIndex = state.currentFieldIndex
  let focusedFieldId = state.focusedFieldId
  let missingFieldsArr = [...state.missingFieldsArr]
  let currentRoute = state.currentRoute

  switch (action.type) {
    case actions.SET_CURRENT_ROUTE:
      if (action.currentRoute !== state.currentRoute) {
        missingFieldsArr = []
        currentFieldIndex = 0
        currentRoute = action.currentRoute
        focusedFieldId = ''
      }

      return { ...state, currentFieldIndex, currentRoute, focusedFieldId, missingFieldsArr }

    case actions.SET_FOCUSED_FIELD_BY_INDEX:
      currentFieldIndex = action.currentFieldIndex
      if (missingFieldsArr[currentFieldIndex]) focusedFieldId = missingFieldsArr[currentFieldIndex]

      return { ...state, currentFieldIndex, focusedFieldId }

    case actions.SET_MISSING_FIELD_BY_ID:
      if (action.currentRoute !== state.currentRoute) {
        missingFieldsArr = []
        currentFieldIndex = 0
        currentRoute = action.currentRoute
        focusedFieldId = ''
      }

      // add current route's missing fields
      if (missingFieldsArr.indexOf(action.fieldId) < 0) missingFieldsArr.push(action.fieldId)

      // assign default focusedFieldId if it is not set.
      if (!focusedFieldId && missingFieldsArr.length > 0) focusedFieldId = missingFieldsArr[0]

      return { ...state, currentFieldIndex, currentRoute, focusedFieldId, missingFieldsArr }

    case actions.REMOVE_MISSING_FIELD_BY_ID:
      if (missingFieldsArr.indexOf(action.fieldId) >= 0) {
        missingFieldsArr = missingFieldsArr.filter(fieldId => fieldId !== action.fieldId)
        // when any fieldId is removed (field input value has updated),
        // should set the next element in the array as a focused id.
        if (currentFieldIndex === missingFieldsArr.length) currentFieldIndex = 0

        focusedFieldId = missingFieldsArr[currentFieldIndex]
      }

      return { ...state, currentFieldIndex, focusedFieldId, missingFieldsArr }

    case actions.SET_MISSING_SUBSECTIONS: {
      if (action.currentRoute !== state.currentRoute) {
        missingFieldsArr = []
        currentFieldIndex = 0
        currentRoute = action.currentRoute
        focusedFieldId = ''
      }

      return {
        ...state,
        currentFieldIndex,
        currentRoute,
        currentCompletion: action.percent,
        focusedFieldId,
        missingFieldsArr,
        missingSubsections: action.subsections,
      }
    }

    case actions.SET_MISSING_FIELDS_COUNT:
      return {
        ...state,
        missingFieldsTotalCount: action.totalCount,
        missingFieldsCount: action.count,
      }
    default:
      return state
  }
}

interface Context {
  currentFieldIndex: number
  currentRoute: string
  currentCompletion: number
  focusedFieldId: string
  missingFieldsArr: string[]
  missingSubsections: T.ISubsection[]
  missingFieldsTotalCount: number
  missingFieldsCount: number
  setCurrentRoute: (currentRoute: string) => void
  setFocusedField: (currentFieldIndex: number) => void
  setMissingFieldById: (fieldId: string, currentRoute: string) => void
  removeMissingFieldById: (fieldId: string) => void
  setMissingSubsections: (
    subsections: T.ISubsection[],
    currentRoute: string,
    percent: number,
  ) => void
  setMissingFieldsCount: (count: number, totalCount: number) => void
}

let value: Context = {
  currentFieldIndex: initialState.currentFieldIndex,
  currentRoute: initialState.currentRoute,
  currentCompletion: initialState.currentCompletion,
  focusedFieldId: initialState.focusedFieldId,
  missingFieldsArr: initialState.missingFieldsArr,
  missingSubsections: initialState.missingSubsections,
  missingFieldsTotalCount: initialState.missingFieldsTotalCount,
  missingFieldsCount: initialState.missingFieldsCount,
  setCurrentRoute: () => {},
  setFocusedField: () => {},
  setMissingFieldById: () => {},
  removeMissingFieldById: () => {},
  setMissingSubsections: () => {},
  setMissingFieldsCount: () => {},
}

const GuidedTourContext = createContext<Context>(value)

const GuidedTourProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  value = {
    currentFieldIndex: state.currentFieldIndex,
    currentRoute: state.currentRoute,
    currentCompletion: state.currentCompletion,
    focusedFieldId: state.focusedFieldId,
    missingFieldsArr: state.missingFieldsArr,
    missingSubsections: state.missingSubsections,
    missingFieldsTotalCount: state.missingFieldsTotalCount,
    missingFieldsCount: state.missingFieldsCount,
    setCurrentRoute: (currentRoute: string) => {
      dispatch({ type: actions.SET_CURRENT_ROUTE, currentRoute })
    },
    setFocusedField: (currentFieldIndex: number) => {
      dispatch({ type: actions.SET_FOCUSED_FIELD_BY_INDEX, currentFieldIndex })
    },
    setMissingFieldById: (fieldId: string, currentRoute: string) => {
      dispatch({ type: actions.SET_MISSING_FIELD_BY_ID, fieldId, currentRoute })
    },
    removeMissingFieldById: (fieldId: string) => {
      dispatch({ type: actions.REMOVE_MISSING_FIELD_BY_ID, fieldId })
    },
    setMissingSubsections: (
      subsections: T.ISubsection[],
      currentRoute: string,
      percent: number,
    ) => {
      dispatch({ type: actions.SET_MISSING_SUBSECTIONS, subsections, currentRoute, percent })
    },
    setMissingFieldsCount: (count: number, totalCount: number) => {
      dispatch({ type: actions.SET_MISSING_FIELDS_COUNT, count, totalCount })
    },
  }
  return <GuidedTourContext.Provider value={value}>{children}</GuidedTourContext.Provider>
}

export { GuidedTourContext, GuidedTourProvider }
