import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const Heading1Text = styled.h1<ITypographyProps>`
  margin: 0;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  font-size: ${props => props.theme.fonts.sizes[36]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 49px;
  flex: none;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[44]}px;
    line-height: 60px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.theme.fonts.sizes[48]}px;
    line-height: 66px;
  }
`

const Heading1: FunctionComponent<ITypographyProps> = ({ children, ...props }) => (
  <Heading1Text
    className="heading1"
    {...props}
  >
    {children}
  </Heading1Text>
)

export default Heading1
