import { ReactNode, createContext, useReducer } from 'react'

enum actions {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}
type Action = { type: actions.SHOW } | { type: actions.HIDE }

type State = { isTooltipVisible: boolean }
const initialState = { isTooltipVisible: false }

type ProviderProps = { children: ReactNode }

function MarketConditionsReducer(state: State, action: Action) {
  switch (action.type) {
    case actions.SHOW:
      return { isTooltipVisible: true }
    case actions.HIDE:
      return { isTooltipVisible: false }
    default:
      throw new Error('Unhandled action type')
  }
}

interface IContext {
  isTooltipVisible: boolean
  showTooltip: () => void
  hideTooltip: () => void
}

let value: IContext = {
  isTooltipVisible: false,
  showTooltip: () => {},
  hideTooltip: () => {},
}

const MarketConditionsContext = createContext<IContext>(value)

const MarketConditionsProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(MarketConditionsReducer, initialState)

  value = {
    isTooltipVisible: state.isTooltipVisible,
    showTooltip: () => dispatch({ type: actions.SHOW }),
    hideTooltip: () => dispatch({ type: actions.HIDE }),
  }
  return (
    <MarketConditionsContext.Provider value={value}>{children}</MarketConditionsContext.Provider>
  )
}

export { MarketConditionsContext, MarketConditionsProvider }
