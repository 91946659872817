import useSWR, { mutate } from 'swr'

import { fetchWithToken } from 'services/fetchers'

import * as T from 'types'

import getErrorMessage from '../utils/getErrorMessage'
import { isServerError } from '../utils/isServerError'

export const useOrders = () => {
  const response = useSWR<T.IOrder[] | T.IServerError>('/orders/user/history', fetchWithToken)

  // return an empty array instead of undefined if there's an issue fetching
  let orders: T.IOrder[] = []
  if (response.data && !isServerError(response.data)) orders = response.data

  const error = getErrorMessage(response, 'order history')

  return { ...response, orders, error }
}

export const refreshOrders = () => mutate('/orders/user/open')
