import { useContext, useEffect } from 'react'

import { MissingInternetContext } from 'services/contexts'

// import { api } from '../api'
// import { isProduction } from 'utils/nodeEnv'

// const MAX_PINGS = 10

const initialState = { missingInternet: false, retrying: false }

const useMonitorInternetConnection = () => {
  if (typeof window === 'undefined') return initialState

  const {
    missingInternet,
    retrying,
    setConnected,
    setDisconnected,
    // setSearching,
  } = useContext(MissingInternetContext)

  useEffect(() => {
    window.addEventListener('offline', setDisconnected)
    window.addEventListener('online', setConnected)
    return () => {
      window.removeEventListener('offline', setDisconnected)
      window.removeEventListener('online', setConnected)
    }
  }, [])

  // this handles case where user does a hard refresh while their WiFi is disconnected
  useEffect(() => {
    if (!missingInternet && !window.navigator.onLine) setDisconnected()
  }, [missingInternet])

  // very simple retry logic; we should replace with something more robust
  // useEffect(() => {
  //   if (!isProduction()) console.log({ retrying})
  //   const pingNTimes = async () => {
  //     let pings = 0
  //     let pingSuccess = false
  //     while(pings < MAX_PINGS && !pingSuccess) {
  //       const pingResponse = await api.ping()
  //       if (pingResponse.ok) {
  //         pingSuccess = true
  //         setConnected()
  //       }
  //       pings += 1
  //       const wait = () => new Promise(
  //         (resolve) => setTimeout(
  //           () => {
  //             if (!isProduction()) console.log('retried... ', pings, ' times')
  //             resolve()
  //           }, 1000
  //         )
  //       )
  //       await wait()
  //     }
  //     if (!pingSuccess) setDisconnected()
  //   }
  //   if(retrying) pingNTimes()
  // }, [retrying])

  return { missingInternet, retrying }
}

export default useMonitorInternetConnection
