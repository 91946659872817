import Cookies from 'js-cookie'
import { mutate } from 'swr'

import { isProduction } from 'utils/nodeEnv'

import * as T from 'types'

import { api } from './api'
import { handleCampaignAssignmentSignupPersistence } from './vwo'

export const createUser = async (
  createUserParams: T.ISignupParams,
): Promise<[T.IListing | undefined, T.IServerError]> => {
  try {
    // handle persisting pre-signup and immediately post-signup VWO A/B test assignments
    const { vwoUserId, vwoExperiments } = await handleCampaignAssignmentSignupPersistence()
    if (vwoUserId) createUserParams.user.vwoUserId = vwoUserId

    if (vwoExperiments.length) createUserParams.user.vwoExperiments = vwoExperiments

    // preselectedPackageName from localStorage
    const preselectedPackageName = localStorage.getItem('preselectedPackageName') ?? undefined

    // create the user
    const response = await api.createUser({
      ...createUserParams,
      listing: {
        ...createUserParams.listing,
        preselectedPackageName,
      },
    })

    // if this condition is met, response.data will be in the shape of IServerError
    if (!response.ok || !response.data) return [undefined, response.data as T.IServerError]

    // remove pre-signup items from localStorage
    localStorage.removeItem('preselectedPackageName')

    // we get back several pieces of data on signup that we can use in the next steps
    const { listing: createdListing, contractDetails, token } = response.data

    // set the user's token as a cookie so we can persist their authentication
    Cookies.set('token', token, { expires: 14 })
    api.api.setHeader('Authorization', `Bearer ${token}`)

    // prefill new listing with third-party data from Zillow and Melissa Data API
    // this can save the users time on completing out the listing flow
    const populatedResponse = await api.getPropertyDetails(createdListing._id)
    const populatedListing = populatedResponse.data

    // return the original listing without populated data if above api call failed
    // (this just means the user won't have the pre-populated data and will have to enter it themselves)
    const listingToReturn = populatedListing || createdListing

    // mutate local cache with the data we received
    // NB: we also get user back from server, but don't put it in cache or some funny redirects can happen
    if (contractDetails) {
      mutate(`/contract-details/listing/${listingToReturn._id}/active`, contractDetails, false)
    }

    if (listingToReturn) {
      mutate(`/listings/${listingToReturn._id}/ownerDetails`, listingToReturn, false)
    }

    return [listingToReturn, {}]
  } catch (error) {
    return [undefined, { message: (error as Error).message }]
  }
}

export const getUser = async (userId: string) => {
  try {
    const response = await api.getUser(userId)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    document.cookie = `token=${response.data.token}`
  } catch (error) {
    console.error({ error })
  }
}

export const updateUser = async (
  userId: string,
  update: Partial<T.IUser>,
): Promise<T.IUser | undefined> => {
  try {
    const response = await api.updateUser(userId, update)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch ({ error }) {
    if (!isProduction()) console.error({ error })
  }
}
