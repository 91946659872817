import useSWR from 'swr'

import { fetchWithToken } from 'services/fetchers'

import * as T from 'types'

import getErrorMessage from '../utils/getErrorMessage'

export const useDocuments = (listingId?: string) => {
  const url = listingId ? `/documents/listing/${listingId}` : null
  const response = useSWR<T.IListingDocument[] | T.IServerError>(url, fetchWithToken)

  const error = getErrorMessage(response, 'listingDocuments')

  const listingDocuments = error ? undefined : (response.data as T.IListingDocument[] | undefined)

  return { ...response, listingDocuments, error }
}
