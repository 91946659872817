import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import { RouteProtectionContext } from 'services/contexts'
import { useUser } from 'services/swr/useUser'

const PRIVATE_ROUTES = ['listing', 'account', 'checkout', 'document']

const SIGN_ROUTE_PART = 'sign'

const useProtectRoute = () => {
  const router = useRouter()
  const onSignInPage = router.pathname === '/signin'

  const { user, isValidating, error, mutate } = useUser()

  const { authenticated, setAuthenticated, setUnauthenticated } = useContext(RouteProtectionContext)

  const [initialPageLoad, setInitialPageLoad] = useState(true)
  const [revalidateTriggered, setRevalidateTriggered] = useState(false)

  // instead of redirecting immediately, we set a `needsRedirect` bool
  // we have to wait a couple renders for next/router to populate `query.id` for listings,
  // which we need in order to redirect
  const [needsSignInRedirect, setNeedsSignInRedirect] = useState(false)
  useEffect(() => {
    const redirectToSignIn = () => {
      router.replace({
        pathname: '/signin',
        query: { redirect: router.asPath },
      })
    }

    if (onSignInPage) return

    if (needsSignInRedirect) {
      setNeedsSignInRedirect(false) // prevent infinite loop
      redirectToSignIn()
    }
  }, [needsSignInRedirect, router.query])

  useEffect(() => {
    const routeParts = router.pathname.split('/').filter(routePart => !!routePart)

    // unprotect 'documents/sign/[token]' to allow title holders to sign agreement
    if (routeParts.includes(PRIVATE_ROUTES[3]) && routeParts.includes(SIGN_ROUTE_PART)) return

    // if the pathname is not in our private routes array, we don't need to protect it
    if (!PRIVATE_ROUTES.includes(routeParts[0]) && routeParts[0] !== 'signin') return

    // special cases for `/listing`-based routes, which are usually private
    // this condition makes `/listing/:customLinkUrl/` and `/listing/create` publically accessible
    if (routeParts[0] === 'listing' && routeParts.length === 2) {
      return // create, dashboard, and regular /listing/[id] are ok. Preview is routeParts[3] now
    }

    if (routeParts[0] === 'listing' && routeParts.length === 3) {
      if (routeParts[2] !== 'preview') return // aka public view listing page: `listing/[id]`
    }

    // this condition makes `/listing/[id]/open-house-form` publically accessible
    if (routeParts[3] === 'open-house-form') return

    // when we come from a public page to a private page while newly authenticated, we need to
    // clear the existing swr error and refetch the user
    if (router.query.fromSignup && !revalidateTriggered) {
      mutate({}, true) // clears error, sets isValidating to true
      return setRevalidateTriggered(true)
    }

    // if the `/me` auth call returns an error (aka Unauthorized), we can reroute immediately
    if (error && !onSignInPage) {
      setUnauthenticated()
      setNeedsSignInRedirect(true)
      return
    }

    // don't do anything on the first render
    if (initialPageLoad) return setInitialPageLoad(false)

    // wait until validation is complete
    if (isValidating) return

    // if no user, reroute to signin
    if (!isValidating && !user && !onSignInPage) {
      setUnauthenticated()
      setNeedsSignInRedirect(true)
      return
    }

    // protect signin route if a user IS signed in
    if (user && onSignInPage) {
      setAuthenticated()
      return
    }

    // otherwise set loadingUser to false so the component knows it's safe to show UI
    if (user) {
      setAuthenticated()
    }
  }, [isValidating, error, router.query, onSignInPage, initialPageLoad])

  // need to reset revalidatedTriggered on page reset to
  // account for edge case where user makes multiple accounts during same session
  useEffect(() => {
    if (!router.query.fromSignup) setRevalidateTriggered(false)
  }, [router.query])

  return { authenticated }
}

export default useProtectRoute
