import * as T from './types'

const LogOutOutlined = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.87868 0.878679C1.44129 0.31607 2.20435 0 3 0H10C10.7957 0 11.5587 0.31607 12.1213 0.878679C12.6839 1.44129 13 2.20435 13 3V5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H10C10.2652 16 10.5196 15.8946 10.7071 15.7071C10.8946 15.5196 11 15.2652 11 15V13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13V15C13 15.7956 12.6839 16.5587 12.1213 17.1213C11.5587 17.6839 10.7957 18 10 18H3C2.20435 18 1.44129 17.6839 0.87868 17.1213C0.31607 16.5587 0 15.7956 0 15V3C0 2.20435 0.31607 1.44129 0.87868 0.878679ZM15.2929 5.29292C15.6834 4.90239 16.3166 4.90239 16.7071 5.29292L19.7071 8.29292C20.0976 8.68344 20.0976 9.31661 19.7071 9.70713L16.7071 12.7071C16.3166 13.0977 15.6834 13.0977 15.2929 12.7071C14.9024 12.3166 14.9024 11.6834 15.2929 11.2929L16.5858 10H5C4.44772 10 4 9.55231 4 9.00003C4 8.44774 4.44772 8.00003 5 8.00003H16.5858L15.2929 6.70713C14.9024 6.31661 14.9024 5.68344 15.2929 5.29292Z"
      fill={props.fill}
    />
  </svg>
)

export default LogOutOutlined
