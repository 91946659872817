import styled, { DefaultTheme, StyledComponent } from 'styled-components'

import { ITypographyProps, Tag, TextColorName, TypographyType } from './types'

// @FIXME - waiting on the DS to be updated with the new color type names
export const getColor = (
  color: TextColorName | undefined,
  type: TypographyType | undefined,
  theme: DefaultTheme,
) => {
  if (color) return theme.colors[color]

  switch (type) {
    case 'primary':
      return theme.colors.neutral900
    case 'secondary':
      return theme.colors.gold600
    case 'tertiary':
      return theme.colors.blue700
    case 'disabled':
      return theme.colors.neutral500
    case 'error':
      return theme.colors.red500
    case 'success':
      return theme.colors.green500
    case 'warning':
      return theme.colors.yellow500
    case 'default':
    default:
      return theme.colors.neutral900
  }
}

export const getComponent = (
  component: StyledComponent<'div', DefaultTheme, ITypographyProps, never>,
  tag?: Tag,
) => {
  const Span = styled(component).attrs({ as: 'span' })``
  const Div = styled(component).attrs({ as: 'div' })``

  switch (tag) {
    case 'span':
      return Span
    case 'div':
      return Div
    case 'p':
    default:
      return component
  }
}
