import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const CaptionText = styled.div<ITypographyProps>`
  padding-top: 1px; /* font correction */
  font-weight: ${props => props.theme.fonts.weights.medium};
  font-size: ${props => props.theme.fonts.sizes[12]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  line-height: 16px;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
`

const Caption: FunctionComponent<ITypographyProps> = ({ children, ...props }) => (
  <CaptionText {...props}>{children}</CaptionText>
)

export default Caption
