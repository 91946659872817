import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const Heading4Text = styled.h4<ITypographyProps>`
  margin: 0;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  font-size: ${props => props.theme.fonts.sizes[20]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 27px;
  flex: none;
  flex-grow: 0;
  padding-top: 2px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[24]}px;
    line-height: 33px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.theme.fonts.sizes[28]}px;
    line-height: 38px;
  }
`

const Heading4: FunctionComponent<ITypographyProps> = ({ children, ...props }) => (
  <Heading4Text {...props}>{children}</Heading4Text>
)

export default Heading4
