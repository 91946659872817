import SVG from './SVG'
import * as T from './types'

const Edit = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 18 18"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18 8.5V16C18 17.1046 17.1046 18 16 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0H9.5C9.77614 0 10 0.223858 10 0.5V1.5C10 1.77614 9.77614 2 9.5 2H2V16H16V8.5C16 8.22386 16.2239 8 16.5 8H17.5C17.7761 8 18 8.22386 18 8.5ZM17.71 0.71L17.29 0.29C17.1037 0.105256 16.8523 0.00110462 16.59 0H16.29C16.0277 0.00110462 15.7763 0.105256 15.59 0.29L13.79 2.09C13.5994 2.28442 13.5994 2.59558 13.79 2.79L15.21 4.21C15.4044 4.40057 15.7156 4.40057 15.91 4.21L17.71 2.41C17.8947 2.22374 17.9989 1.97234 18 1.71V1.41C17.9989 1.14766 17.8947 0.896261 17.71 0.71ZM4.04 13.29L4.83 11.29C4.92875 11.0357 5.07849 10.8043 5.27 10.61L11.85 4C12.0444 3.80943 12.3556 3.80943 12.55 4L14 5.45C14.1906 5.64442 14.1906 5.95558 14 6.15L7.37 12.71C7.17571 12.9015 6.9443 13.0512 6.69 13.15L4.69 13.94C4.50421 14.0148 4.29173 13.9715 4.15 13.83C4.00849 13.6883 3.96521 13.4758 4.04 13.29Z"
  />
)

export default Edit
