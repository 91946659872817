/**
 * until we have time to refactor more business logic out to the api, some of these methods are also located
 * in the api repo @ server/api/completionPercentages/utils/*.js
 * modifications to these will likely need to be made in the api repo as well
 */
import dayjs from 'dayjs'
import { ParsedUrlQuery } from 'querystring'

import { updateFlowState } from 'services/flowStates'

import { negate } from 'utils/function'

import * as T from 'types'
import { IServerError } from 'types'
import * as E from 'types/enums'

import { additionalRooms, defaultRooms } from './defaultRooms'
import { pricingQuestions } from './pricingQuestions'
import { addCommas } from './stringFormatting'

type IParentValue = T.ValueOf<T.IListing> | T.IChecklistValue
type IGetFulfilled = (parentValue: IParentValue, conditionalValue: string) => boolean

const isStrictlyEqual: IGetFulfilled = (parentValue, conditionalValue) => {
  try {
    // try to process checklist
    const parsedValue = JSON.parse(String(parentValue))
    return !!parsedValue[conditionalValue]
  } catch (e) {
    // process string value
    return parentValue === conditionalValue
  }
}

const getFulfilledByCondition: Record<string, IGetFulfilled> = {
  '<': (parentValue, conditionalValue) => Number(parentValue) < Number(conditionalValue),
  '>': (parentValue, conditionalValue) => Number(parentValue) > Number(conditionalValue),
  '<=': (parentValue, conditionalValue) => Number(parentValue) <= Number(conditionalValue),
  '>=': (parentValue, conditionalValue) => Number(parentValue) >= Number(conditionalValue),
  '===': isStrictlyEqual,
  '!==': negate(isStrictlyEqual),
}

const checkCondition = (
  parentValue: IParentValue,
  conditionalValue?: string,
  condition?: string,
) => {
  if (!condition || !conditionalValue) return false

  const getFulfilled = getFulfilledByCondition[condition]

  return getFulfilled?.(parentValue, conditionalValue) ?? false
}

const checkPropertyTypeCondition = (listing: T.IListing, conditionalPropertyType?: string[]) => {
  const propertyOptions = ['Condo', 'Coop', 'House', 'Duplex', 'Land', 'Townhouse', 'Other']

  if (
    !conditionalPropertyType
    || conditionalPropertyType.length === 0
    || propertyOptions.sort().join(',') === conditionalPropertyType.sort().join(',')
    || !listing.propertyType
  ) {
    return true
  }

  return conditionalPropertyType.includes(listing.propertyType)
}

export const formatZillowAddress = (listing?: T.IZillowAddress, commas?: boolean) => {
  if (!listing) return ''

  if (commas) {
    return `${listing.street}, ${listing.city}, ${listing.state} ${
      listing.zip || listing.zipcode || ''
    }`
  }

  return `${listing.street} ${listing.city} ${listing.state} ${listing.zip || listing.zipcode}`
}

// returns an array of analytics events for listing steps and percentages completed
export const getAnalyticsCompletionEvents = (
  listing?: T.IListing,
  completionPercentages?: T.ICompletionPercentages,
  analyticsEvents?: T.ITriggeredEvent[],
) => {
  if (!listing || !completionPercentages || !analyticsEvents) return []

  const percentComplete = completionPercentages.totalPercent

  const eventWasntTracked = (event: T.ITriggeredEvent) =>
    !analyticsEvents?.find(
      (triggeredEvent: T.ITriggeredEvent) => triggeredEvent.event === event.event,
    )

  const eventsToTrack: T.ITriggeredEvent[] = []
  const times = Math.floor(percentComplete / 10)

  for (let i = 1; i <= times; i++) {
    const event = {
      event: `${i}0% of Listing Completed`,
      type: 'fe',
    }

    if (eventWasntTracked(event)) {
      eventsToTrack.push(event)
    }
  }

  Object.keys(completionPercentages.sections).forEach((key: string) => {
    const subsections = completionPercentages.sections[key].subsections || {}
    Object.keys(subsections).forEach((subsectionKey: string) => {
      const subsection = subsections[subsectionKey] || {}

      const event = {
        event: `${subsection.name} Step Completed`,
        type: 'fe',
      }
      if (subsection.percent === 100 && eventWasntTracked(event)) {
        eventsToTrack.push(event)
      }
    })
  })

  return eventsToTrack
}

export const getFieldValueFromListing = (
  listing: T.IListing,
  fieldName: string,
  fieldId?: string,
  isCoreField?: boolean,
) => {
  if (!listing) return ''

  if (isCoreField) {
    return listing[fieldName] || ''
  }

  const foundField = listing.fields.find((field: T.IField) => {
    if (fieldId) {
      return field.fieldId?._id === fieldId
    }

    return field.fieldId?.name === fieldName
  })
  return foundField ? foundField.value : ''
}

export const getPricingQuestionValueFromListing = (
  listing: T.IListing,
  question: T.IPricingQuestion,
) => {
  const { type } = question
  const defaultValue = type === 'checklist' ? {} : ''
  if (!listing || !listing.pricingQuestions) return defaultValue

  const value = listing.pricingQuestions[question.model]
  return value || defaultValue
}

export const filterVisibleFieldsForScreen = (
  listing: T.IListing,
  fields: T.IMlsField[],
  listingFlowSubStep: string,
  publicListing = false,
) =>
  fields.filter((field: T.IMlsField) => {
    // visibility
    const visibilityCheck = publicListing ? field.publiclyVisible : field.visible

    // substep match (only required for non-public listings)
    const listingFlowSubStepCheck = publicListing
      ? true
      : listingFlowSubStep !== '' && field.listingFlowStep === listingFlowSubStep

    // conditional fields meet their condition(s), if they exist
    const { conditionalField, condition, conditionalValue, conditionalPropertyType } = field
    let isConditionalField = !!(conditionalField && condition && conditionalValue)
    const isPropertyTypeConditional = conditionalPropertyType && conditionalPropertyType.length
    let conditionFulfilled = false

    if (isConditionalField) {
      const parentField = fields.find(
        (fld: T.IMlsField) => fld.fieldId && fld.fieldId._id === conditionalField,
      )

      const parentValue = parentField
        ? getFieldValueFromListing(
          listing,
          String(parentField.fieldId?.name),
          String(parentField.fieldId?._id),
          parentField.isCoreField,
        )
        : ''
      conditionFulfilled = checkCondition(parentValue, conditionalValue, condition)
    }

    if (isPropertyTypeConditional) {
      conditionFulfilled
        = checkPropertyTypeCondition(listing, conditionalPropertyType)
        && (isConditionalField ? conditionFulfilled : true)
      isConditionalField = true
    }

    const conditionalFieldCheck = isConditionalField ? conditionFulfilled : true

    return visibilityCheck && listingFlowSubStepCheck && conditionalFieldCheck
  })

export const filterVisibleFieldsForPricing = (listing: T.IListing) =>
  pricingQuestions.filter((field: T.IPricingQuestion) => {
    const { conditionalField, conditionalFieldName, condition, conditionalValue } = field

    const isConditionalField
      = conditionalField && conditionalFieldName && condition && conditionalValue

    let conditionFulfilled = false

    if (isConditionalField) {
      const parentField = pricingQuestions.find(
        (prntField: T.IPricingQuestion) => prntField.model === conditionalFieldName,
      )
      if (!parentField || !listing.pricingQuestions) return false

      // const parentValue = getPricingQuestionValueFromListing(listing, parentField)
      const parentValue = listing.pricingQuestions[parentField.model]
      conditionFulfilled = checkCondition(parentValue, conditionalValue, condition)
    }

    const conditionalFieldCheck = isConditionalField ? conditionFulfilled : true
    return conditionalFieldCheck
  })

interface IObjectWithNameProperty {
  name: string
  [key: string]: any
}
const sortByName = (a: IObjectWithNameProperty, b: IObjectWithNameProperty) => {
  if (a.name < b.name) {
    return -1
  }

  if (a.name > b.name) {
    return 1
  }

  return 0
}

export const setupRooms = (listing: T.IListing) => {
  const { rooms } = listing

  const response = {
    rooms: defaultRooms,
    additionalRooms,
  }

  if (rooms) {
    const union = [...rooms, ...defaultRooms]
    const filtered = union.filter((item: T.IRoom, index: number) => union.indexOf(item) === index)

    const roomsWithValues = filtered.map((room: T.IRoom) => ({
      ...room,
      selected: typeof room.selected === 'undefined' ? true : room.selected,
    }))

    const updatedAdditionalRooms = additionalRooms.map((additionalRoom: T.IRoom) => ({
      ...additionalRoom,
      selected: !!rooms.find((room: T.IRoom) => room.name === additionalRoom.name),
    }))

    const selectedRooms = updatedAdditionalRooms.filter((addRoom: T.IRoom) => !!addRoom.selected)

    const uniqueRooms = [...roomsWithValues, ...selectedRooms].filter(
      (room: T.IRoom, index: number, self: T.IRoom[]) =>
        index === self.findIndex((roomObj: T.IRoom) => roomObj.name === room.name),
    )

    response.rooms = uniqueRooms
    response.additionalRooms = updatedAdditionalRooms
  }

  const checkRequired = (name: string) =>
    ['Kitchen', 'Living Room', 'Master Bedroom', 'Master Bathroom', 'Garage'].includes(name)

  const requiredRooms = response.rooms
    .filter((room: T.IRoom) => checkRequired(room.name))
    .sort(sortByName)

  const notRequiredRooms = response.rooms
    .filter((room: T.IRoom) => !checkRequired(room.name))
    .sort(sortByName)

  response.rooms = [...requiredRooms, ...notRequiredRooms]
  return response
}

export const getSubsections = (
  completionPercentages?: T.ICompletionPercentages,
): { requiredSubsections: T.ISubsection[]; subsections: T.ISubsection[] } => {
  const requiredSubsections: T.ISubsection[] = []
  let subsections: T.ISubsection[] = []

  const { sections = {} } = completionPercentages || ({} as Partial<T.ICompletionPercentages>)

  Object.values(sections).forEach((section: T.ISection) => {
    if (section.subsections) {
      subsections = [...subsections, ...Object.values(section.subsections)]
    }
  })

  subsections.forEach((subsection: T.ISubsection) => {
    if (typeof subsection.percent !== 'undefined' && subsection.percent < 100) {
      if (subsection.route === 'agreement') return

      requiredSubsections.push(subsection)
    }
  })

  return { requiredSubsections, subsections }
}

export const showChecklistValue = (value: string | undefined) => {
  if (!value) return ''

  // try/catch bc if string isn't json for some reason, we don't want JSON.parse to crash app
  try {
    const parsedValue = JSON.parse(value)
    const keys = Object.keys(parsedValue).filter((key: string) => !!parsedValue[key])
    if (!keys.length) return 'N/A'

    return keys.join(', ')
  } catch (err) {
    return value
  }
}

export const showValue = (field: T.IField | T.IMlsField) => {
  if (field.fieldId?.type === 'checklist') return showChecklistValue(field.value)

  return field.value
}

export const showFieldsWithValues = (listing?: T.IListing, schemaFields?: T.IMlsField[]) => {
  if (!listing || !schemaFields) return []

  return schemaFields
    .map((schemaField: T.IMlsField) => {
      if (schemaField.isCoreField) {
        const key = schemaField.fieldId?.name
        let value: T.ValueOf<T.IListing> = ''
        if (key) value = listing[key]

        if (!value && !Number.isInteger(value)) value = '' // avoids stringifying null/undefined into 'null'/'undefined'

        return {
          ...schemaField,
          value: String(value),
        }
      }

      const field = listing.fields.find(fld => fld.fieldId?._id === schemaField.fieldId?._id)
      return {
        ...schemaField,
        value: field?.value ?? '',
      }
    })
    .filter((field?: T.IMlsField) => {
      if (!field) return false

      if (!field?.publiclyVisible) return false

      return field && field.value && field.value !== ''
    })
}

export const showPositiveFields = (fields: T.IMlsField[]) =>
  fields.filter((field: T.IMlsField) => {
    if (field.fieldId?.type === 'checklist') {
      if (!field.value || field.value === 'undefined') return false

      let parsedValue: T.IChecklistValue = {}

      try {
        if (field.value) parsedValue = JSON.parse(String(field.value))
      } catch (e) {
        // log the error so it doesn't get swallowed
        // eslint-disable-next-line no-console
        console.error('Checklist received value of wrong format:', field.value)
      }

      const keys = Object.keys(parsedValue).filter((key: string) => !!parsedValue[key])
      return !keys.includes('None')
    }

    return field.value !== 'No' && field.value !== '0'
  })

// fields ordered by by `fieldOrder` may not be sub-ordered by group
// this fn takes a flat array of fields and returns arrays of fields that share a group
export const groupFields = (fields: T.IMlsField[], key?: string): T.IMlsField[][] => {
  if (!fields.length) return []

  const fieldsWithoutGroup: T.IMlsField[] = []
  const fieldsToReturn: T.IMlsField[][] = [] // our output nested array

  // dictionary to keep track of group indices in `fieldsToReturn`so we know which nested array
  // to place a field in
  const indexDictionary: { [key: string]: number } = {}

  fields.forEach((field: T.IMlsField) => {
    const group = field[key || 'listingFlowSubStep']

    // guard against bad `key` parameter values that don't correspond to a property on the field
    if (typeof group !== 'string' && typeof group !== 'undefined') {
      throw new Error('You must group by a string property')
    }

    // no group
    if (!group) return fieldsWithoutGroup.push(field)

    // yet unseen group
    if (!Object.prototype.hasOwnProperty.call(indexDictionary, group)) {
      indexDictionary[group] = fieldsToReturn.length
      fieldsToReturn.push([])
    }

    // add field to already seen group
    fieldsToReturn[indexDictionary[group]].push(field)
  })
  if (!fieldsWithoutGroup.length) return fieldsToReturn

  return fieldsToReturn.concat([fieldsWithoutGroup])
}

export const markSubstepCompleted = async (
  section: T.ISection | T.ISubsection,
  listing?: T.IListing,
  flowState?: T.IFlowState,
  mutate?: (
    data?: IServerError | T.IFlowState | undefined,
    shouldRevalidate?: boolean | undefined,
  ) => Promise<T.IFlowState | IServerError | undefined>,
) => {
  if (!listing || !flowState || !flowState.flowSubstepsCompletion || !mutate) return

  const currentFlowSubstepIndex = flowState.flowSubstepsCompletion.findIndex(
    (completionStep: T.ISubstepCompletion) => completionStep.permanentId === section.name,
  )

  if (currentFlowSubstepIndex >= 0) {
    if (flowState.flowSubstepsCompletion[currentFlowSubstepIndex].completed) return

    flowState.flowSubstepsCompletion[currentFlowSubstepIndex].completed = true
  } else {
    flowState.flowSubstepsCompletion.push({
      route: section.route,
      permanentId: section.name,
      completed: true,
    })
  }

  const [updatedFlowState, flowStateError] = await updateFlowState(listing._id, flowState)

  if (!flowStateError.message && mutate) {
    mutate(updatedFlowState, true)
  }
}

// validate listing address properties
// interpolation of "undefined" check is done as we have experienced google place returning
// listing address property with such value.
export const isListingAddressInvalid = (listing: T.IListing | undefined) => {
  let isInvalid = false

  if (listing) {
    const { street, city, state, zip } = listing
    const listingAddressObj = { street, city, state, zip }

    // using for...of loop to easily break on first invalid instance
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(listingAddressObj)) {
      if (value === null || value === undefined || value.includes('undefined') || value === '') {
        isInvalid = true
        return isInvalid
      }
    }
  }

  return isInvalid
}

// check if aptNum contains unit name/abbreviation if not prepend default value "Unit"
export const getFormattedApartmentNumber = (aptNum: string): string => {
  if (!aptNum) return ''

  const unitFormats = ['apartment', 'apt', 'building', 'bldg', 'unit', 'suite', 'ste']
  const isAptNumCompleted = unitFormats.some(value => aptNum.toLowerCase().includes(value))
  return isAptNumCompleted ? aptNum : `Unit ${aptNum}`
}

type IFormatAddressListing =
  | T.IListing
  | T.ISparseListing
  | T.ICreateListingData
  | T.ICreateListingData

export const formatAddress = (listing: IFormatAddressListing, removeZipcode?: boolean) => {
  const street = listing.aptNum
    ? `${listing.street} ${getFormattedApartmentNumber(listing.aptNum)}`
    : listing.street

  const address = `${street}, ${listing.city}, ${listing.state}`
  const zip = listing.zip ?? ''
  return removeZipcode ? address : `${address} ${zip}`
}

export const sortByDateModified = (
  listingA: T.IListing | T.ISparseListing,
  listingB: T.IListing | T.ISparseListing,
) => {
  if (listingA.updatedAt > listingB.updatedAt) return -1

  if (listingA.updatedAt < listingB.updatedAt) return 1

  return 0
}

export const getAgreementOverride = (docs: T.IListingDocument[] = []) =>
  docs.find((doc: T.IListingDocument) => doc.type === 'Agreement Override' && !doc.archived)

export const prepareDocuments = (
  listing?: T.IListing,
  dashboardDocuments?: T.IAdminDashboardDocument[],
) => {
  if (!listing || !dashboardDocuments) return []

  return dashboardDocuments
    .filter((document: T.IAdminDashboardDocument) => {
      const { conditionalPropertyType, fieldConditionals } = document

      const propertyConditionFulfilled = checkPropertyTypeCondition(
        listing,
        conditionalPropertyType,
      )

      if (fieldConditionals && fieldConditionals.length) {
        let conditionFulfilled = false
        fieldConditionals.forEach((field: T.IFieldConditional) => {
          if (field.fieldName && field.condition) {
            const mlsField = listing.fields.find(
              (fld: T.IField) => fld.fieldId.name === field.fieldName,
            )

            const mlsValue = mlsField?.value || ''
            const parentFieldValue = listing[field.fieldName] || mlsValue

            if (checkCondition(parentFieldValue, field.value, field.condition)) {
              conditionFulfilled = true
            }
          }
        })
        return conditionFulfilled && propertyConditionFulfilled
      }

      return propertyConditionFulfilled
    })
    .map((document: T.IAdminDashboardDocument) => ({
      ...document,
      url: `${process.env.API_URL}/amazons/dashboardDocument/${document._id}`,
    }))
}

const sortDocuments = (docs: T.IDashboardDocument[] | T.IListingDocument[]) =>
  docs.sort((a: any, b: any) => {
    if (dayjs(a.uploadedAt || 0).isAfter(dayjs(b.uploadedAt || 0))) return -1

    if (dayjs(b.uploadedAt || 0).isAfter(dayjs(a.uploadedAt || 0))) return 1

    return 0
  })

export const getOwnerDocuments = (listing?: T.IListing) => {
  const newDocuments: T.IDashboardDocument[] = []
  if (!listing) return newDocuments

  listing.owners.forEach((owner: T.IOwner, index) => {
    if (owner.agreement) {
      newDocuments.push({
        name: owner.agreement.filename,
        ...owner.agreement,
        url: `${process.env.API_URL}/amazons/listingDocument/${
          listing._id
        }/owners.agreement/${index}/${encodeURIComponent(owner.agreement.filename)}`,
      })
    }
  })

  return sortDocuments(newDocuments)
}

export const getHellosignDocuments = (listing?: T.IListing) => {
  const newDocuments: T.IDashboardDocument[] = []
  if (!listing) return newDocuments

  // temp arrays to support additional data transformationgs
  const agreements: T.IListingMlsDocument[] = []
  const amendments: T.IListingMlsDocument[] = []

  listing.mlsList.forEach((mlsItem: T.IListingMls) => {
    mlsItem.documents.forEach((document: T.IListingMlsDocument) => {
      if (document.listingAgreement) {
        agreements.push(document)
      } else {
        amendments.push(document)
      }
    })
  })

  agreements.forEach((agreement: T.IListingMlsDocument) => {
    newDocuments.push({
      ...agreement,
      name: agreement.name,
      needsToStart: false,
      isSigned: agreement.signed,
      partiallySigned: !agreement.signers.every((signer: T.ISigner) => signer.signed),
      notSigned: agreement.signers.every((signer: T.ISigner) => !signer.signed),
      viewingToken: agreement.signatureRequestId,
      signingToken: agreement.signers[0].token,
    })
  })

  amendments.forEach(doc => {
    newDocuments.push({
      ...doc,
      needsToStart: false,
      isSigned: doc.signed,
      partiallySigned: !doc.signers.every((signer: T.ISigner) => signer.signed),
      notSigned: doc.signers.every((signer: T.ISigner) => !signer.signed),
      viewingToken: doc.signatureRequestId,
      signingToken: doc.signers[0].token,
    })
  })
  return sortDocuments(newDocuments)
}

export const prepareListingDocuments = (docs?: T.IListingDocument[], filterType?: string) => {
  if (!docs) return []

  const newDocuments: T.IListingDocument[] = docs
    .filter((doc: T.IListingDocument) => !doc.archived)
    .map((doc: T.IListingDocument) => {
      let name = doc.name
      if (doc.type) name += ` (${doc.type})`

      return {
        ...doc,
        name,
        originalUrl: doc.url,
        url: `${process.env.API_URL}/amazons/viewDocumentById/${doc._id}`,
      }
    })

  if (filterType) return newDocuments.filter(document => document.type === filterType)

  return sortDocuments(newDocuments)
}

export const getMetaDescriptionFromListing = (listing: T.IListing) => {
  if (!listing) return ''

  const address = formatAddress(listing, false)

  if (listing.propertyType === E.HomeTypeEnum.land) {
    const lotSize = listing.lotSize || 0
    const lotSizeType = listing.lotSizeType === 'Sqft' ? 'square feet' : 'acres of'
    return `${lotSize} ${lotSizeType} land at ${address} for sale on HomeLister.com.`
  }

  let bath = listing.bath ? listing.bath : 0
  if (listing.bathHalf) bath += listing.bathHalf ? 0.5 : 0

  const sqft = listing.sqft ? addCommas(listing.sqft) : 0
  const dimensions = `${listing.bed || 0} bedroom/${bath} bathroom/${sqft} square foot`
  return `${dimensions} home at ${address} on HomeLister.com!`
}

export const getSocialShareTextFromListing = (listing: T.IListing) => {
  if (!listing) return ''

  const address = formatAddress(listing, false)

  if (listing.propertyType === E.HomeTypeEnum.land) {
    const lotSize = listing.lotSize || 0
    const lotSizeType = listing.lotSizeType === 'Sqft' ? 'square feet' : 'acres of'
    return `Check out this ${lotSize} ${lotSizeType} land at ${address} on HomeLister.com!#homelister`
  }

  let bath = listing.bath ? listing.bath : 0
  if (listing.bathHalf) bath += listing.bathHalf ? 0.5 : 0

  const sqft = listing.sqft ? addCommas(listing.sqft) : 0
  const dimensions = `${listing.bed || 0} bedroom/${bath} bathroom/${sqft} square foot`
  return `Check out this beautiful ${dimensions} home at ${address} on HomeLister.com! #homelister`
}

export const getLinkQueryParams = (link: string, query: ParsedUrlQuery) => {
  const queryParams = Object.keys(query)
    .map(key => `${key}=${query[key]}`)
    .join('&')
  return queryParams ? `${link}?${queryParams}` : link
}

// field "isPublished" exist in DB but it seems that is currently not being used
// therefore the listingStatusName is used to check if listing is published
export const isPublished = (listing: T.IListing) => listing.listingStatusName === 'Published'

// Check if listing contains MLS specific fee requirements
export const getMlsSpecificFeeData = (
  listing: T.IListing,
): {
  title?: string
  message?: string
  mlsRules: string[]
} => {
  const state = listing.state
  const mlsType = listing.mlsList[0].mlsLiveIn

  if (state === 'GA' && mlsType.includes('GAMLS')) {
    return {
      title: 'Local Georgia MLS Fees',
      mlsRules: [
        'Your local MLS charges a cancellation fee of $35 if you cancel your listing before it sells.',
      ],
    }
  }

  if (state === 'GA' && mlsType.includes('FMLS')) {
    return {
      title: 'Local Georgia MLS Fees',
      mlsRules: [
        'Your local MLS charges a cancellation fee of $70 if you cancel your listing before it sells.',
        'The FMLS charges sellers an additional 0.12% commission upon closing. For example, on a $300,000 house, the seller would owe the FMLS about $360. This commission is based on the final sale price and is paid directly to the FMLS not HomeLister.',
      ],
    }
  }

  if (state === 'NJ' && (mlsType.includes('Garden State') || mlsType.includes('GSMLS'))) {
    return {
      title: 'Local New Jersey MLS Fees',
      mlsRules: [
        'Your local MLS charges a cancellation fee of $25 if you cancel your listing before it sells.',
      ],
    }
  }

  if (state === 'NY' && mlsType.includes('NY Global MLS')) {
    return {
      title: 'Local New York MLS Fees',
      mlsRules: [
        'Your local MLS charges a cancellation fee of $50 if you cancel your listing before it sells.',
      ],
    }
  }

  return { mlsRules: [] }
}

export const getPackageLabel = (listing?: T.IListing) => {
  if (!listing) return ''

  const stateCodes = listing.pricingPackage.stateCodes || []
  const packageName = listing.pricingPackage.name
  return stateCodes.length
    ? `${stateCodes.filter(state => state === listing.state).join(', ')} ${packageName}`
    : packageName
}
