export const INTERCOM_DEFAULT_VERTICAL_PADDING = 20

export const INTERCOM_LISTING_FLOW_VERTICAL_PADDING = 20
export const INTERCOM_LISTING_FLOW_VERTICAL_PADDING_PHONE = 60

export const INTERCOM_DEFAULT_HORIZONTAL_PADDING = 20
export const INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING = 10

/*
  intercom configuration
  API docs: https://developers.intercom.com/installing-intercom/docs/configuration
*/
export interface intercomSetting {
  app_id?: string
  vertical_padding?: number
  horizontal_padding?: number
}

const intercomSettings: { [key: string]: intercomSetting } = {
  defaultIntercomSetting: {
    app_id: process.env.INTERCOM_APP_ID,
    vertical_padding: INTERCOM_DEFAULT_VERTICAL_PADDING,
    horizontal_padding: INTERCOM_DEFAULT_HORIZONTAL_PADDING,
  },
  listingFlowIntercomSetting: {
    app_id: process.env.INTERCOM_APP_ID,
    vertical_padding: INTERCOM_LISTING_FLOW_VERTICAL_PADDING,
    horizontal_padding: INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING,
  },
  listingFlowPhoneViewportIntercomSetting: {
    app_id: process.env.INTERCOM_APP_ID,
    vertical_padding: INTERCOM_LISTING_FLOW_VERTICAL_PADDING_PHONE,
    horizontal_padding: INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING,
  },
}

export default intercomSettings
