// this file often logs errors for developers since we don't have a ui for such things

/* eslint-disable no-console */
import produce from 'immer'
import { mutate } from 'swr'

import { isProduction } from 'utils/nodeEnv'

import * as T from 'types'
import { IServerError } from 'types'

import { trackMultipleEventsForListing } from './analytics'
import { api } from './api'
import { refreshCompletionPercentages } from './swr/completionPercentages'

export const createListing = async (
  data: T.ICreateListingData,
): Promise<[T.IListing | undefined, IServerError]> => {
  // create the listing
  const response = await api.createListing(data)

  if (!response.ok || !response.data) {
    return [
      undefined,
      {
        status: response.status?.toString(),
        message:
          response.status === 409
            ? 'You already have a listing for this address'
            : 'There was a problem creating your listing',
      },
    ]
  }

  // pre-fill melissa data and zillow data for the address
  const listing: T.IListing = response.data as T.IListing
  const populatedResponse = await api.getPropertyDetails(listing._id)

  // populating data request can sometimes time out talking to third party services
  // in case of populatedResponse timeout, return the successfully created listing
  return [populatedResponse.data || listing, {}]
}

// use the `update` function in `swr/useListing` when you need optimistic update/rollback
export const updateListing = async (
  listingId: string,
  update: { [key: string]: T.ValueOf<T.IListing> | null | undefined },
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.updateListing(listingId, update)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    refreshCompletionPercentages(listingId)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

export const updateListingField = async (
  listingId: string,
  field: T.IField,
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.updateListingField(listingId, field)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    refreshCompletionPercentages(listingId)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

export const updateListingOwner = async (
  listingId: string,
  owner: Partial<T.IOwner>,
  unshift?: boolean,
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.updateListingOwner(listingId, owner, unshift)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    refreshCompletionPercentages(listingId)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

export const deleteListingOwner = async (
  listingId: string,
  ownerId: string,
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.deleteListingOwner(listingId, ownerId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    refreshCompletionPercentages(listingId)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

export const updateListingPhotos = async (listingId: string, photos: T.IPhoto[]) => {
  try {
    const update: T.IListingPhotosUpdate = {
      update: { photos, category: 'Added Photo' },
    }

    const response: any = await api.updatePhotos(listingId, update)
    if (!response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${listingId}/photos`, response.data, false)

    refreshCompletionPercentages(listingId)

    return response.data
  } catch (error) {
    return error
  }
}

export const saveRoom = async (room: T.IRoom, listing: T.IListing) => {
  try {
    if (!listing) throw new Error('Listing required')

    const response = await api.updateRoom(listing._id, room)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    refreshCompletionPercentages(listing._id)

    // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the second
    // argument of mutate is a function, it passes in the cached current value stored for the key
    await mutate(
      `/listings/${listing._id}/ownerDetails`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      response.data as T.IListing,
      true, // @FIXME: we're refetching the listing here, but not sure if we need to
    )
  } catch (err) {
    console.error(err)
  }
}

export const updateListingDocuments = async (
  listing: T.IListing,
  documents: T.IListingMlsDocument[],
) => {
  if (!listing) return

  // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the second
  // argument of mutate is a function, it passes in the cached current value stored for the key
  await mutate(
    `/listings/${listing._id}/ownerDetails`,
    produce((draftListing: T.IListing) => {
      draftListing.mlsList[0].documents = documents
    }),
    true, // @FIXME: we're refetching the listing here, but not sure if we need to
  )

  refreshCompletionPercentages(listing._id)
}

export const resetAgreement = async (listingId: string) => {
  try {
    const response = await api.resetAgreement(listingId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the second
    // argument of mutate is a function, it passes in the cached current value stored for the key
    await mutate(
      `/listings/${listingId}/ownerDetails`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      response.data as T.IListing,
      true, // refetch the listing or UI won't update correctly
    )

    refreshCompletionPercentages(listingId)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

export const contactSeller = async (listingId: string, params: T.IContactSellerData) => {
  try {
    if (!listingId) throw new Error('missing listing')

    const response = await api.contactSeller(listingId, params)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

// WARNING: you almost always should use `useListing` instead of `getListing`. This is for rare
// cases like when you need listing data but are on a page without a listing [id] param in the path
export const getListing = async (listingId: string): Promise<T.IListing | undefined> => {
  try {
    const response = await api.getListing(listingId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

export const getListingByAddress = async (
  address: T.ICreateListingData,
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.getListingByAddress(address)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    if (!isProduction()) console.log({ error })
  }
}

// tracks events for segment, then sends an API request to mark events as tracked
// returns boolean as a result if analytics events array for listing was updated
export const trackCompletionsForListing = async (
  listing?: T.IListing,
  events?: T.ITriggeredEvent[],
  skipTracking: boolean = false,
) => {
  if (!listing || listing.hasOptimisticChanges || !events?.length) return false

  if (!skipTracking) trackMultipleEventsForListing(listing, events)

  const triggeredAnalyticsEventsResponse = await api.trackCompletionsForListing(listing._id, events)
  if (!triggeredAnalyticsEventsResponse.ok) return false

  // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the second
  // argument of mutate is a function, it passes in the cached current value stored for the key
  await mutate(
    `/listings/${listing._id}/analyticsEvents`,
    triggeredAnalyticsEventsResponse.data as T.ITriggeredEvent[],
    false,
  )
  return true
}

export const getAverageHomeValueByZipcode = async (
  zipcode: string,
): Promise<[T.IAverageHomeValueDataSuccess | undefined, Error | undefined]> => {
  try {
    const response = await api.getAverageHomeValueByZipcode(zipcode)

    if (!response.ok || !response.data || typeof response.data === 'string') {
      throw new Error(`bad response: ${response.problem}`)
    }

    return [response.data, undefined]
  } catch (error) {
    if (!isProduction()) console.error(error)

    const err = error instanceof Error ? error : new Error('Unknown error')
    return [undefined, err]
  }
}

export const emitSellerNotesViewedEvent = async (
  listingId: T.IListing['_id'],
  viewedNotes: T.IAgentSellerNote[],
): Promise<[boolean | undefined, undefined | Error]> => {
  try {
    const filterDefinedIds = (str: string | undefined): str is string => !!str
    const viewedNotesIds = viewedNotes.map(note => note._id).filter(filterDefinedIds)
    if (!viewedNotesIds.length) throw new Error('No proper notes.')

    const response = await api.emitSellerNotesViewedEvent(listingId, viewedNotesIds)

    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${listingId}/ownerDetails`)
    return [true, undefined]
  } catch (error) {
    if (!isProduction()) console.log({ error })

    return [undefined, new Error('Impossible to emit agentSellerNote viewed event')]
  }
}
