import * as T from './types'

const DashboardOutlined = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.95447C8.34012 2.95447 6.72133 3.47078 5.36799 4.43186C4.01464 5.39293 2.99375 6.75117 2.4468 8.31835C1.89984 9.88553 1.85391 11.584 2.31536 13.1785C2.74525 14.6639 3.59567 15.9905 4.76061 17H15.2394C16.4043 15.9905 17.2547 14.6639 17.6846 13.1785C18.1461 11.584 18.1002 9.88553 17.5532 8.31835C17.0062 6.75117 15.9854 5.39293 14.632 4.43186C13.2787 3.47078 11.6599 2.95447 10 2.95447ZM4.20998 2.8012C5.90167 1.59986 7.92515 0.954468 10 0.954468C12.0749 0.954468 14.0983 1.59986 15.79 2.8012C17.4817 4.00255 18.7578 5.70035 19.4415 7.65932C20.1252 9.6183 20.1826 11.7414 19.6058 13.7345C19.029 15.7276 17.8465 17.4919 16.2222 18.7829C16.0453 18.9235 15.826 19 15.6 19H4.4C4.17401 19 3.95469 18.9235 3.77778 18.7829C2.1535 17.4919 0.971014 15.7276 0.394198 13.7345C-0.182617 11.7414 -0.125199 9.6183 0.558495 7.65932C1.24219 5.70035 2.5183 4.00255 4.20998 2.8012ZM14.2071 6.79295C14.5976 7.18347 14.5976 7.81664 14.2071 8.20716L12.7076 9.70664C12.895 10.0983 13 10.5369 13 11.0001C13 12.6569 11.6568 14.0001 9.99999 14.0001C8.34314 14.0001 7 12.6569 7 11.0001C7 9.3432 8.34314 8.00005 9.99999 8.00005C10.4631 8.00005 10.9018 8.10501 11.2934 8.29243L12.7929 6.79295C13.1834 6.40242 13.8166 6.40242 14.2071 6.79295ZM9.99999 10.0001C9.44771 10.0001 9 10.4478 9 11.0001C9 11.5523 9.44771 12.0001 9.99999 12.0001C10.5523 12.0001 11 11.5523 11 11.0001C11 10.4478 10.5523 10.0001 9.99999 10.0001Z"
      fill={props.fill}
    />
  </svg>
)

export default DashboardOutlined
