import { Placement } from '@popperjs/core'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import styled, { ThemeContext } from 'styled-components'
import {
  INTERCOM_DEFAULT_HORIZONTAL_PADDING,
  INTERCOM_LISTING_FLOW_VERTICAL_PADDING,
} from 'themes/intercom'

import CloseButton from 'components/CloseButton'
import RoundedButton from 'components/RoundedButton'
import Spacer from 'components/Spacer'

import { ProductTourContext } from 'services/contexts'
import { useWindowSize } from 'services/hooks'

import { interleaveArrayWithElement } from 'utils/arrayHelpers'

export const FIRST_STEP = 0
export const SECOND_STEP = 1
export const THIRD_STEP = 2
export const FOURTH_STEP = 3
export const LAST_STEP = 4
const LISTING_FLOW_PATH = 'listing/[id]/create'

const titles = [
  'Your estimated savings',
  'Your overall progress',
  'Step by step listing process',
  'Your listing navigation',
  'Chat with us!',
]

const contents = [
  // eslint-disable-next-line quotes
  [
    "This is how much you can expect to save based on your home's value and the commission percentage you offer the buyer's agent. The low end represents how much you'll save if you offer the buyer's agent 3% commission and the high end represents the amount you'll save if the buyer does not have an agent.",
  ],
  [
    "This shows where you are in the overall listing process; how much you've completed and how much you still have to do. You do not need to complete your listing in one sitting. It will save automatically as you go and you can always go back and change things.",
  ],
  [
    "Four easy steps to getting your house listed and on the market. We've tried to make the process as easy and streamlined as possible by breaking it down into small, achievable steps. Enter your home details, choose your list price and sales terms, add photos, videos, and more, and finalize all your listing paperwork.",
  ],
  [
    'Continue to the next page and go back whenever you need to. Saved status will be displayed in real time as entries are made throughout the listing process.',
  ],
  [
    "Questions? We're here to help and are available via chat 7 days a week: 1pm-8pm (Eastern) on Mondays, 11am-8pm (Eastern) Tuesday through Friday, and 11am-3pm (Eastern) on Saturday.",
    "Have questions after hours? Check out our FAQs or leave a message in the chat window and we'll get back to you as soon as we open again.",
  ],
]

const desktopPointerDirections: ('up' | 'down' | 'left')[] = ['up', 'up', 'left', 'down', 'down']

const mobilePointerDirections: ('up' | 'down' | 'left')[] = ['up', 'up', 'up', 'down', 'down']

const desktopPlacements: Placement[] = ['bottom', 'bottom-end', 'left-end', 'top', 'top-end']

const mobilePlacements: Placement[] = ['bottom', 'bottom', 'bottom', 'top', 'top']

const desktopOffsets: [number, number][] = [
  [250, 0], // half 630 max width - 50 caret offset - half 30 caret width = 250
  [0, 0],
  [196.75, 0], // (570 height content - half 353 height popover) / 2 to center vertically = 196.75
  [0, 0],
  [0, 0],
]

const mobileOffsets: [number, number][] = [
  [0, -8],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
]

const desktopPointerTransformRotation = ['134deg', '134deg', '44deg', '314deg', '314deg']

const mobilePointerTransformRotation = ['134deg', '134deg', '134deg', '314deg', '314deg']

interface IContainerProps {
  step: number
  debug?: boolean
}
interface IStepProp {
  step: number
}
const Container = styled.div<IContainerProps>`
  height: auto;
  padding: 30px 20px;
  z-index: ${props => props.theme.zIndices.level11};
  ${props => props.debug && 'border: 1px solid green;'}
  ${props => props.step === LAST_STEP && 'padding-right: 5px;'}

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 30px 70px;
    ${props => props.step === THIRD_STEP && 'padding-left: 30px; padding-right: 0;'}
    ${props => props.step === LAST_STEP && 'padding-right: 5px;'}
  }
`

const BackingContainer = styled.div<IStepProp>`
  position: relative;
  display: flex;
  height: auto;
  padding: 24px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 24px;
  max-width: 630px;

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    ${props => props.step === THIRD_STEP && 'max-width: 600px;'}
  }
`

/* Popover */
interface IPointerDirectionProp {
  direction: 'up' | 'down' | 'left'
}
const PopoverPointer = styled.div<IStepProp & IPointerDirectionProp>`
  position: absolute;

  ${props =>
    ['up', 'down'].includes(props.direction)
    && `
    top: -12px;
    left: calc(50% - 18px);
    ${props.step === LAST_STEP ? 'left: unset; right: 30px;' : ''}

    @media (min-width: ${props.theme.metrics.tablet}px) {
      ${props.step === FIRST_STEP ? 'left: 50px;' : ''}
      ${props.step === SECOND_STEP ? 'left: unset; right: 50px;' : ''}
    }
  `}

  ${props =>
    props.direction === 'down'
    && `
    top: unset;
    bottom: -12px;
  `}

  ${props =>
    props.direction === 'left'
    && `
    left: -12px;
    top: calc(50% - 18px);
  `}
`

/**
 * Adapted from:
 * https://stackoverflow.com/questions/14446677/how-to-make-3-corner-rounded-triangle-in-css
 */
const PopoverPointerShape = styled.div<IStepProp>`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 2px;
  transform: rotate(${props => mobilePointerTransformRotation[props.step]}) scale(1, 0.866);

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    transform: rotate(${props => desktopPointerTransformRotation[props.step]}) scale(1, 0.866);
  }
`

const PopoverFlexContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${props => props.theme.colors.white};
`

const CloseRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Title = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: ${props => props.theme.colors.grey27};
`

const Content = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.grey27};
`

const BottomRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: ${props => props.theme.colors.grey23};
`

const ButtonContainer = styled.div`
  display: flex;
`

interface IProps {
  visibleStep: number
  referenceElement: HTMLDivElement | null
}
const ProductTourPopover: FunctionComponent<IProps> = ({
  visibleStep,
  referenceElement = null,
}) => {
  const router = useRouter()
  const theme = useContext(ThemeContext)
  const { width } = useWindowSize()
  const isDesktop = width && width >= theme.metrics.desktop
  const isPhone = width && width < theme.metrics.tablet

  const inListingFlow = router.pathname.includes(LISTING_FLOW_PATH)
  const isLargeDesktop = width && width >= theme.metrics.listingFlowPageMaxWidth
  let intercomRightValue = 0

  // keep intercom inside listingFlow page when viewport is greater than 1920px
  if (inListingFlow && isLargeDesktop) {
    intercomRightValue
      = (width - theme.metrics.listingFlowPageMaxWidth) / 2 + INTERCOM_DEFAULT_HORIZONTAL_PADDING
  }

  const ref = useRef<HTMLDivElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: isDesktop ? desktopOffsets[visibleStep] : mobileOffsets[visibleStep],
        },
      },
    ],
    placement: isDesktop ? desktopPlacements[visibleStep] : mobilePlacements[visibleStep],
  })

  const {
    productTourActive,
    productTourStep,
    advanceProductTour,
    rewindProductTour,
    endProductTour,
  } = useContext(ProductTourContext)

  if (!referenceElement) return null

  if (!productTourActive) return null

  if (visibleStep !== productTourStep) return null

  const title = titles[visibleStep]

  const content = interleaveArrayWithElement(
    contents[visibleStep].map(paragraph => <Content>{paragraph}</Content>),
    <Spacer />,
  )

  const lastStepCompleteText = isPhone ? 'Start' : 'Get Started'
  const advanceButtonText = productTourStep === LAST_STEP ? lastStepCompleteText : 'Next'

  const settingsText = {
    app_id: process.env.INTERCOM_APP_ID,
    horizontal_padding: intercomRightValue,
    vertical_padding: INTERCOM_LISTING_FLOW_VERTICAL_PADDING,
  }

  window.Intercom('update', settingsText)

  return (
    <div ref={ref}>
      <Container
        ref={setPopperElement}
        step={visibleStep}
        style={styles.popper}
        {...attributes.popper}
      >
        <BackingContainer step={visibleStep}>
          <PopoverPointer
            step={productTourStep}
            direction={
              isDesktop
                ? desktopPointerDirections[productTourStep]
                : mobilePointerDirections[productTourStep]
            }
          >
            <PopoverPointerShape step={productTourStep} />
          </PopoverPointer>
          <PopoverFlexContainer>
            <CloseRow>
              <CloseButton
                closeButtonColor={theme.colors.white}
                closeText
                closeTextUpperCase
                closeTextFontWeight={500}
                onClick={endProductTour}
              />
            </CloseRow>
            <Title>{title}</Title>
            <Spacer size={16} />
            {content}
            <Spacer size={24} />
            <BottomRow>
              <StepContainer>{`${visibleStep + 1} of 5`}</StepContainer>
              <Spacer />
              <ButtonContainer>
                {productTourStep > 0 && (
                  <RoundedButton
                    text="Back"
                    height={36}
                    padding="0 32px"
                    onClick={rewindProductTour}
                    background={theme.colors.white}
                    borderColor={theme.colors.blue4}
                    borderWidth={1}
                  />
                )}
                <Spacer size={6} />
                <RoundedButton
                  text={advanceButtonText}
                  padding="0 32px"
                  height={36}
                  background={theme.colors.blue4}
                  color={theme.colors.white}
                  onClick={productTourStep === LAST_STEP ? endProductTour : advanceProductTour}
                />
              </ButtonContainer>
            </BottomRow>
          </PopoverFlexContainer>
        </BackingContainer>
      </Container>
    </div>
  )
}

export default ProductTourPopover
