export type ZIndices =
  | 'level0'
  | 'level1'
  | 'level2'
  | 'level3'
  | 'level4'
  | 'level5'
  | 'level6'
  | 'level7'
  | 'level8'
  | 'level9'
  | 'level10'
  | 'level11'

export type IZIndices = {
  [key in ZIndices]: number
}

const zIndices: IZIndices = {
  level0: 0,
  level1: 1,
  level2: 2,
  level3: 3,
  level4: 1000,
  level5: 1001,
  level6: 1002,
  level7: 10000,
  level8: 10001,
  level9: 10002,
  level10: 100000,
  level11: 100001,
}

export default zIndices
