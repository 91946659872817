export const saveToLocalStorage = (key: string, value: string, listingId?: string) => {
  if (typeof window === 'undefined') return

  let data: { [key: string]: string } = {}
  const json = window.localStorage.getItem(key)

  if (json) {
    const parsed: { [key: string]: string } = JSON.parse(json)
    data = parsed
  }

  if (listingId) {
    data[listingId] = value
    localStorage.setItem(key, JSON.stringify(data))
  } else {
    localStorage.setItem(key, value)
  }
}

export const getFromLocalStorage = (localStorageKey: string) => {
  if (typeof window === 'undefined') return

  const json = window.localStorage.getItem(localStorageKey)

  if (json) {
    const parsedData: { [key: string]: string } = JSON.parse(json)
    return parsedData
  }
}

export const deleteFromLocalStorage = (localStorageKey: string) => {
  if (typeof window === 'undefined') return

  window.localStorage.removeItem(localStorageKey)
}

export const vwoUserStorageService = {
  getSettings: () => {
    const settingsExpiry: any = localStorage.getItem('vwo-settings-expiry')

    // Check if there is any expiry set
    if (settingsExpiry) {
      const currentTimeStamp = Number(new Date())

      // calculate the time difference
      const timeDifference = currentTimeStamp - settingsExpiry

      // if time eleapsed is greater than 1 minute
      if (timeDifference > 60 * 1000) {
        // 1 min = 60 * 1000 = 60000 ms
        // remove the stale settings and return null
        localStorage.removeItem('vwo-settings')
        return ''
      }

      return localStorage.getItem('vwo-settings') || ''
    }

    return localStorage.getItem('vwo-settings') || ''
  },
  // `any` is appropriate here as any kind of data can be stored in localstorage
  // and the library may change the data type in future versions w/o warning
  setSettings: (settings: any) => {
    localStorage.setItem('vwo-settings-expiry', (+new Date()).toString()) // store current timestampp
    localStorage.setItem('vwo-settings', settings)
  },
  // stubbed out for now - not used but required to be defined
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get: (userId: string, campaignKey: string): Record<string, any> => ({ fake: 'data' }),
  // stubbed out for now - not used but required to be defined
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  set: (userStorageData: Record<string, any>): void => {},
}
