import { ReactNode, createContext, useReducer } from 'react'

import { IVariations } from 'types'

enum actions {
  SET_VWO_USER_ID = 'SET_VWO_USER_ID',
  SET_VWO_VARIATIONS = 'SET_VWO_VARIATIONS',
}
type Action =
  | { type: actions.SET_VWO_USER_ID; vwoUserId: string }
  | { type: actions.SET_VWO_VARIATIONS; variations: IVariations }

type State = {
  vwoUserId: string
  variations: IVariations
}
const initialState = { vwoUserId: '', variations: {} }

type ProviderProps = { children: ReactNode }

function abVariationsReducer(state: State, action: Action) {
  switch (action.type) {
    case actions.SET_VWO_USER_ID:
      return { ...state, vwoUserId: action.vwoUserId }
    case actions.SET_VWO_VARIATIONS:
      return { ...state, variations: action.variations }
    default:
      throw new Error('Unhandled action type')
  }
}

interface IContext {
  vwoUserId: string
  variations: IVariations
  setVwoUserId: (vwoUserId: string) => void
  setVariations: (variations: IVariations) => void
}

let value: IContext = {
  ...initialState,
  setVwoUserId: () => {},
  setVariations: () => {},
}

const ABVariationsContext = createContext<IContext>(value)

const ABVariationsProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(abVariationsReducer, initialState)

  value = {
    vwoUserId: state.vwoUserId,
    variations: state.variations,
    setVwoUserId: (vwoUserId: string) => dispatch({ type: actions.SET_VWO_USER_ID, vwoUserId }),
    setVariations: (variations: IVariations) =>
      dispatch({ type: actions.SET_VWO_VARIATIONS, variations }),
  }
  return <ABVariationsContext.Provider value={value}>{children}</ABVariationsContext.Provider>
}

export { ABVariationsContext, ABVariationsProvider }
