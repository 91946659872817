import { useRouter } from 'next/router'
import { FC, ReactElement, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { ABVariationsContext } from 'services/contexts'
import { useUser } from 'services/swr/useUser'
import { activateVwoTestClientside, getRouteNameToExperimentKeys, getVwoData } from 'services/vwo'

const FullPageContainer = styled.div`
  background: ${props => props.theme.colors.white};
  height: calc(100vh - ${props => props.theme.metrics.headerHeightPhone}px);
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: calc(100vh - ${props => props.theme.metrics.headerHeight}px);
  }
`

interface IProps {
  children: ReactElement<any, any> | null
}

const ABVariation: FC<IProps> = ({ children }) => {
  const router = useRouter()
  const { user } = useUser()

  // A/B Testing Initialization
  // VWO - https://developers.vwo.com/docs/nodejs-sdk-reference
  const { setVwoUserId, setVariations } = useContext(ABVariationsContext)
  const [variationSet, setVariationSet] = useState(false)

  // NB: currently, we've set this up so that all clientside AB tests require a user
  // vwo cookie-user sync logic will need to be modified to support non-users
  useEffect(() => {
    if (!router.isReady) return

    const initializeABTests = async () => {
      setVariationSet(false)

      const routeNameToExperimentKeys = getRouteNameToExperimentKeys()
      const experimentKey = routeNameToExperimentKeys[router.route]

      const { vwoUserId, variations } = experimentKey
        ? await activateVwoTestClientside(experimentKey, user)
        : getVwoData()
      if (vwoUserId) setVwoUserId(vwoUserId)

      if (variations) setVariations(variations)

      setVariationSet(true)
    }

    initializeABTests()
  }, [router.isReady, router.asPath])

  if (!variationSet) return <FullPageContainer /> // silent loader, as this will complete in <200ms

  return children
}

export default ABVariation
