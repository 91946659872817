import { ParsedUrlQuery } from 'querystring'
import { ReactNode, createContext, useReducer } from 'react'

// dependency of next/router
import * as T from './types'

enum actions {
  SET_PARAMS = 'SET_PARAMS',
}
type Action = {
  type: actions.SET_PARAMS
  params: ParsedUrlQuery
}

type ProviderProps = { children: ReactNode }

type State = { [key: string]: string }
const initialState = {}

const queryParamsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_PARAMS':
      if (action.params) {
        const allowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid', 'msclkid']
        const allowedQueryParams: T.IStateObj = {}
        Object.keys(action.params).forEach((key: string) => {
          const value = action.params[key]

          if (allowedKeys.includes(key) && !!value) {
            allowedQueryParams[key] = value
          }
        })
        return allowedQueryParams
      }

      return state
    default:
      return state
  }
}

interface Context {
  params: ParsedUrlQuery
  setParams: (payload: ParsedUrlQuery) => void
}

let value: Context = {
  params: {},
  setParams: () => {},
}

const QueryParamsContext = createContext<Context>(value)

const QueryParamsProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(queryParamsReducer, initialState)
  value = {
    params: state,
    setParams: (params: ParsedUrlQuery) => dispatch({ type: actions.SET_PARAMS, params }),
  }
  return <QueryParamsContext.Provider value={value}>{children}</QueryParamsContext.Provider>
}

export { QueryParamsContext, QueryParamsProvider }
