import { FC } from 'react'

import * as T from 'types'

/* eslint-disable import/prefer-default-export */
interface IKeyValue {
  [key: string]: string
}

export interface ISVGProps {
  fill?: string
  stroke?: string
  width?: string | number
  height?: string | number
  viewBox?: string
  style?: IKeyValue
  d?: string
  maskId?: string
  masktype?: string
  transformString?: string
  onClick?: () => void
  [key: string]: T.ValueOf<ISVGProps>
}

export interface ISvgFC extends FC<ISVGProps> {}

export enum Rotations {
  none = 'none',
  right = 'right',
  left = 'left',
  flip = 'flip',
}
