export type ShadowColors = 'neutral' | 'blue'

export type ShadowLevelNeutral = 'level1' | 'level2' | 'level3' | 'level4' | 'level5' | 'level6'

export type ShadowLevelBlue = 'level1'

export type IShadows = {
  neutral: { [levelKey in ShadowLevelNeutral]: string }
  blue: { [levelKey in ShadowLevelBlue]: string }
}

const shadows: IShadows = {
  neutral: {
    level1: '0px 4px 8px rgba(112, 112, 112, 0.16)',
    level2: '0px 8px 16px rgba(112, 112, 112, 0.16)',
    level3: '0px 12px 24px rgba(112, 112, 112, 0.16)',
    level4: '0px 12px 30px rgba(112, 112, 112, 0.2)',
    level5: '0px 16px 36px rgba(112, 112, 112, 0.24)',
    level6: '0px 24px 48px rgba(112, 112, 112, 0.32)',
  },
  blue: {
    level1: '0px 12px 24px rgba(18, 153, 236, 0.16)',
  },
}

export default shadows
