import useSWR, { mutate } from 'swr'

import { fetchWithToken } from 'services/fetchers'

import * as T from 'types'

import getErrorMessage from './utils/getErrorMessage'
import isServerError from './utils/isServerError'

const getSwrKey = (listingId: string) => `/completionPercentages/${listingId}`

const refreshCompletionPercentages = (listingId: string) => mutate(getSwrKey(listingId))

const useCompletionPercentages = (listingId?: string) => {
  const response = useSWR<T.ICompletionPercentages | T.IServerError>(
    listingId ? getSwrKey(listingId) : null,
    fetchWithToken,
  )

  let completionPercentages
  if (!isServerError(response.data)) completionPercentages = response.data

  const error = getErrorMessage(response, 'completion percentages')

  return { ...response, completionPercentages, error }
}

export { refreshCompletionPercentages, useCompletionPercentages }
