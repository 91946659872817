import { ReactNode, createContext, useReducer } from 'react'

enum actions {
  SET_SAVING = 'SET_SAVING',
}
type Action = { type: actions.SET_SAVING; saving: boolean }

type State = {
  saving: boolean
}
const initialState = { saving: false }

type ProviderProps = { children: ReactNode }

function savingReducer(state: State, action: Action) {
  switch (action.type) {
    case actions.SET_SAVING:
      return { ...state, saving: action.saving }
    default:
      throw new Error('Unhandled action type')
  }
}

interface IContext {
  saving: boolean
  setSaving: (saving: boolean) => void
}

let value: IContext = {
  ...initialState,
  setSaving: () => {},
}

const SavingContext = createContext<IContext>(value)

const SavingProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(savingReducer, initialState)

  value = {
    saving: state.saving,
    setSaving: (saving: boolean) => dispatch({ type: actions.SET_SAVING, saving }),
  }
  return <SavingContext.Provider value={value}>{children}</SavingContext.Provider>
}

export { SavingContext, SavingProvider }
