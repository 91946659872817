import * as E from 'types/enums'

export const publishedListingStatuses = [
  E.ListingStatus.sold,
  E.ListingStatus.closedNotPaid,
  E.ListingStatus.cancelled,
  E.ListingStatus.cancellationSigned,
  E.ListingStatus.remove,
  E.ListingStatus.expired,
  E.ListingStatus.leased,
  E.ListingStatus.inContract,
  E.ListingStatus.offerAcceptedNYIC,
  E.ListingStatus.published,
  E.ListingStatus.inProgress,
  E.ListingStatus.brokerReview,
  E.ListingStatus.tempHold,
  E.ListingStatus.needsRevision,
  E.ListingStatus.newEscrow,
  E.ListingStatus.escrowClosed,
]

export const amendmentListingStatuses = [E.ListingStatus.amendmentNeeded]

export const amendmentInProgressStatuses = [
  E.ListingStatus.amendmentSent,
  E.ListingStatus.listingDetailsUpdated,
]

export const hiddenContactSellerFormStatuses = [
  E.ListingStatus.cancelled,
  E.ListingStatus.closedNotPaid,
  E.ListingStatus.expired,
  E.ListingStatus.sold,
  E.ListingStatus.leased,
]
