import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const LabelText = styled.div<ITypographyProps>`
  font-weight: ${props => props.theme.fonts.weights.medium};
  font-size: ${props => props.theme.fonts.sizes[10]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 14px;
  flex: none;
  flex-grow: 0;
`

const Label: FunctionComponent = ({ children, ...props }) => (
  <LabelText {...props}>{children}</LabelText>
)

export default Label
