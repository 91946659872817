import { ReactNode, createContext, useReducer } from 'react'

enum actions {
  SET_CONNECTED = 'SET_CONNECTED',
  SET_DISCONNECTED = 'SET_DISCONNECTED',
  SET_SEARCHING = 'SET_SEARCHING',
}
type Action =
  | { type: actions.SET_CONNECTED }
  | { type: actions.SET_DISCONNECTED }
  | { type: actions.SET_SEARCHING }

type State = { missingInternet: boolean; retrying: boolean }
const initialState = { missingInternet: false, retrying: false }

type ProviderProps = { children: ReactNode }

function missingInternetReducer(state: State, action: Action) {
  switch (action.type) {
    case actions.SET_CONNECTED:
      return { missingInternet: false, retrying: false }
    case actions.SET_DISCONNECTED:
      return { missingInternet: true, retrying: false }
    case actions.SET_SEARCHING:
      return { missingInternet: true, retrying: true }
    default:
      throw new Error('Unhandled action type')
  }
}

interface IContext {
  missingInternet: boolean
  retrying: boolean
  setConnected: () => void
  setDisconnected: () => void
  setSearching: () => void
}

let value: IContext = {
  missingInternet: false,
  retrying: false,
  setConnected: () => {},
  setDisconnected: () => {},
  setSearching: () => {},
}

const MissingInternetContext = createContext<IContext>(value)

const MissingInternetProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(missingInternetReducer, initialState)
  value = {
    missingInternet: state.missingInternet,
    retrying: state.retrying,
    setConnected: () => dispatch({ type: actions.SET_CONNECTED }),
    setDisconnected: () => dispatch({ type: actions.SET_DISCONNECTED }),
    setSearching: () => dispatch({ type: actions.SET_SEARCHING }),
  }

  return <MissingInternetContext.Provider value={value}>{children}</MissingInternetContext.Provider>
}

export { MissingInternetContext, MissingInternetProvider }
