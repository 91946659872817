import Link from 'next/link'
import { FunctionComponent, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import Anchor from 'components/GlobalStyles/Anchor'
import HorizontalRule from 'components/GlobalStyles/Hr'
import ConversationBubblesIcon from 'components/SVG/ChatBubbleOutlined'
import EmailIcon from 'components/SVG/EmailOutlined'
import InfoIcon from 'components/SVG/InfoOutlined'
import PhoneIcon from 'components/SVG/PhoneOutlined'
import Spacer from 'components/Spacer'

import { prepopulateIntercomMessage } from 'services/intercom'

import DropDown from './DropDown'
import { ContentContainer, ItemRow, LinkText, TitleContainer, TitleText } from './Shared'

interface IProps {
  disabled?: boolean
  inDropDown?: boolean
  transparent?: boolean
  toggleModal?: () => void
}

const Help: FunctionComponent<IProps> = ({ transparent, disabled, inDropDown, toggleModal }) => {
  const theme = useContext(ThemeContext)

  const openIntercom = () => prepopulateIntercomMessage('Hello')

  const renderDivider = () => (
    <>
      <Spacer size={10} />
      <HorizontalRule />
      <Spacer size={10} />
    </>
  )

  const renderHelp = () => (
    <ContentContainer inDropDown={inDropDown}>
      {!inDropDown && <Spacer size={10} />}
      <Anchor
        href="mailto:help@homelister.com"
        target="_blank"
      >
        <ItemRow>
          <EmailIcon
            width={20}
            height={16}
            fill={theme.colors.yellow4}
          />
          <TitleContainer>
            <TitleText>Email Us</TitleText>
            <LinkText>help@homelister.com</LinkText>
          </TitleContainer>
        </ItemRow>
      </Anchor>
      {renderDivider()}
      <Anchor href="tel:855-400-8566">
        <ItemRow>
          <PhoneIcon
            width={18}
            height={18}
            fill={theme.colors.yellow4}
          />
          <TitleContainer>
            <TitleText>Call Us</TitleText>
            <LinkText>855-400-8566</LinkText>
          </TitleContainer>
        </ItemRow>
      </Anchor>
      {renderDivider()}
      <ItemRow onClick={openIntercom}>
        <ConversationBubblesIcon
          width={22}
          height={21}
          fill={theme.colors.yellow4}
        />
        <TitleContainer>
          <TitleText>Chat with Us</TitleText>
        </TitleContainer>
      </ItemRow>
      {renderDivider()}
      <Link
        href="/faq"
        prefetch={false}
        passHref
      >
        <Anchor>
          <ItemRow onClick={toggleModal}>
            <InfoIcon
              width={18}
              height={18}
              fill={theme.colors.yellow4}
            />
            <TitleContainer>
              <TitleText>FAQ</TitleText>
            </TitleContainer>
          </ItemRow>
        </Anchor>
      </Link>
    </ContentContainer>
  )

  if (inDropDown) {
    return (
      <DropDown
        text="HELP"
        disabled={disabled}
        marginCorrection={-42}
        textColor={transparent ? theme.colors.white : theme.colors.neutral900}
      >
        {renderHelp()}
      </DropDown>
    )
  }

  return renderHelp()
}

export default Help
