import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import HomeListerLogo from './HomeListerSVGLogo'
import * as T from './types'

const LogoContainer = styled.div<T.IDisabledProp & T.IWaitingProp>`
  display: block;
  width: 148px;

  cursor: pointer;
  ${props => props.waiting && 'cursor: wait;'}
  ${props => props.disabled && 'cursor: default;'}

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    width: 175px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 196px;
  }
`

interface IProps {
  disabled?: boolean
  waiting?: boolean
  transparent?: boolean
}
const HomeListerLogoContainer: FunctionComponent<IProps> = ({ waiting, disabled, transparent }) => {
  const theme = useContext(ThemeContext)
  return (
    <LogoContainer
      waiting={waiting}
      disabled={disabled}
    >
      <HomeListerLogo fill={transparent ? theme.colors.white : theme.colors.teal500} />
    </LogoContainer>
  )
}

export default HomeListerLogoContainer
