import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { GuidedTourContext } from 'services/contexts'
import { useGuidedTourModeState } from 'services/hooks'
import { useCompletionPercentages } from 'services/swr/completionPercentages'
import { useListing } from 'services/swr/useListing'

import { getSubsections } from 'utils/listingHelpers'

import * as T from 'types'

const useMissingFieldGuide = () => {
  const { listing } = useListing()
  const { completionPercentages } = useCompletionPercentages(listing?._id)
  const router = useRouter()
  const route = router.asPath.split('create/').pop() ?? ''

  const { setMissingSubsections, setMissingFieldsCount } = useContext(GuidedTourContext)
  const { guidedTour } = useGuidedTourModeState(listing)

  const getCurrentSubsections = (percentages: T.ICompletionPercentages) => {
    // required subsections
    const { subsections } = getSubsections(percentages)
    // update missing substeps and current completion percentage to the GuidedTourContext
    let currentSubsection: T.ISubsection | undefined
    const sectionsWithoutAgreement: T.ISubsection[] = []
    subsections.forEach((subsection: T.ISubsection) => {
      if (subsection.route === route) currentSubsection = subsection

      if (subsection.route !== 'agreement') sectionsWithoutAgreement.push(subsection)
    })

    return { currentSubsection, sectionsWithoutAgreement }
  }

  const calculateCompletionProgress = (percentages: T.ICompletionPercentages) => {
    const { currentSubsection, sectionsWithoutAgreement } = getCurrentSubsections(percentages)

    let currentCount = 0

    if (currentSubsection) {
      const percent = currentSubsection.percent ?? 0
      setMissingSubsections(sectionsWithoutAgreement, route, percent)
      if (
        currentSubsection.requiredLength !== undefined
        && currentSubsection.filledLength !== undefined
      ) {
        currentCount = currentSubsection.requiredLength - currentSubsection.filledLength
      }
    }

    // remove agreement substep from the total count
    const totalCount = percentages.totalMissingFields - 1
    setMissingFieldsCount(currentCount, totalCount)
  }

  useEffect(() => {
    if (!listing || !guidedTour || !completionPercentages) return

    calculateCompletionProgress(completionPercentages)
  }, [listing, completionPercentages, router.asPath])

  return { calculateCompletionProgress, getCurrentSubsections }
}

export default useMissingFieldGuide
