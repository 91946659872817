import useSWR from 'swr'

import { fetchWithToken } from 'services/fetchers'

import * as T from 'types'

import getErrorMessage from '../utils/getErrorMessage'
import { isServerError } from '../utils/isServerError'

// @FIXME: this would be better to roll into `useUserListings` using an options object with an
// optional `sparse` property, but I'm coudln't figure out how to conditionally change the return type
// from ISparseListing to IListing in TS.

export const useUserListingsFullyPopulated = () => {
  const key = '/listings/user'
  const response = useSWR<T.IListing[] | T.IServerError>(key, fetchWithToken)

  let listings: T.IListing[] | undefined

  if (response.data && !isServerError(response) && Array.isArray(response.data)) {
    listings = response.data
  }

  const error = getErrorMessage(response, 'listings', true)

  return {
    ...response,
    userListings: listings,
    listings,
    error,
  }
}
