import Cookies from 'js-cookie'

interface Iheaders {
  [key: string]: string | string[]
}
// saves Google Click Id and Microsoft Click Id in cookies with very long expiry;
// if there are new gclid or msclkid from url params/cookies, save previous values in cookies
// set current and previous click ids as special headers to be saved on the backend
export const initializeCookiesAndUpdHeaders = (headers: Iheaders, qs: string) => {
  const qsParams = new URLSearchParams(qs)
  const gclidFromQs = qsParams.get('gclid')
  const msclkidFromQs = qsParams.get('msclkid')

  let overrideMsclkid = false
  let overrideGclid = false

  if (gclidFromQs && !msclkidFromQs) {
    overrideMsclkid = true
  } else {
    overrideGclid = true
  }

  const currentGclid = Cookies.get('longExpiryGclid') || ''
  const prevGclids = Cookies.get('prevGclids') || '[]'
  const prevMsclkids = Cookies.get('prevMsclkids') || '[]'
  const extendedHeaders: Iheaders = { ...headers, prevGclids, prevMsclkids }

  const gclAw = Cookies.get('_gcl_aw') || ''
  const gclid = gclAw.split('.').length > 1 ? gclAw.split('.').pop() : gclidFromQs

  if (gclid && gclid !== '' && gclid !== currentGclid) {
    if (currentGclid && currentGclid !== '') {
      const currentPrevGclids = JSON.parse(decodeURIComponent(Cookies.get('prevGclids') || '[]'))
      const newPrevGclids = JSON.stringify([...currentPrevGclids, currentGclid])
      Cookies.set('prevGclids', newPrevGclids, { expires: 10 * 365 })
      extendedHeaders.prevGclids = newPrevGclids
    }

    Cookies.set('longExpiryGclid', gclid, { expires: 10 * 365 })
    extendedHeaders.gclid = gclid
    if (overrideMsclkid) {
      Cookies.set('longExpiryMsclkid', '', { expires: 10 * 365 })
      extendedHeaders.msclkid = ''
    }
  } else if (currentGclid) {
    extendedHeaders.gclid = currentGclid
  }

  const currentMsclkid = Cookies.get('longExpiryMsclkid') || ''
  const uetMsclkid = Cookies.get('_uetmsclkid') || ''

  const msclkid
    = uetMsclkid.split('_uet').length > 1 ? uetMsclkid.split('_uet').pop() : msclkidFromQs

  if (msclkid && msclkid !== '' && msclkid !== currentMsclkid) {
    if (currentMsclkid && currentMsclkid !== '') {
      const currentPrevMsclkids = JSON.parse(
        decodeURIComponent(Cookies.get('prevMsclkids') || '[]'),
      )

      const newPrevMsclkids = JSON.stringify([...currentPrevMsclkids, currentGclid])
      Cookies.set('prevMsclkids', newPrevMsclkids, { expires: 10 * 365 })
      extendedHeaders.prevMsclkids = newPrevMsclkids
    }

    Cookies.set('longExpiryMsclkid', msclkid, { expires: 10 * 365 })
    extendedHeaders.msclkid = msclkid
    if (overrideGclid) {
      Cookies.set('longExpiryGclid', '', { expires: 10 * 365 })
      extendedHeaders.gclid = ''
    }
  } else if (currentMsclkid) {
    extendedHeaders.msclkid = currentMsclkid
  }

  return extendedHeaders
}
