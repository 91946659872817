import * as T from './types'

const ProfileOutlined = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.94113C6.34315 2.94113 5 4.28428 5 5.94113C5 7.59799 6.34315 8.94113 8 8.94113C9.65685 8.94113 11 7.59799 11 5.94113C11 4.28428 9.65685 2.94113 8 2.94113ZM3 5.94113C3 3.17971 5.23858 0.941132 8 0.941132C10.7614 0.941132 13 3.17971 13 5.94113C13 7.34272 12.4233 8.60961 11.4943 9.51747C12.5298 9.70811 13.5011 10.1531 14.284 10.824C15.3591 11.7456 16 13.0315 16 14.4117V16.4117C16 16.964 15.5523 17.4117 15 17.4117C14.4477 17.4117 14 16.964 14 16.4117V14.4117C14 13.6702 13.6576 12.9213 12.9824 12.3426C12.3024 11.7597 11.3513 11.4117 10.3333 11.4117H5.66667C4.64867 11.4117 3.69761 11.7597 3.01763 12.3426C2.34241 12.9213 2 13.6702 2 14.4117V16.4117C2 16.964 1.55228 17.4117 1 17.4117C0.447715 17.4117 0 16.964 0 16.4117V14.4117C0 13.0315 0.640919 11.7456 1.71604 10.824C2.49885 10.1531 3.47023 9.70811 4.50574 9.51747C3.57669 8.60961 3 7.34272 3 5.94113Z"
      fill={props.fill}
    />
  </svg>
)

export default ProfileOutlined
