import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const Title = styled.div<ITypographyProps>`
  margin: 0;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  font-size: ${props => props.theme.fonts.sizes[18]}px;
  text-align: ${props => props.textAlign || 'initial'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 25px;
  flex: none;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.theme.fonts.sizes[20]}px;
    line-height: 27px;
  }
`

const Title1: FunctionComponent<ITypographyProps> = ({ children, ...props }) => (
  <Title
    className="title1"
    {...props}
  >
    {children}
  </Title>
)

export default Title1
