import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const Body1Text = styled.p<ITypographyProps>`
  padding-top: 2px; /* font correction */
  margin: 0;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  font-size: ${props => props.theme.fonts.sizes[16]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 22px;
`

const Body1: FunctionComponent<ITypographyProps> = ({ children, tag, ...props }) => (
  <Body1Text
    {...props}
    as={tag || 'p'}
  >
    {children}
  </Body1Text>
)

export default Body1
