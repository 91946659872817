import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const Body3Text = styled.p<ITypographyProps>`
  margin: 0;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  font-size: ${props => props.theme.fonts.sizes[14]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 19px;
`

const Body3: FunctionComponent<ITypographyProps> = ({ children, tag, ...props }) => (
  <Body3Text
    {...props}
    as={tag || 'p'}
  >
    {children}
  </Body3Text>
)

export default Body3
