const SearchingInternet: any = (
  style = {},
  fill = '#000',
  width = '100%',
  height = '100%',
  viewBox = '0 0 561 493',
) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M560.242 267.34C560.242 343.246 515.74 410.398 447.52 451.276C403.78 477.49 350.284 492.904 292.522 492.904C215.572 492.904 156.874 463.822 112.72 419.272C72.022 378.22 43.684 324.022 24.802 267.34C-59.492 14.266 81.322 -54.776 292.522 41.776C426.994 103.258 560.242 142.768 560.242 267.34Z"
      fill="#293189"
    />
    <path
      d="M127.72 78.4539C136.004 78.4539 142.72 71.7381 142.72 63.4539C142.72 55.1696 136.004 48.4539 127.72 48.4539C119.436 48.4539 112.72 55.1696 112.72 63.4539C112.72 71.7381 119.436 78.4539 127.72 78.4539Z"
      fill="#F2F2F2"
    />
    <path
      d="M259.12 116.254C264.753 116.254 269.32 111.687 269.32 106.054C269.32 100.421 264.753 95.854 259.12 95.854C253.487 95.854 248.92 100.421 248.92 106.054C248.92 111.687 253.487 116.254 259.12 116.254Z"
      fill="#F2F2F2"
    />
    <path
      d="M382.745 455.493C442.519 455.493 490.976 407.037 490.976 347.262C490.976 287.488 442.519 239.031 382.745 239.031C322.97 239.031 274.513 287.488 274.513 347.262C274.513 407.037 322.97 455.493 382.745 455.493Z"
      fill="#F2F2F2"
    />
    <path
      d="M386.85 470.123C443.573 470.123 489.556 461.688 489.556 451.283C489.556 440.878 443.573 432.444 386.85 432.444C330.127 432.444 284.144 440.878 284.144 451.283C284.144 461.688 330.127 470.123 386.85 470.123Z"
      fill="#3F3D56"
    />
    <path
      d="M478.076 450.748C477.97 450.575 475.47 446.401 474.604 437.733C473.809 429.781 474.32 416.377 481.271 397.68C494.44 362.259 478.236 333.68 478.071 333.395L478.87 332.932C478.912 333.004 483.093 340.29 485.564 351.893C488.827 367.276 487.639 383.271 482.137 398.002C468.991 433.362 478.765 450.102 478.865 450.267L478.076 450.748Z"
      fill="#3F3D56"
    />
    <path
      d="M472.927 331.316C476.244 331.316 478.932 328.627 478.932 325.31C478.932 321.993 476.244 319.304 472.927 319.304C469.61 319.304 466.921 321.993 466.921 325.31C466.921 328.627 469.61 331.316 472.927 331.316Z"
      fill="#3F3D56"
    />
    <path
      d="M491.868 353.491C495.185 353.491 497.874 350.802 497.874 347.485C497.874 344.168 495.185 341.479 491.868 341.479C488.551 341.479 485.862 344.168 485.862 347.485C485.862 350.802 488.551 353.491 491.868 353.491Z"
      fill="#3F3D56"
    />
    <path
      d="M478.933 368.274C482.249 368.274 484.938 365.585 484.938 362.268C484.938 358.952 482.249 356.263 478.933 356.263C475.616 356.263 472.927 358.952 472.927 362.268C472.927 365.585 475.616 368.274 478.933 368.274Z"
      fill="#595AD3"
    />
    <path
      d="M494.64 380.748C497.957 380.748 500.646 378.059 500.646 374.742C500.646 371.425 497.957 368.736 494.64 368.736C491.323 368.736 488.634 371.425 488.634 374.742C488.634 378.059 491.323 380.748 494.64 380.748Z"
      fill="#595AD3"
    />
    <path
      d="M474.313 400.151C477.63 400.151 480.318 397.462 480.318 394.145C480.318 390.829 477.63 388.14 474.313 388.14C470.996 388.14 468.307 390.829 468.307 394.145C468.307 397.462 470.996 400.151 474.313 400.151Z"
      fill="#3F3D56"
    />
    <path
      d="M481.704 450.969C481.704 450.969 475.699 436.186 493.716 425.098L481.704 450.969Z"
      fill="#3F3D56"
    />
    <path
      d="M474.318 450.701C474.318 450.701 471.585 434.98 450.43 435.115L474.318 450.701Z"
      fill="#3F3D56"
    />
    <path
      d="M99.0908 277.777L193.896 342.196L202.924 335.237L210.913 335.93L195.72 350.704L99.0908 289.932V277.777Z"
      fill="#3F3D56"
    />
    <path
      d="M293.235 247.695L333.37 295.097L325.442 304.821L278.371 251.949L293.235 247.695Z"
      fill="#2F2E41"
    />
    <path
      d="M94.8369 274.738C99.8715 274.738 103.953 270.113 103.953 264.407C103.953 258.701 99.8715 254.075 94.8369 254.075C89.8023 254.075 85.7209 258.701 85.7209 264.407C85.7209 270.113 89.8023 274.738 94.8369 274.738Z"
      fill="#171F74"
    />
    <path
      d="M305.57 244.357C321.866 183.205 299.516 124.156 255.65 112.467C211.785 100.778 163.014 140.875 146.719 202.027C130.423 263.179 152.773 322.229 196.639 333.918C240.505 345.607 289.275 305.509 305.57 244.357Z"
      fill="#3F3D56"
    />
    <path
      opacity="0.1"
      d="M298.86 242.57C313.779 186.585 293.318 132.524 253.158 121.823C212.999 111.121 168.349 147.831 153.43 203.816C138.511 259.801 158.973 313.861 199.132 324.563C239.291 335.265 283.941 298.555 298.86 242.57Z"
      fill="white"
    />
    <path
      d="M209.09 221.258C210.097 221.258 210.913 220.442 210.913 219.435C210.913 218.428 210.097 217.612 209.09 217.612C208.083 217.612 207.267 218.428 207.267 219.435C207.267 220.442 208.083 221.258 209.09 221.258Z"
      fill="#171F74"
    />
    <path
      d="M198.758 264.407C199.765 264.407 200.581 263.591 200.581 262.584C200.581 261.577 199.765 260.76 198.758 260.76C197.751 260.76 196.935 261.577 196.935 262.584C196.935 263.591 197.751 264.407 198.758 264.407Z"
      fill="#171F74"
    />
    <path
      d="M249.808 224.904C250.814 224.904 251.631 224.088 251.631 223.081C251.631 222.074 250.814 221.258 249.808 221.258C248.801 221.258 247.984 222.074 247.984 223.081C247.984 224.088 248.801 224.904 249.808 224.904Z"
      fill="#171F74"
    />
    <path
      d="M239.476 268.661C240.483 268.661 241.299 267.845 241.299 266.838C241.299 265.831 240.483 265.015 239.476 265.015C238.469 265.015 237.653 265.831 237.653 266.838C237.653 267.845 238.469 268.661 239.476 268.661Z"
      fill="#171F74"
    />
    <path
      d="M102.13 292.97L96.1496 294.167L96.0524 294.186L82.0747 290.539L77.8206 262.584L80.3973 261.38L86.9365 258.33C98.4833 258.33 100.914 265.015 100.914 265.015L102.13 292.97Z"
      fill="#3F3D56"
    />
    <path
      opacity="0.1"
      d="M96.1496 294.167L96.0524 294.186L82.0747 290.539L77.8206 262.584L80.3973 261.38C85.7271 262.085 91.9624 263.599 94.2292 266.838L96.1496 294.167Z"
      fill="black"
    />
    <path
      d="M124.918 224.601C124.59 224.601 124.268 224.512 123.986 224.344C106.008 213.632 88.5279 213.632 70.5495 224.344C69.6847 224.857 68.5678 224.573 68.0531 223.709C67.5385 222.845 67.8202 221.728 68.683 221.211C87.6507 209.911 106.885 209.911 125.853 221.211C126.558 221.632 126.895 222.471 126.677 223.262C126.459 224.054 125.739 224.602 124.918 224.601H124.918Z"
      fill="#FFE400"
    />
    <path
      d="M117.929 239.71C117.601 239.71 117.279 239.621 116.997 239.453C103.517 231.422 90.411 231.422 76.9306 239.453C76.0658 239.966 74.9489 239.682 74.4342 238.818C73.9196 237.954 74.2013 236.837 75.0641 236.32C89.5961 227.663 104.332 227.663 118.864 236.32C119.569 236.741 119.906 237.58 119.688 238.372C119.47 239.163 118.75 239.711 117.929 239.71H117.929Z"
      fill="#FFE400"
    />
    <path
      d="M130.387 207.584C130.059 207.585 129.737 207.496 129.456 207.327C111.514 196.638 81.1983 196.638 63.2566 207.327C62.697 207.662 62.0011 207.673 61.4316 207.355C60.8621 207.037 60.5058 206.439 60.4972 205.787C60.4887 205.135 60.8292 204.527 61.3901 204.195C80.3436 192.903 112.369 192.903 131.322 204.195C132.027 204.615 132.365 205.455 132.146 206.246C131.928 207.037 131.208 207.585 130.387 207.584H130.387Z"
      fill="#FFE400"
    />
    <path
      d="M429.839 415.312L433.689 430.71L416.752 435.33L415.212 419.162L429.839 415.312Z"
      fill="#FBBEBE"
    />
    <path
      d="M368.25 434.559V449.187L351.312 452.266L348.233 444.567L354.392 431.48L368.25 434.559Z"
      fill="#FBBEBE"
    />
    <path
      d="M402.124 275.196L399.815 295.983L401.354 336.016C401.354 336.016 403.664 336.016 403.664 338.326C403.664 340.635 401.354 339.865 403.664 341.405C404.903 342.246 406.188 343.017 407.513 343.715L432.149 418.392C432.149 418.392 425.99 426.091 414.442 423.781L412.133 416.083C412.133 416.083 407.513 411.463 407.513 409.154C407.513 406.844 409.053 406.074 407.513 403.765C405.974 401.455 382.877 354.493 382.877 354.493L372.099 335.246L369.02 361.422C369.02 361.422 375.179 394.526 373.639 409.924C372.099 425.321 369.02 441.488 369.02 441.488L350.543 438.409C350.459 435.924 349.657 433.518 348.233 431.48C345.924 428.4 342.844 429.17 345.924 426.861C349.003 424.551 352.852 426.861 349.773 423.781C346.694 420.702 346.694 417.622 346.694 417.622V415.313L342.074 356.033L333.606 278.276L402.124 275.196Z"
      fill="black"
    />
    <path
      d="M349.773 135.079C361.253 135.079 370.559 125.773 370.559 114.293C370.559 102.813 361.253 93.5063 349.773 93.5063C338.293 93.5063 328.986 102.813 328.986 114.293C328.986 125.773 338.293 135.079 349.773 135.079Z"
      fill="#FBBEBE"
    />
    <path
      d="M367.865 114.678L376.334 135.464L356.317 150.092L343.999 128.535L367.865 114.678Z"
      fill="#FBBEBE"
    />
    <path
      d="M369.02 130.46L376.718 126.611L381.338 137.389C381.338 137.389 411.363 146.628 409.053 188.97C406.743 231.313 401.354 247.481 401.354 247.481C401.354 247.481 414.442 275.196 406.743 278.276C399.045 281.355 333.606 292.903 332.066 282.125C330.526 271.347 337.455 208.217 337.455 208.217C337.455 208.217 335.145 162.795 354.392 153.556L350.543 145.088L369.02 130.46Z"
      fill="#D0CDE1"
    />
    <path
      d="M326.677 279.815C326.677 279.815 308.97 298.292 318.978 302.911C328.986 307.531 335.145 282.125 335.145 282.125L326.677 279.815Z"
      fill="#FBBEBE"
    />
    <path
      d="M362.861 445.338L371.329 442.258C371.329 442.258 380.568 463.045 372.869 463.045H352.853C352.853 463.045 341.304 459.195 335.145 459.195C328.987 459.195 317.438 447.647 325.907 442.258C334.376 436.869 345.154 438.409 345.154 438.409L348.233 431.48L352.083 442.258C352.083 442.258 356.702 449.957 362.861 445.338Z"
      fill="#2F2E41"
    />
    <path
      d="M425.22 428.4C425.22 428.4 430.041 425.358 432.635 426.495C435.229 427.631 436.768 439.949 436.768 439.949C436.768 439.949 444.467 449.187 427.53 450.727C410.593 452.266 406.743 449.187 405.204 444.568C403.664 439.949 406.743 435.329 406.743 435.329L411.363 433.79C411.363 433.79 416.752 429.94 415.212 428.4C413.672 426.861 415.212 423.781 415.212 423.781C415.212 423.781 420.601 434.559 425.22 428.4Z"
      fill="#2F2E41"
    />
    <path
      d="M356.883 113.96C356.798 113.287 356.647 112.624 356.431 111.982C355.313 109.638 350.954 110.322 350.178 107.844C350.026 107.359 350.057 106.832 349.908 106.346C349.529 105.107 348.072 104.493 346.777 104.552C345.483 104.612 344.27 105.165 343.024 105.519C339.978 106.383 336.738 106.053 333.591 105.716C332.199 105.567 330.755 105.401 329.563 104.668C328.371 103.934 327.512 102.458 327.946 101.128C328.362 99.8518 329.758 99.1508 330.386 97.9647C331.382 96.0814 330.164 93.6491 330.973 91.6776C332.27 88.5152 337.179 89.1502 339.673 86.8127C340.278 86.2453 340.714 85.52 341.294 84.9269C343.016 83.1674 345.734 82.8268 348.184 83.0695C350.634 83.3123 353.041 84.0316 355.502 83.9881C357.836 83.9468 360.283 83.236 362.434 84.1439C363.738 84.6947 364.735 85.7721 365.69 86.8187L371.206 92.8688C371.963 93.583 372.547 94.4617 372.911 95.437C373.243 96.5962 372.937 97.8408 373.079 99.0383C373.402 101.769 375.959 103.832 376.304 106.56C376.373 107.406 376.332 108.258 376.182 109.094L375.409 114.791C375.37 115.347 375.222 115.889 374.974 116.387C374.749 116.771 374.409 117.075 374.156 117.44C373.442 118.474 373.507 119.839 373.152 121.044C372.797 122.249 371.525 123.441 370.411 122.86C369.685 122.482 369.204 119.349 368.322 118.536C367.093 117.403 366.451 117.542 364.933 117.561C361.895 117.6 357.855 117.86 356.883 113.96Z"
      fill="black"
    />
    <path
      d="M360.551 158.945C360.551 158.945 383.647 168.184 376.718 188.97C369.79 209.757 351.313 273.656 351.313 273.656L340.534 279.045L337.455 287.514L322.827 282.125L325.137 270.577C325.137 270.577 325.907 269.807 325.907 266.727C325.907 263.648 322.827 262.878 326.677 262.878C330.526 262.878 330.526 259.029 330.526 259.029L344.384 172.803C344.384 172.803 344.384 158.175 360.551 158.945Z"
      fill="#D0CDE1"
    />
    <path
      opacity="0.1"
      d="M377.874 193.205L354.008 274.811L343.999 284.82L349.773 274.426L377.874 193.205Z"
      fill="black"
    />
  </svg>
)

export default SearchingInternet
