import cookie from 'cookie'
// dependency of next/router
import Cookies from 'js-cookie'
import { ParsedUrlQuery } from 'querystring'

import { sendIntercomNewListingEvent } from 'services/intercom'
import { trackCompletionsForListing } from 'services/listings'

import { applyDiscount } from 'utils/applyDiscount'
import { capitalizeWord } from 'utils/capitalizeWord'
import { getPackageLabel } from 'utils/listingHelpers'

import * as T from 'types'
import * as E from 'types/enums'

import {
  formatVariations,
  getVariationsForVwoUserId,
  getVwoClientInstance,
  goalToOngoingExperimentsOfType,
} from './vwo'

interface IProperty {
  [key: string]: any
}

interface IContext {
  cid?: string
  ua?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  gclid?: string
}

interface IParsedContext {
  cid?: string
  ua?: string
  gclid?: string
  campaign?: {
    name?: string
    medium?: string
    source?: string
  }
}

declare global {
  interface Window {
    analytics: any
    dotq: any
    ga: any
  }
}

/** helpers */

const isProductionCheck = () => {
  if (
    typeof window === 'undefined'
    || !window.analytics
    || !window.analytics.initialized
    || cookie.parse(document.cookie).disableAnalyticsTracking
    // || process.env.NODE_ENV === 'development'
  ) {
    return false
  }

  return true
}

// Helper function that sets the global variable for GTM to read the dynamic conversion value from it
const setGlobalConversionValue = (
  globalConversionKey: string,
  value: number,
  dataLayerEvent?: string,
) => {
  if (typeof window !== 'undefined' && window) {
    window[globalConversionKey] = value
    if (dataLayerEvent && window.dataLayer) {
      window.dataLayer.push({
        event: dataLayerEvent,
      })
    }
  }
}

// for automated bidding
const getSignupGoalValueForState = (state: string) => {
  let value: number = 1

  switch (state) {
    case 'FL':
      value = 144.27
      break
    case 'NY':
      value = 144.13
      break
    case 'CA':
      value = 172.89
      break
    case 'TX':
      value = 138.5
      break
    case 'PA':
      value = 131.7
      break
    case 'GA':
      value = 139.97
      break
    case 'NJ':
      value = 176.91
      break
    case 'MA':
      value = 178.9
      break
    case 'NC':
      value = 191.67
      break
    case 'MD':
      value = 216.8
      break
    case 'OR':
      value = 162.19
      break
    case 'CT':
      value = 141.44
      break
    case 'UT':
      value = 145.68
      break
    case 'DC':
      value = 127.2
      break
    case 'NM':
      value = 93.16
      break
    case 'DE':
      value = 187.59
      break
    case 'WA':
      value = 153.68
      break
    default:
      break
  }

  return value
}

/** Segment/Google Analytics */

export const page = () => {
  if (isProductionCheck()) {
    window.analytics.page()
  }
}

export const ready = (callback: () => void) => {
  window.analytics.ready(() => callback())
}

export const identify = (userId: string, traits: IProperty) => {
  if (isProductionCheck()) {
    window.analytics.identify(userId, traits)
  }
}

export const reset = () => {
  if (isProductionCheck()) {
    window.analytics.reset()
  }
}

export const alias = (aid: string) => {
  if (isProductionCheck()) {
    window.analytics.alias(aid)
  }
}

export const track = (event: string, properties: IProperty = {}, context: IContext = {}) => {
  if (isProductionCheck()) {
    const parsedContext: IParsedContext = {
      cid: context.cid,
      ua: context.ua,
      gclid: context.gclid,
    }

    if (context.utmCampaign || context.utmMedium || context.utmSource) {
      parsedContext.campaign = {
        name: context.utmCampaign,
        medium: context.utmMedium,
        source: context.utmSource,
      }
    }

    window.analytics.track(
      event,
      {
        ...properties,
        appV2: true,
      },
      parsedContext,
    )
  }
}

/** Gemini */

interface IGemini {
  ea: string
  el?: string
  ev?: number
}

export const trackGemini = (params: IGemini) => {
  if (isProductionCheck()) {
    if (typeof window !== 'undefined' && window.dotq) {
      window.dotq.push({
        projectId: '10000',
        properties: {
          pixelId: '10098327',
          qstrings: params,
        },
      })
    }
  }
}

/** VWO A/B testing */

export const trackVwoGoalCompletions = async (
  goalIdentifier: E.VwoGoalIdentifier,
  variations: T.IVariations,
  persistedVwoUserIdOverride?: string,
) => {
  try {
    // get ongoing experiments that are set up to track this goal
    const experimentsForGoal = goalToOngoingExperimentsOfType[goalIdentifier]

    // get the user id, preferably persisted in DB, cookies is for pre-signup tracking cases
    const vwoUserId = persistedVwoUserIdOverride || Cookies.get('vwoUserId')
    if (!vwoUserId) throw new Error('vwoUserId not found in DB or cookies')

    // variations may be empty if this track call is called from the `document/sign/[token]` page
    // because it's not the same site visitor as the listing user, so we need to get the variations
    let assignedExperiments = variations

    if (Object.keys(variations).length === 0) {
      assignedExperiments = await getVariationsForVwoUserId(vwoUserId, experimentsForGoal)
    }

    // remove null values. these occur if test has ended, or if misconfigured
    const userAssignedExperiments = experimentsForGoal.filter(exp =>
      Boolean(assignedExperiments[exp]),
    )

    // track
    const vwoClientInstance = await getVwoClientInstance()
    userAssignedExperiments.forEach(testName => {
      vwoClientInstance.track(testName, vwoUserId, goalIdentifier)
    })
  } catch (err) {
    console.error(err)
  }
}

/** Funnel step-ordered conversion track calls */

export const trackZipNotCovered = (
  listingData: T.ICreateListingData,
  user: T.IUser | T.ICreateUserData,
  query: ParsedUrlQuery,
) => {
  track('Uncovered Address', {
    city: listingData?.city,
    county: listingData?.county,
    state: listingData?.state,
    zip: listingData?.zip,
    street: listingData?.street,
    ...query,
  })
  track('error.region_not_covered', {
    city: listingData?.city,
    county: listingData?.county,
    state: listingData?.state,
    zip: listingData?.zip,
    street: listingData?.street,
    email: user?.email,
    name: user?.name,
    ...query,
  })
}

export const trackNewSignUpAddress = (
  listingData: T.ICreateListingData,
  user: T.IUser | T.ICreateUserData | undefined,
  query: ParsedUrlQuery,
) => {
  track('New Sign Up Address', {
    city: listingData?.city,
    county: listingData?.county,
    state: listingData?.state,
    zip: listingData?.zip,
    street: listingData?.street,
    email: user?.email,
    name: user?.name,
    ...query,
  })
}

export const trackSignup = (
  newListing: T.IListing,
  user: T.IUser | T.ICreateUserData,
  query: ParsedUrlQuery,
) => {
  let mlsId: string | undefined = ''

  if (newListing?.mlsList && newListing?.mlsList.length) {
    mlsId = newListing?.mlsList[0].mls?._id
  }

  const provider = user.provider && user.provider !== 'local' ? user.provider : 'email'
  const signUpMethod = provider !== 'email' ? `Social - ${capitalizeWord(provider)}` : 'Email'
  track('FE Signup', {
    provider,
    'Sign Up Method': signUpMethod,
    ...query,
  })

  // triggering event for GTM
  const stateBasedValue: number = getSignupGoalValueForState(newListing.state)
  setGlobalConversionValue('globalConversionValue', stateBasedValue, 'value.based.bidding.signup')

  // GA event
  track('goal.signup.fe', {
    'city': newListing?.city,
    'county': newListing?.county,
    'state': newListing?.state,
    'zip': newListing?.zip,
    'street': newListing?.street,
    'email': user?.email,
    'name': user?.name,
    'listingID': newListing?._id,
    'preselectedPricingPackage': newListing?.preselectedPricingPackage,
    mlsId,
    'userId': newListing?.userId?._id,
    'value': stateBasedValue,
    provider,
    'Sign Up Method': signUpMethod,
    ...query,
  })
  trackGemini({
    ea: 'goal.signup.fe',
  })

  // VWO A/B Tests
  const vwoUserExperiments
    = (user as T.IUser)?.vwoExperiments || newListing?.userId?.vwoExperiments || []
  if (!vwoUserExperiments.length) return

  const vwoExperiments = formatVariations(vwoUserExperiments)
  trackVwoGoalCompletions(E.VwoGoalIdentifier.signup, vwoExperiments, (user as T.IUser)?.vwoUserId)
}

// helper function to calculate total price for package
const calculateTotalPriceForPackage = (
  pricingPackage: T.IPricingPackage,
  payAtCloseDiscountValue?: number,
) => {
  const { payNow, payAtClose } = pricingPackage

  return Number(payNow || 0) + Number(payAtClose || 0) - Number(payAtCloseDiscountValue || 0)
}

/**
Tracks the purchase of the basic package for a listing.
Sends conversion data for GTM, Segment and VWO.
@param {T.IListing} listing - The listing for which the package is being purchased.
@param {ParsedUrlQuery | T.IRefererParams} query - The query or referer params.
@param {T.IUser} [user] - The user who is making the purchase.
@param {T.IOfferCode} [coupon] - The coupon to be applied.
@param {number} [payAtCloseDiscountValue] - The pay at close discount value.
@returns {void}
*/
export const trackBasicPackagePurchase = (
  listing: T.IListing,
  query: ParsedUrlQuery | T.IRefererParams,
  user?: T.IUser,
  coupon?: T.IOfferCode,
  payAtCloseDiscountValue?: number,
) => {
  const price = calculateTotalPriceForPackage(listing.pricingPackage, payAtCloseDiscountValue)
  setGlobalConversionValue('globalPackageConversionValue', price)
  track(
    'goal.purchase_service_package.basic.fe',
    {
      listingId: listing._id,
      label: 'Basic',
      value: price,
      pricingPackageName: 'Basic',
      priceBeforeDiscount: payAtCloseDiscountValue ? listing.pricingPackage.price : undefined,
      discount: payAtCloseDiscountValue ? coupon?.name : undefined,
      pricingPackagePayNow: listing.pricingPackage.payNow,
      pricingPackagePayAtClose: listing.pricingPackage.payAtClose - (payAtCloseDiscountValue || 0),
      pricingPackageLabel: getPackageLabel(listing),
    },
    query,
  )
  if (window && window.ga) {
    window.ga(
      'gtm1.send',
      'event',
      '(FE) Basic service package purchased',
      'goal.purchase_service_package.basic.fe.test',
      'Basic',
    )
  }

  trackGemini({
    ea: 'goal.purchase_service_package.basic.fe',
    el: 'Basic',
    ev: price,
  })

  const vwoExperiments = formatVariations(
    user?.vwoExperiments || listing.userId?.vwoExperiments || [],
  )

  const vwoUserId = user?.vwoUserId || listing.userId?.vwoUserId
  trackVwoGoalCompletions(E.VwoGoalIdentifier.basic, vwoExperiments, vwoUserId)
}

/**

Tracks the purchase of a pricing package and sends relevant conversion data to analytics services.
Tracks the purchase of Premium/Platinum packages.
Sends conversion data for GTM, Segment and VWO.
@param {Object} listing - The listing object for which the pricing package is being purchased.
@param {Object} user - The user object making the purchase.
@param {Object} [coupon] - An optional discount object.
@param {number} [payAtCloseDiscountValue] - An optional discount value to apply at closing.
@returns {void}
*/
export const trackPaidPricingPackage = (
  listing: T.IListing,
  user: T.IUser,
  coupon?: T.IOfferCode,
  payAtCloseDiscountValue?: number,
) => {
  const price = calculateTotalPriceForPackage(listing.pricingPackage, payAtCloseDiscountValue)
  setGlobalConversionValue('globalPackageConversionValue', price)
  track(
    `goal.purchase_service_package.${(listing.pricingPackage.name || '').toLowerCase()}.fe`,
    {
      listingId: listing._id,
      label: listing.pricingPackage.name || '',
      value: price,
      priceBeforeDiscount: payAtCloseDiscountValue ? listing.pricingPackage.price : undefined,
      discount: payAtCloseDiscountValue ? coupon?.name : undefined,
      pricingPackageName: listing.pricingPackage.name || '',
      pricingPackagePayNow: listing.pricingPackage.payNow,
      pricingPackagePayAtClose: listing.pricingPackage.payAtClose - (payAtCloseDiscountValue || 0),
      pricingPackageLabel: getPackageLabel(listing),
    },
    user?.referrerParams || {},
  )
  if (window && window.ga) {
    window.ga(
      'gtm1.send',
      'event',
      `(FE) ${listing.pricingPackage.name || ''} service package purchased`,
      `goal.purchase_service_package.${(listing.pricingPackage.name || '').toLowerCase()}.fe.test`,
      listing.pricingPackage.name || '',
    )
  }

  trackGemini({
    ea: `goal.purchase_service_package.${(listing.pricingPackage.name || '').toLowerCase()}.fe`,
    el: listing.pricingPackage.name || '',
    ev: price,
  })

  // VWO A/B Tests
  const goalIdentifier
    = listing.pricingPackage.name === 'Premium'
      ? E.VwoGoalIdentifier.premium
      : E.VwoGoalIdentifier.platinum

  const vwoExperiments = formatVariations(user?.vwoExperiments || [])
  trackVwoGoalCompletions(goalIdentifier, vwoExperiments, user?.vwoUserId)
}

/**
Tracks the purchase of an add-on service. Sends conversion data for GTM, Segment and VWO.
@param {Object} listing - The listing that the add-on service was purchased for.
@param {Object} user - The user who purchased the add-on service.
@param {Object} service - The add-on service that was purchased.
@param {Object} coupon - The coupon that was used to purchase the add-on service.
@returns {void}
*/
export const trackAddOnServicePurchase = (
  listing: T.IListing,
  user: T.IUser,
  service: T.IOrderService,
  coupon: T.IOfferCode | null,
) => {
  const { price } = service
  setGlobalConversionValue('globalServiceConversionValue', price, 'fe.add.service')

  const { amountWithDiscountApplied, isDiscountApplied } = applyDiscount(
    coupon,
    service.type._id,
    price,
  )

  // GA tracking
  track(
    'FE Item Purchased',
    {
      name: service.type?.name,
      revenue: amountWithDiscountApplied,
      value: amountWithDiscountApplied,
      priceBeforeDiscount: isDiscountApplied ? price : undefined,
      discount: isDiscountApplied ? coupon?.name : undefined,
      listingId: listing._id,
    },
    user.referrerParams,
  )

  // VWO A/B Tests
  const vwoUserExperiments = user?.vwoExperiments || listing?.userId?.vwoExperiments || []
  const vwoExperiments = formatVariations(vwoUserExperiments)
  trackVwoGoalCompletions(E.VwoGoalIdentifier.addOnService, vwoExperiments, user?.vwoUserId)
}

export const trackPreselectedPackage = (preselectedPackageName: string) => {
  localStorage.setItem('preselectedPackageName', preselectedPackageName)
  track('Preselected Pricing Package', {
    preselectedPackageName,
  })
}

export const trackMultipleEventsForListing = (listing: T.IListing, events: T.ITriggeredEvent[]) => {
  events.forEach((event: T.ITriggeredEvent) => {
    track(event.event, {
      listingId: listing._id,
      city: listing?.city,
      county: listing?.county,
      state: listing?.state,
      zip: listing?.zip,
      street: listing?.street,
      email: listing.userId?.email,
      name: listing.userId?.name,
    })
  })
}

export const trackSSOAccountConnectionSignup = async (
  listing: T.IListing,
  user: T.IUser,
  analyticsEvents: T.ITriggeredEvent[],
  query: ParsedUrlQuery,
): Promise<boolean> => {
  if (!listing || listing.hasOptimisticChanges || !analyticsEvents.length) return false

  const signUpEvent = 'goal.signup.fe'

  if (analyticsEvents?.find(evt => evt.event === signUpEvent)) return false

  trackSignup(listing, user, query)

  sendIntercomNewListingEvent(listing, query)

  const analyticsEventsUpdated = await trackCompletionsForListing(
    listing,
    [
      {
        event: signUpEvent,
        type: 'fe',
      },
    ],
    true,
  )
  return analyticsEventsUpdated
}

export const trackSelectedStateForSignup = (state: T.IState) => {
  const existingState = localStorage.getItem('selectedState')

  if (existingState !== state.abbreviation) {
    localStorage.setItem('selectedState', state.abbreviation)
    track('Signup: Selected State', {
      ...state,
      previousStateValue: existingState,
    })
  }
}

export const trackGeolocatedStateForSignup = (state?: T.IState | null) => {
  if (state) track('Signup: Geolocated State', state)
  else track('Signup: Geolocated State')
}
