const LicensingData: { [key: string]: any } = {
  florida: {
    name: 'FLORIDA',
    licensing:
      'Florida Real Estate Brokerage, License No. CQ1050201 \n David Montalvo, License No. BK3555118',
  },
  texas: {
    name: 'TEXAS',
    licensing:
      'Texas Real Estate Brokerage, License No. 9008445 \n David Montalvo, License No. TX 595597',
  },
}

export default LicensingData
