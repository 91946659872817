import { useEffect, useState } from 'react'

import { useDocuments } from 'services/swr/documents/useDocuments'

import { getAgreementFromListing } from 'utils/agreementHelpers'
import { getAgreementOverride } from 'utils/listingHelpers'

import { amendmentListingStatuses } from 'data/listingStatusGroupings'

import * as T from 'types'

const amendmentListingStatusStrings: string[] = Object.values(amendmentListingStatuses)

const useAgreementSigningState = (listing?: T.IListing) => {
  const agreement = getAgreementFromListing(listing)
  const { listingDocuments } = useDocuments(listing?._id)
  const agreementOverrideDocument = getAgreementOverride(listingDocuments)
  const agreementOverrideExists = !!agreementOverrideDocument?.signedDate

  const signedArray = agreement?.signers?.filter((signer: T.ISigner) => signer.signed) ?? []
  const signerCount = signedArray.length

  const agreementInProgress = !!agreement && !agreement.signed && !agreementOverrideExists
  const agreementNoSigners = agreementInProgress && !signerCount
  const agreementAllSignersSigned = agreementInProgress && signerCount === agreement.signers.length
  const agreementComplete = (!!agreement && agreement.signed) || agreementOverrideExists

  // this is for the case where the user is managing the listing, so they're the first signer.
  // if `agreementInProgress` and the `listingUser` hasn't signed yet, we silently reset the
  // agreement for the user on the BE, so we don't show the Edit Buttons/Reset Agreement Modal
  // when `awaitingListingUserSigner` is true
  const managingUser = listing?.iAmNotATitleHolder
  const listingUserEmail = listing?.userId?.email
  const listingUserSigned = !!signerCount && signedArray[0].email === listingUserEmail
  const awaitingListingUserSigner = agreementInProgress && !managingUser && !listingUserSigned

  const [amendmentInProgress, setAmendmentInProgress] = useState(
    amendmentListingStatusStrings.includes(listing?.listingStatus.name ?? ''),
  )

  useEffect(() => {
    if (amendmentListingStatusStrings.includes(listing?.listingStatus.name ?? '')) {
      setAmendmentInProgress(true)
    }
  }, [listing?.listingStatus.name])

  return {
    agreement,
    agreementInProgress,
    agreementNoSigners,
    agreementAllSignersSigned,
    agreementComplete, // broker signer has signed too
    awaitingListingUserSigner,
    setAmendmentInProgress,
    amendmentInProgress,
  }
}

export default useAgreementSigningState
