import * as T from '../types'

const InstagramCircle = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 45 44"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="instagramCircleTitle">Instagram Logo</title>
    <desc id="instagramCircleDescription">A white Twitter Logo in a black circle</desc>
    <g clipPath="url(#clip0)">
      <path d="M18.6266 21.8327C18.6266 24.1407 20.4975 26.0116 22.8055 26.0116C25.1135 26.0116 26.9844 24.1407 26.9844 21.8327C26.9844 19.5247 25.1135 17.6538 22.8055 17.6538C20.4975 17.6538 18.6266 19.5247 18.6266 21.8327Z" />
      <path d="M13.0325 14.4392C13.2334 13.8948 13.5539 13.4021 13.9703 12.9977C14.3747 12.5812 14.8671 12.2608 15.4118 12.0599C15.8535 11.8883 16.5172 11.6841 17.7394 11.6285C19.0617 11.5682 19.4581 11.5552 22.8055 11.5552C26.1532 11.5552 26.5496 11.5678 27.8715 11.6281C29.0938 11.6841 29.7578 11.8883 30.1992 12.0599C30.7439 12.2608 31.2366 12.5812 31.6407 12.9977C32.0571 13.4021 32.3776 13.8945 32.5788 14.4392C32.7504 14.8809 32.9546 15.5448 33.0102 16.7671C33.0705 18.089 33.0835 18.4855 33.0835 21.8332C33.0835 25.1806 33.0705 25.577 33.0102 26.8992C32.9546 28.1215 32.7504 28.7851 32.5788 29.2269C32.3776 29.7715 32.0574 30.2639 31.641 30.6684C31.2366 31.0848 30.7442 31.4053 30.1995 31.6061C29.7578 31.778 29.0938 31.9823 27.8715 32.0379C26.5496 32.0982 26.1535 32.1109 22.8058 32.1109C19.4578 32.1109 19.0613 32.0982 17.7398 32.0379C16.5175 31.9823 15.8535 31.778 15.4118 31.6061C14.3184 31.1844 13.4543 30.3202 13.0325 29.2269C12.861 28.7851 12.6567 28.1215 12.6008 26.8992C12.5405 25.577 12.5278 25.1806 12.5278 21.8332C12.5278 18.4855 12.5405 18.089 12.6008 16.7671C12.6564 15.5448 12.8606 14.8809 13.0325 14.4392ZM22.8055 28.2704C26.3611 28.2704 29.2434 25.3884 29.2434 21.8328C29.2434 18.2773 26.3611 15.3953 22.8055 15.3953C19.2502 15.3953 16.3679 18.2773 16.3679 21.8328C16.3679 25.3884 19.2502 28.2704 22.8055 28.2704ZM16.1134 16.6452C16.9442 16.6452 17.6178 15.9716 17.6178 15.1408C17.6178 14.3099 16.9442 13.6363 16.1134 13.6363C15.2825 13.6363 14.6089 14.3099 14.6089 15.1408C14.6093 15.9716 15.2825 16.6452 16.1134 16.6452Z" />
      <path d="M22.8056 0C34.8616 0 44.6382 9.77663 44.6382 21.8326C44.6382 33.8886 34.8616 43.6652 22.8056 43.6652C10.7496 43.6652 0.972988 33.8886 0.972988 21.8326C0.972988 9.77663 10.7496 0 22.8056 0ZM10.3445 27.0012C10.4052 28.3358 10.6174 29.2469 10.9272 30.0445C11.5785 31.7285 12.9097 33.0597 14.5937 33.711C15.3909 34.0208 16.3024 34.2327 17.6366 34.2937C18.9735 34.3546 19.4006 34.3693 22.8053 34.3693C26.2103 34.3693 26.637 34.3546 27.9742 34.2937C29.3085 34.2327 30.2199 34.0208 31.0171 33.711C31.854 33.3962 32.6115 32.9028 33.2378 32.2648C33.8755 31.6389 34.3688 30.881 34.6837 30.0445C34.9935 29.2473 35.2057 28.3358 35.2663 27.0016C35.3279 25.6644 35.3423 25.2373 35.3423 21.8326C35.3423 18.4279 35.3279 18.0008 35.2667 16.6639C35.206 15.3294 34.9941 14.4183 34.6843 13.6207C34.3695 12.7842 33.8758 12.0263 33.2378 11.4003C32.6119 10.7624 31.854 10.269 31.0175 9.95419C30.2199 9.64437 29.3088 9.4325 27.9742 9.37153C26.6374 9.31057 26.2103 9.29591 22.8056 9.29591C19.4009 9.29591 18.9738 9.31057 17.6369 9.37187C16.3024 9.4325 15.3912 9.64437 14.5937 9.95386C13.7572 10.2687 12.9993 10.7624 12.373 11.4003C11.7354 12.0267 11.2417 12.7842 10.9272 13.6207C10.617 14.4183 10.4052 15.3294 10.3442 16.6639C10.2832 18.0008 10.2689 18.4279 10.2689 21.8326C10.2689 25.2373 10.2832 25.6644 10.3445 27.0012Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="43.6652"
          height="43.6652"
          fill="white"
          transform="matrix(-1 0 0 1 44.6382 0)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default InstagramCircle
