import produce from 'immer'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import useSWR, { mutate } from 'swr'

import { SavingContext } from 'services/contexts/SavingContext'
import { fetch, fetchWithToken } from 'services/fetchers'
import {
  deleteListingOwner,
  updateListing,
  updateListingField,
  updateListingOwner,
} from 'services/listings'

import * as T from 'types'
import { IServerError } from 'types'

import { refreshOrders } from './orders/useOrders'
import getErrorMessage from './utils/getErrorMessage'

let lastRequest = 0
let updatingFields = false

interface IOptions {
  customLinkUrl?: boolean
  withToken?: boolean
  noFetch?: boolean
  shouldRefetch?: boolean
}

const defaultOptions: IOptions = {
  customLinkUrl: false,
  withToken: true,
  noFetch: false,
  shouldRefetch: false,
}

export const useListing = ({
  customLinkUrl,
  withToken,
  noFetch,
  shouldRefetch,
} = defaultOptions) => {
  const { setSaving } = useContext(SavingContext)

  const router = useRouter()
  let apiEndpoint: string | null = customLinkUrl
    ? `/view/${router.query.id}`
    : `/listings/${router.query.id}/ownerDetails`

  // router is `undefined` on first render. use `null` key in `useSWR` to avoid calling API with an
  // `undefined` param that will result in flashing user an error message
  if (noFetch || !router.query.id) apiEndpoint = null

  const response = useSWR<T.IListing | IServerError>(
    apiEndpoint,
    withToken ? fetchWithToken : fetch,
    { isPaused: () => updatingFields },
  )

  let listing: T.IListing | undefined
  if (response.data && !response.data.message) listing = response.data as T.IListing

  const error = getErrorMessage(response, 'listing')
  let updateError = ''

  // DEPRECATED: use `updateMany` going forward, even for single updates
  const update = async (key: string, value: T.ValueOf<T.IListing>) => {
    try {
      if (!listing) return

      // tell any components that care that we are saving
      setSaving(true)

      const optimisticListing = produce(listing, draftListing => {
        draftListing[key] = value
        draftListing.hasOptimisticChanges = true
      })
      mutate(apiEndpoint, optimisticListing, false) // no revalidation because it's optimistic

      // race condition fix
      const requestId = Math.random()
      lastRequest = requestId

      // in this mutate call, we will POST the API and update the UI
      // if the update fails it will roll back the optimistic change
      const updateResult = await updateListing(listing._id, { [key]: value })

      if (updateResult) {
        if (lastRequest !== requestId) return

        await mutate(apiEndpoint, updateResult, false)
        // update the cart to recalculate conditionalPropertyType items
        if (key === 'propertyType') await refreshOrders()
      } else {
        // if update failed, restore back to default value
        await mutate(apiEndpoint, listing, false)
        updateError = 'failed to update'
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  const updateField: T.IUpdateField = async (
    field,
    fieldIndex, // present if it exists, otherwise we're adding a new field to the fields array
  ) => {
    try {
      if (!listing) return

      // tell any components that care that we are saving
      setSaving(true)

      // edge case: swr would sometimes refetch after we tried to update conditional parent fields
      // this tells swr to temporarily pause revalidation, which solves that issue
      updatingFields = true

      const optimisticListing = produce(listing, draftListing => {
        if (fieldIndex) draftListing.fields[fieldIndex].value = field.value || ''
        else draftListing.fields.push(field)

        draftListing.hasOptimisticChanges = true
      })
      mutate(apiEndpoint, optimisticListing, false) // no revalidation because it's optimistic

      // race condition fix
      const requestId = Math.random()
      lastRequest = requestId

      // in this mutate call, we will POST the API and update the UI
      // if the update fails it will roll back the optimistic change
      const updateResult = await updateListingField(listing._id, field)

      if (updateResult) {
        if (lastRequest !== requestId) return

        await mutate(apiEndpoint, updateResult, shouldRefetch)
        if (updatingFields) updatingFields = false
      } else {
        // if update failed, restore back to default value
        await mutate(apiEndpoint, listing, shouldRefetch)
        if (updatingFields) updatingFields = false

        updateError = 'failed to update'
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  const updateOwner: T.IUpdateOwner = async (
    owner,
    ownerIndex, // present if it exists, otherwise we're adding a new field to the fields array
    unshift, // if user is title holder and not yet in owners array, we add them in 0th position
  ) => {
    try {
      if (!listing) return

      // tell any components that care that we are saving
      setSaving(true)

      const optimisticListing = produce(listing, draftListing => {
        if (typeof ownerIndex === 'number') draftListing.owners[ownerIndex] = owner as T.IOwner
        else draftListing.owners.push(owner as T.IOwner)

        draftListing.hasOptimisticChanges = true
      })
      mutate(apiEndpoint, optimisticListing, false) // no revalidation because it's optimistic

      // race condition fix
      const requestId = Math.random()
      lastRequest = requestId

      // in this mutate call, we will POST the API and update the UI
      // if the update fails it will roll back the optimistic change
      const updateResult = await updateListingOwner(listing._id, owner, unshift)

      if (updateResult) {
        if (lastRequest !== requestId) return

        await mutate(apiEndpoint, updateResult, shouldRefetch)
      } else {
        // if update failed, restore back to default value
        await mutate(apiEndpoint, listing, shouldRefetch)
        updateError = 'failed to update'
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  const deleteOwner: T.IDeleteOwner = async (ownerId, ownerIndex) => {
    try {
      if (!listing) return

      // tell any components that care that we are saving
      setSaving(true)

      const optimisticListing = produce(listing, draftListing => {
        draftListing.owners.splice(ownerIndex, 1)
        draftListing.hasOptimisticChanges = true
      })
      mutate(apiEndpoint, optimisticListing, false) // no revalidation because it's optimistic

      // race condition fix
      const requestId = Math.random()
      lastRequest = requestId

      // in this mutate call, we will POST the API and update the UI
      // if the update fails it will roll back the optimistic change
      const updateResult = await deleteListingOwner(listing._id, ownerId)

      if (updateResult) {
        if (lastRequest !== requestId) return

        await mutate(apiEndpoint, updateResult, shouldRefetch)
      } else {
        // if update failed, restore back to default value
        await mutate(apiEndpoint, listing, shouldRefetch)
        updateError = 'failed to update'
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  // @TODO: this should eventually replace `update` as the only way to update a listing
  const updateMany: T.IUpdateManyListing = async updates => {
    try {
      if (!listing) return

      // tell any components that care that we are saving
      setSaving(true)

      const optimisticListing = produce(listing, draftListing => {
        Object.keys(updates).forEach(key => {
          draftListing[key] = updates[key]
        })
        draftListing.hasOptimisticChanges = true
      })
      mutate(apiEndpoint, optimisticListing, false) // no revalidation because it's optimistic

      // race condition fix
      const requestId = Math.random()
      lastRequest = requestId

      // in this mutate call, we will POST the API and update the UI
      // if the update fails it will roll back the optimistic change
      const updateResult = await updateListing(listing._id, updates)

      if (updateResult) {
        if (lastRequest !== requestId) return

        await mutate(apiEndpoint, updateResult, shouldRefetch)
      } else {
        // if update failed, restore back to default value
        await mutate(apiEndpoint, listing, shouldRefetch)
        updateError = 'failed to update field'
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSaving(false)
    }
  }

  const refreshListing = () => mutate(apiEndpoint)

  return {
    ...response,
    listing,
    error,
    update,
    updateMany,
    updateField,
    updateOwner,
    deleteOwner,
    refreshListing,
    updateError,
  }
}
