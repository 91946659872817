/* eslint-disable camelcase */
import apisauce, { ApisauceInstance } from 'apisauce'
import Cookies from 'js-cookie'
import { NextPageContext } from 'next'

import { initializeCookiesAndUpdHeaders } from 'utils/cookiesHelpers'
import getToken from 'utils/getToken'

import * as T from 'types'

import { IPaymentData } from './payments'

export default class ApiCreator {
  public api: ApisauceInstance

  constructor(ctx?: NextPageContext) {
    let token = ''
    if (typeof window !== 'undefined') token = Cookies.get('token') || ''

    if (ctx && !token) token = getToken(ctx) || ''

    // TEMP: remove quotes if token is url-encoded (how old site works)
    if (token?.includes('"') || token?.includes('%')) {
      token = decodeURI(token).replace(/"/g, '')
      Cookies.set('token', token)
    }

    const headers: { [key: string]: string } = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }

    const ga = Cookies.get('_ga') || ''
    if (ga) headers.Ga = ga

    const qs = typeof window !== 'undefined' ? window.location.search : ''
    if (qs) headers.qs = qs

    const updHeaders = initializeCookiesAndUpdHeaders(headers, qs)

    this.api = apisauce.create({
      // withCredentials: true, // CAUSES CORS ERRORS, BE does not support
      baseURL: process.env.API_URL,
      headers: {
        ...headers,
        ...updHeaders,
      },
      timeout: 15000,
    })
  }

  // generic get call for use with SWR hook
  public get = (path: string, params = {}) => this.api.get(path, params)

  // generic ping for use with establishing if client can connect to server
  public ping = (path?: string, params = {}) => this.api.get(`/${path || ''}`, params)

  // auth
  public login = (email: string, password: string) =>
    this.api.post<T.IToken>('auth/local', { email, password })

  public changePassword = (userId: string, oldPassword: string, newPassword: string) =>
    this.api.put(`users/${userId}/password`, { oldPassword, newPassword })

  public requestResetPassword = (email: string) =>
    this.api.post<{ message: string } | undefined>('users/password/request', { email })

  public resetPassword = (token: string, password: string) =>
    this.api.post(`users/password/reset/${token}`, { password })

  public updateLastLogin = (userId: string) =>
    this.api.put(`users/updateUser/${userId}/lastLogin`, {})

  // email
  public verifyEmail = () => this.api.get('users/verify/request')

  public verifyToken = (token: string) => this.api.get(`users/verify/${token}`)

  // demographics
  public getLocalAttractions = (listingId: string) =>
    this.api.get<T.ILocalAttraction[]>(`demographics/${listingId}/localAttractions`)

  public getLocalSchools = (listingId: string) =>
    this.api.get<T.IGreatSchoolsRatings>(`demographics/${listingId}/localSchools`)

  public getNeighborhoodDetails = (listingId: string) =>
    this.api.get<T.INeighborhoodDetails>(`demographics/${listingId}/neighborhoodDetails`)

  public getWalkAndTransitScores = (listingId: string) =>
    this.api.get<T.IWalkAndTransitScore>(`demographics/${listingId}/walkAndTransitScores`)

  // user
  public createUser = (createUserParams: T.ISignupParams) =>
    this.api.post<T.ISignupResponseData>('users', createUserParams)

  public getUser = (userId: string) => this.api.get<T.IUser>(`users/${userId}`)

  public getMe = () => this.api.get<T.IUser>('users/me')

  public updateUser = (userId: string, update: Partial<T.IUser>) =>
    this.api.put<T.IUser>(`users/${userId}`, update)

  public deleteUser = (userId: string) => this.api.delete(`users/${userId}`)

  // listing
  public createListing = (data: T.ICreateListingData) => this.api.post<T.IListing>('listings', data)

  public getListing = (listingId: string) =>
    this.api.get<T.IListing>(`listings/${listingId}/ownerDetails`)

  public getListingByAddress = (data: T.ICreateListingData) =>
    this.api.get<T.IListing>('listings/userListingByAddress', data)

  public getPublicListing = (listingId: string) => this.api.get<T.IListing>(`view/${listingId}`)

  public getPublicListingPhotos = (listingId: string) =>
    this.api.get<T.IPhoto[]>(`view/${listingId}/photos`)

  public getMyListings = () => this.api.get<T.ISparseListing[]>('listings/user?sparse=true')

  public getMyListingsFullyPopulated = () => this.api.get<T.IListing[]>('listings/user')

  public getPropertyComps = (listingId: string) =>
    this.api.get(`listings/${listingId}/propertyComparables`)

  // aka property details from third party services: melissa data, zillow
  public getPropertyDetails = (listingId: string) =>
    this.api.get<T.IListing>(`listings/${listingId}/propertyDetails`)

  public getAverageHomeValueByZipcode = (zipcode: string, months: number = 3) =>
    this.api.get<T.AverageHomeValueData>(`melissa/average-home-value/zipcode/${zipcode}`, {
      months,
    })

  public updateListing = (listingId: string, update: { [key: string]: T.ValueOf<T.IListing> }) =>
    this.api.put<T.IListing>(`listings/${listingId}`, { update })

  public updateListingField = (listingId: string, field: Partial<T.IField>) =>
    this.api.put<T.IListing>(`listings/${listingId}/field/${field.fieldId?._id}`, field)

  public updateListingOwner = (listingId: string, owner: Partial<T.IOwner>, unshift?: boolean) =>
    this.api.put<T.IListing>(
      `listings/${listingId}/owner/${owner?._id}`,
      owner,
      unshift ? { params: { position: 0 } } : {},
    )

  public deleteListingOwner = (listingId: string, ownerId: string) =>
    this.api.delete<T.IListing>(`listings/${listingId}/owner/${ownerId}`)

  public updatePhotos = (listingId: string, update: T.IListingPhotosUpdate) =>
    this.api.put(`listings/${listingId}/updatePhotos`, update)

  public updateFlowState = (listingId: string, flowState: T.IFlowState) =>
    this.api.put<T.IFlowState>(`flowStates/${listingId}/updateFlowState`, flowState)

  public contactSeller = (listingId: string, params: T.IContactSellerData) =>
    this.api.post(`listings/${listingId}/contactSeller`, params)

  public contactUs = (params: T.IContactSellerData) => this.api.post('contactUs', params)

  public buyerInquiry = (params: T.IContactSellerData) =>
    this.api.post('contactUs/buyerInquiry', params)

  public resetAgreement = (listingId: string) =>
    this.api.post(`listings/${listingId}/resetAgreement`, {})

  public signatureRequest = (listingId: string, params: { isAmendment: boolean }) =>
    this.api.post<T.ISignUrlData | T.IServerError>(`hellosigns/agreement/${listingId}`, params)

  public getAgreementSignUrlByToken = (token: string) =>
    this.api.get<T.ITokenSignUrlData>(`hellosigns/sign-url/token/${token}`)

  public signedAgreementBySignatureId = (
    signatureId: string,
    event: string,
    fromWeb: boolean,
    user?: T.IUser,
  ) =>
    this.api.post<T.ISignedResponseData>('hellosigns/signed', { signatureId, event, fromWeb, user })

  public getListingDocumentObjectFromSignerToken = (token: string) =>
    this.api.post('hellosigns/document/token', { token })

  public getDocumentByToken = (token: string) =>
    this.api.post<T.IListingMlsDocument>(
      'hellosigns/view/token',
      { token },
      { responseType: 'arraybuffer' },
    )

  public updateRoom = (listingId: string, room: T.IRoom) =>
    this.api.post(`listings/${listingId}/saveRoomDimensions`, { room })

  public trackCompletionsForListing = (listingId: string, events: T.ITriggeredEvent[]) =>
    this.api.post<T.ITriggeredEvent[]>(`listings/${listingId}/trackAnalyticsEvents`, { events })

  public deleteListing = (listingId: string) => this.api.delete(`listings/${listingId}`)

  public getListingChecklists = (listingId: string) =>
    this.api.get(`listings/${listingId}/checklists`)

  // offerCodes
  public checkOfferCode = (params: T.ICheckOfferCodeParams) =>
    this.api.get<T.IOfferCode | null>('offerCodes/checkCode', params)

  public addUserOfferCode = (offerCode: T.IOfferCode) =>
    this.api.post<T.IOfferCode[]>('users/addCoupon', { offerCode })

  public removeUserOfferCode = (offerCode: T.IOfferCode) =>
    this.api.post<T.IOfferCode[]>('users/removeCoupon', { offerCode })

  // orders (includes cart)
  public getCart = () => this.api.get<T.IOrder>('orders/user/open')

  public getOrders = () => this.api.get<T.IOrder[]>('orders/user/history')

  public payCart = (
    orderId: string,
    update: { stripeToken: string; discount?: T.IOrderDiscount },
  ) => this.api.put<T.IOrder>(`orders/${orderId}/pay`, update)

  public updateCartItems = (orderId: string, items: T.ICreateOrderItem[]) =>
    this.api.put<T.IOrder>(`orders/${orderId}`, { items })

  public updateCartShipping = (orderId: string, shipping: T.IOrderShipping) =>
    this.api.put<T.IOrder>(`orders/${orderId}/shipping`, { shipping })

  // stripe payments

  public createSetupIntent = () => this.api.post<string>('stripe/payment/future')

  public createPaymentIntent = (paymentData: IPaymentData) =>
    this.api.post<string>('stripe/payment/charge', paymentData)

  public confirmCardSetup = (userId: string, setupIntentId: string) =>
    this.api.post<T.IUser>(`stripe/payment/confirm/user/${userId}`, { setupIntentId })

  // pricing packages
  public getPricingPackages = () => this.api.get<T.IPricingPackage[]>('pricingpackages')

  public getPricingPackageItems = () => this.api.get<T.IPricingPackageItem[]>('pricingpackageitems')

  // s3
  public getS3UploadUrl = (
    listingId: string,
    name: string,
    type: string,
    filetype: 'photos' | 'agreements',
  ) =>
    this.api.get<{ signed_request: string; [key: string]: any }>(
      `amazons/${listingId}/s3/sign/${filetype}?name=${encodeURIComponent(name)}&type=${type}`,
    )

  public addAttachedDocument = (listingId: string, url: string, name: string, type: string) =>
    this.api.post<{ [key: string]: any }>(`amazons/${listingId}/s3/addAttachedDocument`, {
      type,
      url,
      name,
    })

  // documents
  public createDocument = (data: Partial<T.IAdminUploadedDocument | T.IAttachedDocument>) =>
    this.api.post<T.IListingDocument>('documents', data)

  public removeDocument = (documentId: string) => this.api.delete(`documents/${documentId}`)

  // services
  public getServices = () => this.api.get<T.IService>('services')

  // zip
  public validateZip = (zip: string) => this.api.get<[]>(`zips/validate/${zip}`)

  // checklists
  public addChecklist = (checklist: T.IChecklistCreateObject) =>
    this.api.post<T.IChecklist>('checklists', checklist)

  //  ORIGINAL
  // public updateChecklist = (
  //   checklistId: string,
  //   update: T.IChecklistUpdateObject,
  // ) => this.api.put<T.IChecklist>(`checklists/${checklistId}`, update)

  // @FIXME: per original app's api calls, 'update' is always in practice an entire checklist object
  // using T.IChecklist is hard right now because { tasks: string[] | T.ITask[] } causes issues
  public updateChecklist = (checklistId: string, update: any) =>
    this.api.put<T.IChecklist>(`checklists/${checklistId}`, update)

  public addTask = (task: string) => this.api.post<T.ITask>('tasks', { task })

  public deleteTask = (taskId: string) => this.api.delete(`tasks/${taskId}`)

  public updateTask = (taskId: string, update: T.ITaskUpdateObject) =>
    this.api.put<T.ITask>(`tasks/${taskId}`, update)

  public emitSellerNotesViewedEvent = (listingId: string, viewedNotesIds: string[]) =>
    this.api.post<void>(`/listings/${listingId}/sellerNotes/events/notesViewed`, viewedNotesIds)

  public patchContractDetails = (id: number, updates: Partial<T.IContractDetails>) =>
    this.api.patch<T.IContractDetails>(`contract-details/${id}`, updates)

  public getStates = () => this.api.get<T.IState[]>('states')
}

export const api = new ApiCreator()
