import * as T from 'modules/Marketing/types'

const CampaignData: { [key: string]: T.CampaignInfo } = {
  'home': {
    name: 'Marketing Home',
    label: 'Home',
    pageTitle: 'Better Than For Sale By Owner - HomeLister',
    metaDescription:
      'HomeLister provides home sellers with the benefits of For Sale By Owner, like taking total control over your own sale, without the hassles of FSBO.',
    metaKeywords: 'for sale by owner, fsbo, homelister',
    heroTitle: 'Better Home Selling',
    heroSubtitle: 'The modern way to sell your home. Consider it sold!',
    imgUrl: 'amazon/assets/c/1920/homelister-home.jpg',
    infoTitle: 'What is HomeLister',
    infoContent:
      'HomeLister offers a better way to list and sell your home online faster and save more of your equity. We’ll list on your local MLS, plus Zillow, Trulia, Redfin, Realtor.com and all other major real estate search websites for a low flat fee, not a percentage commission. It’s an easy, guided process from adding your home details to closing. Our home sellers save an average of $21,000 more when you sell.',
  },
  'for-sale-by-owner': {
    name: 'For Sale By Owner',
    label: 'Better Than For Sale By Owner',
    pageTitle: 'Better Than For Sale By Owner - HomeLister',
    metaDescription:
      'HomeLister provides home sellers with the benefits of For Sale By Owner, like taking total control over your own sale, without the hassles of FSBO.',
    metaKeywords: 'for sale by owner, fsbo, homelister',
    heroTitle: 'Better Than For Sale By Owner',
    heroSubtitle: 'Sell your home with the benefits of FSBO minus the hassle!',
    imgUrl: 'amazon/assets/1920/homelister-for-sale-by-owner.jpg',
    infoTitle: "Why We're Better Than FSBO",
    infoContent:
      'HomeLister provides home sellers with the benefits of For Sale By Owner, like taking total control over your own sale, without the hassles of FSBO, such as figuring out disclosures and forms, and lack of interest from qualified buyers. Get listed on your local MLS, Zillow, Trulia, Redfin, Realtor.com, and all other real estate search websites. With HomeLister, you can sell your home faster and for more money.',
  },
  'mls': {
    name: 'MLS',
    label: 'Get Listed in MLS',
    pageTitle: 'Get Listed on the MLS for a Low Flat Fee - HomeLister',
    metaDescription:
      'HomeLiaster will list your home on your local MLS, plus Zillow, Trulia, Redfin, Realtor.com and all other major real estate search websites for a low flat fee.',
    metaKeywords: 'mls, multiple listing service, homelister',
    heroTitle: 'Get It Listed, Get It Sold',
    heroSubtitle: 'We List You On the MLS and Everywhere Else!',
    imgUrl: 'amazon/assets/1920/homelister-mls.jpg',
    infoTitle: 'Get Listed in the MLS',
    infoContent:
      'HomeLister will list your home on your local MLS, plus Zillow, Trulia, Redfin, Realtor.com and all other major real estate search websites for a low flat fee, not a percentage commission. It’s an easy, guided process from adding your home details to closing. Our home sellers save an average of $21,000 more when you sell.',
  },
  'sell-home-online': {
    name: 'Sell Home Online',
    label: 'Sell Your Home Online',
    pageTitle: 'Sell Your Home Online - HomeLister',
    metaDescription:
      'HomeLister offers a new way to list and sell your home online faster and save more of your equity.',
    metaKeywords: 'sell your home online, homelister',
    heroTitle: 'A New Way To Sell Your Home Online',
    heroSubtitle: 'It’s better, faster, smarter and only low flat fees!',
    imgUrl: 'amazon/assets/1920/homelister-sell-home-online.jpg',
    infoTitle: 'Modernizing Home Selling',
    infoContent:
      'HomeLister offers a better way to list and sell your home online faster and save more of your equity. We’ll list on your local MLS, plus Zillow, Trulia, Redfin, Realtor.com and all other major real estate search websites for a low flat fee, not a percentage commission. It’s an easy, guided process from adding your home details to closing. Our home sellers save an average of $21,000 more when you sell.',
  },
  'online-realtor': {
    name: 'Online Realtor',
    label: 'Hybrid Realtor',
    pageTitle: 'Discover your Online Realtor Solution | HomeLister',
    metaDescription:
      'HomeLister offers a better way to list and sell your home online faster and save more. Learn how we list on all major real estate sites for a low flat fee.',
    metaKeywords: 'sell your home online, homelister',
    heroTitle: 'Sell Your Home Modernized',
    heroSubtitle: 'A new way to sell your home online.',
    imgUrl: 'amazon/assets/1920/homelister-online-realtor.jpg',
    infoTitle: 'Modernizing Home Selling',
    infoContent:
      'HomeLister offers a better way to list and sell your home online faster and save more of your equity. We’ll list on your local MLS, plus Zillow, Trulia, Redfin, Realtor.com and all other major real estate search websites for a low flat fee, not a percentage commission. It’s an easy, guided process from adding your home details to closing. Our home sellers save an average of $21,000 more when you sell.',
  },
  'sell-home-without-realtor': {
    name: 'Sell Home Without Realtor',
    label: 'HomeLister vs Offline Traditional Realtor',
    pageTitle: 'Sell Your Home Without an Offline Traditional Real Estate Agent - HomeLister',
    metaDescription:
      'Sell your home without an offline traditional real estate realtor, but get the full benefit with HomeLister',
    metaKeywords: 'sell your home without a realtor, homelister',
    heroTitle: 'Say Hello To A Better Way To Sell Your Home',
    heroSubtitle: 'And goodbye to high commissions and fees!',
    imgUrl: 'amazon/assets/1920/homelister-sell-home-without-realtor.jpg',
    infoTitle: 'Sell Your Home Faster & Keep More of Your Equity',
    infoContent:
      'HomeLister offers a better way to list and sell your home online faster and save more of your equity. We’ll list on your local MLS, plus Zillow, Trulia, Redfin, Realtor.com and all other major real estate search websites for a low flat fee, not a percentage commission. It’s an easy, guided process from adding your home details to closing. Our home sellers save an average of $21,000 more when you sell.',
  },
  'sell-your-home-fast': {
    name: 'Sell Your Home Fast',
    label: 'Sell Your Home Fast',
    pageTitle: 'Sell Your Home Fast - HomeLister',
    metaDescription: 'Sell your home fast and easy with HomeLister',
    metaKeywords: 'sell your home fast, homelister',
    heroTitle: 'Sell Your Home For Full Value and Fast.',
    heroSubtitle: 'Get the offer you deserve!',
    imgUrl: 'amazon/assets/1920/homelister-sell-home-fast.jpg',
    infoTitle: 'Selling Your Home Fast Doesn’t Mean Selling For Less',
    infoContent:
      'HomeLister can help you sell your home for its full value fast, and keep more of your equity.  We’ll list on your local MLS, plus Zillow, Trulia, Redfin, Realtor.com and all other major real estate search websites for a low flat fee.  Our home sellers save an average of $21,000 more when you sell.  It’s your hard earned money, so keep more of it! ',
  },
}

export default CampaignData
