import { ApiResponse } from 'apisauce'
import { responseInterface } from 'swr'

import { IServerError } from 'types'

const INTERNAL_SERVER_ERR = 'Internal Server Error'

export const getFetchErrorMessage = <A>(
  response: responseInterface<A, any>,
  dataType?: string,
  plural = false,
) => {
  const serverError = response.data as IServerError
  const dataName = dataType || 'data'

  const adj = plural ? 'these' : 'this'

  let error = ''

  if (serverError?.status === '403') {
    error = 'You have reached a page that only the account holder has access to.'
  }

  if (!error) error = serverError?.message ?? ''

  if (!error && !!response.error) error = `There was an issue loading this ${adj} ${dataName}.`

  if (error === INTERNAL_SERVER_ERR) error = `There was an issue loading this ${adj} ${dataName}.`

  return error
}

interface IGetUpdateErrorMessageOpts {
  userReadableError?: string
  statusCodeErrors?: { [key: string]: string }
  showServerError?: boolean
}

interface IGetUpdateErrorMessage {
  <A>(response: ApiResponse<A, any>, opts: IGetUpdateErrorMessageOpts): {
    message: string
    status?: string | undefined
  }
}

export const getUpdateErrorMessage: IGetUpdateErrorMessage = (response, opts = {}) => {
  if (response.ok) return { message: '' } // no error

  const { showServerError, statusCodeErrors, userReadableError } = opts

  const defaultMsg = 'There was an issue updating this data.'
  const status = response.status ? String(response.status) : undefined
  let message = ''

  // reading the server code and adding an error for every status code a route can return is the
  // optimal solution
  if (response.status && statusCodeErrors?.[response.status]) {
    message = statusCodeErrors[response.status]
  }

  // you can also pass one catch all error message to the user for a route
  if (!message && userReadableError) message = userReadableError

  // if neither human readable error args are sent, we'll send a very generic error
  if (!message) message = defaultMsg

  // can also send the error sent from the server, but this is risky, so only do this if you know
  // what you're doing and are sure the route will never throw a gnarly error
  if (showServerError) {
    message = response.data?.message || response.originalError?.message || defaultMsg
  }

  return { message, status }
}
