import * as T from 'types'
import * as E from 'types/enums'

import { sortByDateModified } from './listingHelpers'

export const getEditLinkByListingStatus = (listing?: T.IListing | T.ISparseListing) => {
  if (!listing) return { href: '/', as: '/' }

  let subPath = 'homeDetails/basic-home-details'
  if (listing.listingStatus.name === E.ListingStatus.draft) subPath = 'welcome'

  return {
    href: `/listing/[id]/create/${subPath}`,
    as: `/listing/${listing._id}/create/${subPath}`,
  }
}

export const getEditLinkByMostRecentlyUpdated = (listings?: (T.IListing | T.ISparseListing)[]) => {
  if (!listings || !listings.length) return { href: '/', as: '/' }

  const sortedListings = listings.sort(sortByDateModified)

  const draftListings = sortedListings.filter(
    listing => listing.listingStatus.name === E.ListingStatus.draft,
  )

  const mostRecentListing = draftListings.length ? draftListings[0] : sortedListings[0]
  if (!mostRecentListing) return { href: '/', as: '/' }

  const subPath = draftListings.length ? 'welcome' : 'homeDetails/basic-home-details'

  // use these values to construct a Link in the following form:
  // `<Link href={href}` as={as} />}`
  return {
    href: `/listing/[id]/create/${subPath}`,
    as: `/listing/${mostRecentListing._id}/create/${subPath}`,
    subStep: subPath === 'homeDetails/basic-home-details' ? 'basic-home-details' : 'welcome',
  }
}
