/* eslint-disable import/prefer-default-export */
import * as T from '../types'
import { api } from './api'
import { refreshCompletionPercentages } from './swr/completionPercentages'

export const updateFlowState = async (
  listingId: string,
  flowState: T.IFlowState,
): Promise<[T.IFlowState | undefined, T.IServerError]> => {
  const response = await api.updateFlowState(listingId, flowState)
  refreshCompletionPercentages(listingId)
  return [response.data, { message: response.originalError?.message }]
}
