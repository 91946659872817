import { ReactNode, createContext, useReducer } from 'react'

enum actions {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}
type Action = { type: actions.SHOW } | { type: actions.HIDE }

type State = { overlay: boolean }
const initialState = { overlay: false }

type ProviderProps = { children: ReactNode }

function overlayReducer(state: State, action: Action) {
  switch (action.type) {
    case actions.SHOW:
      return { overlay: true }
    case actions.HIDE:
      return { overlay: false }
    default:
      throw new Error('Unhandled action type')
  }
}

interface IContext {
  overlay: boolean
  showOverlay: () => void
  hideOverlay: () => void
}

let value: IContext = {
  overlay: false,
  showOverlay: () => {},
  hideOverlay: () => {},
}

const OverlayContext = createContext<IContext>(value)

const OverlayProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(overlayReducer, initialState)

  value = {
    overlay: state.overlay,
    showOverlay: () => dispatch({ type: actions.SHOW }),
    hideOverlay: () => dispatch({ type: actions.HIDE }),
  }
  return <OverlayContext.Provider value={value}>{children}</OverlayContext.Provider>
}

export { OverlayContext, OverlayProvider }
