import * as T from 'types'

/**
applyDiscount function calculates the discounted amount for a given service,
based on the discount code and other parameters provided.
@param {Object} discount - The discount object that contains information about the discount code.
@param {string} serviceId - The ID of the service that the discount is being applied to.
@param {number} servicePrice - The original price of the service with any previous discount already applied.
@returns {[number, boolean]} An array containing the discounted amount as the first element,
and a boolean indicating whether the discount was applied as the second element.
*/
export const applyDiscount = (
  discount: T.IOfferCode | null,
  serviceId: string,
  servicePrice: number,
) => {
  if (!discount) return { amountWithDiscountApplied: servicePrice, isDiscountApplied: false }

  const isDiscountApplied = !discount.signupOffer && discount.validServices?.includes(serviceId)
  if (!isDiscountApplied) return { amountWithDiscountApplied: servicePrice, isDiscountApplied }

  let amountWithDiscountApplied = servicePrice
  if (discount.units === 'percent') {
    amountWithDiscountApplied -= +((discount.discount / 100) * amountWithDiscountApplied).toFixed(2)
  } else if (discount.units === 'fixed') {
    amountWithDiscountApplied = discount?.discount
  } else if (discount.discount) {
    amountWithDiscountApplied -= discount.discount
  }

  return { amountWithDiscountApplied, isDiscountApplied }
}
