import { useEffect, useState } from 'react'

import { getFromLocalStorage, saveToLocalStorage } from 'utils/localStorage'

import * as T from 'types'
import { LocalStorageKey } from 'types/enums'

const useGuidedTourModeState = (listing?: T.IListing) => {
  const getGuidedTourMode = () => {
    if (!listing) return false

    const cachedGuidedTourMode = getFromLocalStorage(LocalStorageKey.guidedTourMode)
    return !!(cachedGuidedTourMode && cachedGuidedTourMode[listing._id] === 'on')
  }

  const [guidedTour, setGuidedTour] = useState<boolean>(getGuidedTourMode)

  const setGuidedTourMode = (listingId: string, mode: string) => {
    setGuidedTour(mode === 'on')
    saveToLocalStorage(LocalStorageKey.guidedTourMode, mode, listingId)
  }

  useEffect(() => {
    setGuidedTour(getGuidedTourMode)
  }, [listing])

  return { guidedTour, setGuidedTourMode }
}

export default useGuidedTourModeState
