import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { INTERCOM_DEFAULT_HORIZONTAL_PADDING } from 'themes/intercom'

import ProductTourPopover, { LAST_STEP } from 'modules/ProductTour'

import { ProductTourContext } from 'services/contexts'
import { useElement } from 'services/hooks'
import usePreventScroll from 'services/hooks/usePreventScroll'

import { useWindowSize } from '../../../src/services/hooks'

const LISTING_FLOW_PATH = 'listing/[id]/create'

interface IVisibilityProps {
  visible: boolean
  rightValue?: number
}
const IntercomProductTourBackingSquare = styled.div<IVisibilityProps>`
  position: fixed;
  display: ${props => (props.visible ? 'block' : 'none')};
  bottom: 0px;
  right: 0px;
  background: ${props => props.theme.colors.white};
  width: 100px;
  height: 100px;
  z-index: ${props => props.theme.zIndices.level11};
  transition: all 1s ease-in-out;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    transition: all 1s ease-in-out;
    height: 100px;
    width: 100px;
  }

  @media (min-width: ${props => props.theme.metrics.listingFlowPageMaxWidth}px) {
    right: ${props => props.rightValue}px;
    transition: none;
  }
`

const IntercomDisabledOverlay = styled(IntercomProductTourBackingSquare)`
  background: transparent;
  z-index: 2147483002; // 1 greater than what the intercom widget sets its z-index to
`

const ProductTourIntercomStep = () => {
  const router = useRouter()
  const { width } = useWindowSize()
  const theme = useContext(ThemeContext)
  const inListingFlow = router.pathname.includes(LISTING_FLOW_PATH)
  const isLargeDesktop = width && width >= theme.metrics.listingFlowPageMaxWidth
  let intercomRightValue = 0

  // minus 20 to fit the white box behind intercom to the edge of the page
  // when viewport is greater than 1920px
  if (inListingFlow && isLargeDesktop) {
    intercomRightValue
      = (width - theme.metrics.listingFlowPageMaxWidth) / 2 + INTERCOM_DEFAULT_HORIZONTAL_PADDING - 20
  }

  // product tour context
  const { productTourActive, productTourStep } = useContext(ProductTourContext)
  const intercomTourStepActive = productTourActive && productTourStep === LAST_STEP

  // show a white square behind the intercom launcher when the product tour is active
  const [productTourRef, productTourElement] = useElement<HTMLDivElement>()
  usePreventScroll(productTourActive, productTourElement, 'productTour')

  return (
    <>
      <IntercomProductTourBackingSquare
        ref={productTourRef}
        visible={intercomTourStepActive}
        rightValue={intercomRightValue}
      />
      <IntercomDisabledOverlay visible={intercomTourStepActive} />
      <ProductTourPopover
        visibleStep={LAST_STEP}
        referenceElement={productTourElement}
      />
    </>
  )
}

export default ProductTourIntercomStep
