/* eslint-disable no-alert */
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const useLeavePrevention = (needsLeaveConfirmed: boolean) => {
  const router = useRouter()

  const [leaveConfirmed, setLeaveConfirmed] = useState(false)

  const handleWindowClose = (event: BeforeUnloadEvent) => {
    if (!needsLeaveConfirmed || leaveConfirmed) return

    return (event.returnValue = 'Saving in progress. Are you sure you want to leave?')
  }

  const handleRouteChange = () => {
    if (!needsLeaveConfirmed || leaveConfirmed) return

    if (window.confirm('Are you sure you want to leave?')) setLeaveConfirmed(true)
    else {
      router.events.emit('routeChangeError')
      // eslint-disable-next-line no-throw-literal
      throw 'routeChange aborted.' // needs to be a plain string to work, Error obj will fail
    }
  }

  // Use beforeunload to prevent closing the tab, refreshing the page or moving outside the Next app
  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose)
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
  })

  // Use routeChangeStart to prevent navigation inside of the Next app
  // Uses a module variable to bypass the confirm, otherwise we would be in a loop
  useEffect(() => {
    if (router?.events) router.events.on('routeChangeStart', handleRouteChange)

    return () => router.events.off('routeChangeStart', handleRouteChange)
  })
}

export default useLeavePrevention
