import * as T from './types'

const PhoneOutlined = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1.75003C2.66848 1.75003 2.35054 1.88173 2.11612 2.11615C1.8865 2.34576 1.75544 2.6555 1.75017 2.97965C1.98167 6.68821 3.5593 10.185 6.18716 12.8129C8.81502 15.4407 12.3118 17.0184 16.0204 17.2499C16.3445 17.2446 16.6543 17.1135 16.8839 16.8839C17.1183 16.6495 17.25 16.3315 17.25 16V12.5078L13.3145 10.9336L12.1431 12.8859C11.9423 13.2206 11.5183 13.3453 11.1683 13.1727C8.84867 12.0287 6.97133 10.1514 5.82735 7.83177C5.65471 7.4817 5.77943 7.05773 6.11413 6.85691L8.06643 5.68553L6.49223 1.75003H3ZM1.05546 1.05549C1.57118 0.539762 2.27065 0.250031 3 0.250031H7C7.30668 0.250031 7.58246 0.436744 7.69636 0.721487L9.69636 5.72149C9.83307 6.06327 9.70153 6.45376 9.38587 6.64315L7.49809 7.77582C8.39409 9.32124 9.67879 10.6059 11.2242 11.5019L12.3569 9.61416C12.5463 9.2985 12.9368 9.16696 13.2785 9.30367L18.2785 11.3037C18.5633 11.4176 18.75 11.6933 18.75 12V16C18.75 16.7294 18.4603 17.4288 17.9445 17.9446C17.4288 18.4603 16.7293 18.75 16 18.75C15.9848 18.75 15.9697 18.7496 15.9545 18.7486C11.8709 18.5005 8.01935 16.7664 5.1265 13.8735C2.23364 10.9807 0.499542 7.12911 0.251381 3.04552C0.250461 3.03038 0.25 3.01521 0.25 3.00003C0.25 2.27068 0.539731 1.57121 1.05546 1.05549Z"
      fill={props.fill}
    />
  </svg>
)

export default PhoneOutlined
