import * as T from '../types'

const YouTubeCircle = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 45 44"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_882_2020)">
      <path
        d="M22.8291 0C34.8851 0 44.6617 9.77663 44.6617 21.8326C44.6617 33.8886 34.8851 43.6652 22.8291 43.6652C10.7732 43.6652 0.996548 33.8886 0.996548 21.8326C0.996548 9.77663 10.7732 0 22.8291 0Z"
        fill="#CCF4FF"
      />
      <g clipPath="url(#clip1_882_2020)">
        <path
          d="M35.4566 15.8094C35.3091 15.2643 35.0188 14.7672 34.615 14.3683C34.2111 13.9693 33.708 13.6825 33.1562 13.5368C31.1301 13 23 13 23 13C23 13 14.8699 13 12.8387 13.5368C12.2873 13.6833 11.7846 13.9704 11.3809 14.3692C10.9772 14.7681 10.6866 15.2647 10.5383 15.8094C10 17.8161 10 22 10 22C10 22 10 26.1839 10.5434 28.1906C10.6917 28.7353 10.9823 29.2319 11.386 29.6308C11.7897 30.0296 12.2924 30.3167 12.8438 30.4632C14.8699 31 23 31 23 31C23 31 31.1301 31 33.1613 30.4632C33.7127 30.3167 34.2154 30.0296 34.6191 29.6308C35.0228 29.2319 35.3134 28.7353 35.4617 28.1906C36 26.1839 36 22 36 22C36 22 36 17.8161 35.4566 15.8094ZM20.4152 25.8428V18.1572L27.1488 21.9699L20.4152 25.8428Z"
          fill="#333333"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_882_2020">
        <rect
          width="43.6652"
          height="43.6652"
          fill="white"
          transform="matrix(-1 0 0 1 44.6617 0)"
        />
      </clipPath>
      <clipPath id="clip1_882_2020">
        <rect
          width="26"
          height="18"
          fill="white"
          transform="translate(10 13)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default YouTubeCircle
