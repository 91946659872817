import SVG from '../SVG'
import * as T from '../types'

const TwitterCircle = (props: T.ISVGProps) => (
  <SVG
    {...props}
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 45 44"
    d="M22.1402 0C10.0842 0 0.307617 9.77663 0.307617 21.8326C0.307617 33.8886 10.0842 43.6652 22.1402 43.6652C34.1962 43.6652 43.9728 33.8886 43.9728 21.8326C43.9728 9.77663 34.1962 0 22.1402 0ZM32.1087 17.0227C32.1184 17.2376 32.1231 17.4535 32.1231 17.6704C32.1231 24.2922 27.0827 31.928 17.865 31.9284H17.8654H17.865C15.035 31.9284 12.4016 31.0989 10.1839 29.6774C10.576 29.7237 10.9751 29.7466 11.3792 29.7466C13.7271 29.7466 15.8879 28.9458 17.6032 27.6016C15.4095 27.5609 13.5599 26.1121 12.9216 24.1209C13.2271 24.1796 13.5412 24.2115 13.8634 24.2115C14.3208 24.2115 14.7639 24.1499 15.1849 24.035C12.8919 23.5759 11.1646 21.5494 11.1646 19.1228C11.1646 19.1002 11.1646 19.0799 11.1653 19.0589C11.8406 19.4343 12.6128 19.6602 13.435 19.6855C12.0894 18.7877 11.2049 17.2529 11.2049 15.5143C11.2049 14.5962 11.4531 13.736 11.8835 12.9954C14.3548 16.0277 18.0479 18.0218 22.2128 18.2314C22.1269 17.8642 22.0826 17.4818 22.0826 17.0887C22.0826 14.3223 24.3269 12.078 27.0943 12.078C28.5358 12.078 29.8377 12.6873 30.7522 13.6614C31.8939 13.4362 32.9659 13.0191 33.9343 12.4451C33.5596 13.6147 32.7654 14.5962 31.7306 15.2168C32.7444 15.0955 33.7105 14.8267 34.6083 14.4276C33.9377 15.4327 33.0872 16.3155 32.1087 17.0227Z"
    title="Twitter Logo"
    titleId="twitterCircleTitle"
    description="A white Twitter Logo in a black circle"
    descriptionId="twitterCircleDescription"
  />
)

export default TwitterCircle
