/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import useSWR, { mutate } from 'swr'

import * as T from 'types'

import { api } from '../api'
import { fetchWithToken } from '../fetchers'
import { getDataFromResponse } from './utils/dataHelpers'
import { getFetchErrorMessage, getUpdateErrorMessage } from './utils/errorMessageHelpers'

export const useContractDetails = (listingId?: string) => {
  const apiKey: string | null = listingId ? `/contract-details/listing/${listingId}/active` : null
  const response = useSWR<T.IContractDetails | T.IServerError>(apiKey, fetchWithToken)

  let contractDetails: T.IContractDetails | undefined
  if (response.data && !response.data.message) contractDetails = response.data as T.IContractDetails

  const error = getFetchErrorMessage(response, 'contract details')

  const [updateError, setUpdateError] = useState<T.IServerError>({})

  const update: T.IUpdateContractDetails = async ({ id, updates }) => {
    // In prisma/postgres, to unset a value we must explicitly set it to null
    const updatesWithNulls = Object.entries(updates).reduce((acc, [key, value]) => {
      if (!value && value !== 0) acc[key] = null
      else acc[key] = value

      return acc
    }, {} as T.IContractDetails)

    const updateResponse = await api.patchContractDetails(id, updatesWithNulls)
    const updatedContractDetails = getDataFromResponse<T.IContractDetails>(updateResponse)

    const userReadableError = 'failed to update contract details'
    const updateContractDetailsError = getUpdateErrorMessage(updateResponse, { userReadableError })
    if (error) setUpdateError(updateContractDetailsError)

    if (updatedContractDetails) await mutate(apiKey, updatedContractDetails, false)

    return [updatedContractDetails, updateContractDetailsError]
  }

  return {
    ...response,
    contractDetails,
    error, // swr fetch error
    update, // generic update function
    updateError,
  }
}
