/* eslint-disable react/no-unused-prop-types */
import Link from 'next/link'
import { FunctionComponent, MouseEvent } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import Anchor from 'components/GlobalStyles/Anchor'
import Loader from 'components/Loader'

import * as E from 'types/enums'

export const getHoverColor = (theme: DefaultTheme, color?: string) => {
  switch (color) {
    case theme.colors.blue:
      return theme.colors.blue7
    case theme.colors.purple:
      return theme.colors.purple3
    case theme.colors.yellow:
      return theme.colors.yellow3
    case theme.colors.red:
      return theme.colors.red3
    default:
      return color
  }
}

interface IHoverProps {
  hoverBackground?: string
  hoverBorderColor?: string
  hoverColor?: string
  hoverSvgFill?: string
}

interface IStyledAnchorProps {
  flexGrow?: boolean
  noFlexShrink?: boolean
  flexBasis?: number
  fullWidth?: boolean
  externalLink?: boolean
}

const StyledAnchor = styled(Anchor)<IStyledAnchorProps>`
  ${props => props.fullWidth && 'width: 100%;'}
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    ${props => props.flexBasis && `flex-basis: ${props.flexBasis}px;`}
    ${props => props.flexGrow && 'flex-grow: 1;'}
    ${props => props.noFlexShrink && 'flex-shrink: 0;'}
  }
`

/* NB: for the API to stay 100% compatible with both, make sure changes to StyledButton
 * are reflected in LinkButton and vice versa */
interface IStyledButtonProps {
  autoHeight?: boolean
  background?: string
  borderColor?: string
  borderWidth?: number
  boxShadow?: string
  color?: string
  disabled?: boolean
  loadingCursor?: boolean
  fullWidth?: boolean
  height?: number
  justifyContent?: string
  minWidth?: number
  radius?: number
  width?: number
  padding?: string
}
const StyledButton = styled.button<IStyledButtonProps & IStyledAnchorProps & IHoverProps>`
  min-width: 0; /* inside flexbox default of auto can cause overflow if text bigger than container */
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  border-radius: ${props => props.radius || 100}px;
  border: ${props => (props.borderColor ? `2px solid ${props.borderColor}` : 'none')};
  padding: ${props => props.padding || '0 15px'};
  height: ${props => props.height || 40}px;
  ${props => props.autoHeight && 'height: auto;'}
  ${props => props.background && `background: ${props.background}`};
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
  ${props => props.borderWidth && `border-width: ${props.borderWidth}px;`}
  ${props => props.minWidth && `min-width: ${props.minWidth}px;`}
  ${props => props.fullWidth && 'width: 100%;'}
  ${props => props.width && `width: ${props.width}px;`}
  ${props => props.noFlexShrink && 'flex-shrink: 0; min-width: auto;'}

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${props => props.loadingCursor && 'cursor: wait;'}

  &:hover {
    ${props =>
    props.borderColor
      && `border: ${`${props.borderWidth}px` || '2px'} solid ${getHoverColor(
        props.theme,
        props.borderColor,
      )};`}
    ${props =>
    props.hoverBorderColor
      && `border: ${`${props.borderWidth}px` || '2px'} solid ${props.hoverBorderColor};`}
    background: ${props => props.hoverBackground || getHoverColor(props.theme, props.background)};
  }
  &:hover span {
    ${props => props.hoverColor && `color: ${props.hoverColor}`};
  }
  &:hover svg path {
    ${props => props.hoverSvgFill && `fill: ${props.hoverSvgFill}`};
  }

  &:focus {
    outline: none;
  }

  transition: all 250ms ease-out;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    ${props => props.flexBasis && `flex-basis: ${props.flexBasis}px;`}
    ${props => props.flexGrow && 'flex-grow: 1;'}
    ${props => props.noFlexShrink && 'flex-shrink: 0; min-width: auto;'}
  }
`
interface IStyledSpanProps {
  bold?: boolean
  color?: string
  compactText?: boolean
  disabled?: boolean
  disabledColor?: string
  fontSize?: number
  hasLeftIcon?: boolean
  hasRightIcon?: boolean
  iconPadding?: number
  letterSpacing?: string
  lineHeight?: number
  noUppercase?: boolean
  light?: boolean
  removeFontCorrection?: boolean
  spanTextPadding?: string
}

const StyledSpan = styled.span<IStyledSpanProps>`
  padding-top: ${props => (props.compactText ? 1 : 2)}px; /* font correction */
  ${props => props.removeFontCorrection && 'padding-top: 0px;'}
  font-style: normal;
  font-size: ${props => (props.compactText ? 10 : 12)}px;
  ${props => props.fontSize && `font-size: ${props.fontSize}px;`}
  white-space: nowrap;
  font-weight: ${props => (props.bold ? 900 : 800)};
  letter-spacing: ${props => (props.compactText || props.noUppercase ? 0 : 1.5)}px;
  ${props => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
  ${props => props.lineHeight && `line-height: ${props.lineHeight}px;`}
  white-space: nowrap;
  ${props => props.color && `color: ${props.color}`};
  ${props => props.disabled && props.disabledColor && `color: ${props.disabledColor};`}
  ${props => props.hasLeftIcon && `padding-left: ${props.iconPadding || '12'}px;`}
  ${props => props.hasRightIcon && `padding-right: ${props.iconPadding || '12'}px;`}
  ${props => props.light && 'font-weight: 500;'}
  ${props => props.noUppercase && !props.fontSize && 'font-size: 14px;'}
  ${props => !props.noUppercase && 'text-transform: uppercase;'}
  ${props => props.spanTextPadding && `padding: ${props.spanTextPadding};`}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 100ms ease-out;
`

const NoFlexShrinkContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`

interface ICenterProp {
  center?: boolean
}
interface IFullWidthProp {
  fullWidth?: boolean
}
interface IPaddingProp {
  padding?: string
}
const RoundedButtonContainer = styled.div<ICenterProp & IFullWidthProp & IPaddingProp>`
  ${props => props.fullWidth && 'width: 100%;'}
  ${props =>
    props.center
    && `
    width: 100%;
    display: flex;
    justify-content: center;
  `}
  ${props => props.padding && `padding: ${props.padding};`}
`

export interface IRoundedButtonProps {
  // eslint-disable-next-line react/no-unused-prop-types
  as?: string
  autoHeight?: boolean // useful for placing inside responsive containers with fixed heights
  background?: string
  bold?: boolean
  borderColor?: string
  borderWidth?: number
  boxShadow?: string
  center?: boolean
  color?: string
  compactText?: boolean
  containerPadding?: string // useful for when the button has a large drop shadow you don't want to clip
  dataTestId?: string
  disabled?: boolean
  disabledColor?: string
  // eslint-disable-next-line react/no-unused-prop-types
  externalLink?: boolean
  flexBasis?: number
  flexGrow?: boolean
  fontSize?: number
  fullWidth?: boolean
  hoverBackground?: string
  hoverBorderColor?: string
  hoverColor?: string
  hoverSvgFill?: string
  height?: number
  // eslint-disable-next-line react/no-unused-prop-types
  href?: string | { pathname: string; query?: { [key: string]: string } }
  iconPadding?: number
  justifyContent?: E.JustifyContent
  letterSpacing?: string
  lineHeight?: number
  light?: boolean
  loading?: boolean
  loadingCursor?: boolean
  minWidth?: number
  noFlexShrink?: boolean
  noUppercase?: boolean
  padding?: string
  // eslint-disable-next-line react/no-unused-prop-types
  prefetch?: boolean
  radius?: number
  removeFontCorrection?: boolean
  spanTextPadding?: string // offset icon paddding
  text?: string
  type?: E.ButtonType
  width?: number
  onClick?(event?: MouseEvent): void
  renderLeftIcon?(): JSX.Element | null
  renderRightIcon?(): JSX.Element | null
}

export const Button = ({
  autoHeight,
  background,
  bold,
  borderColor,
  borderWidth,
  boxShadow,
  color,
  compactText,
  dataTestId,
  disabled,
  disabledColor,
  flexBasis,
  flexGrow,
  fontSize,
  fullWidth,
  height,
  hoverBackground,
  hoverBorderColor,
  hoverColor,
  hoverSvgFill,
  iconPadding,
  justifyContent,
  letterSpacing,
  lineHeight,
  light,
  loading,
  loadingCursor,
  minWidth,
  noFlexShrink,
  noUppercase,
  padding,
  radius,
  removeFontCorrection,
  spanTextPadding,
  text,
  type,
  width,
  onClick,
  renderLeftIcon,
  renderRightIcon,
}: IRoundedButtonProps) => (
  <StyledButton
    autoHeight={autoHeight}
    background={background}
    borderColor={borderColor}
    borderWidth={borderWidth}
    boxShadow={boxShadow}
    data-testid={dataTestId}
    disabled={disabled}
    height={height}
    flexBasis={flexBasis}
    flexGrow={flexGrow}
    fullWidth={fullWidth}
    hoverBackground={hoverBackground}
    hoverBorderColor={hoverBorderColor}
    hoverColor={hoverColor}
    hoverSvgFill={hoverSvgFill}
    justifyContent={justifyContent}
    loadingCursor={loadingCursor}
    minWidth={minWidth}
    noFlexShrink={noFlexShrink}
    padding={padding}
    radius={radius}
    type={type || E.ButtonType.button}
    width={width}
    onClick={onClick}
  >
    {!loading && !!renderLeftIcon && (
      <NoFlexShrinkContainer>{renderLeftIcon()}</NoFlexShrinkContainer>
    )}
    {loading && <Loader fill={color} />}
    {!loading && (
      <StyledSpan
        bold={bold}
        color={color}
        disabled={disabled}
        disabledColor={disabledColor}
        compactText={compactText}
        fontSize={fontSize}
        hasLeftIcon={!!renderLeftIcon}
        hasRightIcon={!!renderRightIcon}
        iconPadding={iconPadding}
        spanTextPadding={spanTextPadding}
        letterSpacing={letterSpacing}
        lineHeight={lineHeight}
        noUppercase={noUppercase}
        light={light}
        removeFontCorrection={removeFontCorrection}
      >
        {text || 'Click'}
      </StyledSpan>
    )}
    {!loading && renderRightIcon && renderRightIcon()}
  </StyledButton>
)

const RoundedButton: FunctionComponent<IRoundedButtonProps> = props => {
  /*
    https://github.com/vercel/next.js/issues/7915
    anchor tag is added to remove the ref warning
  */

  const dataTestId = props.dataTestId ?? props.text
  const prefetch: { [key: string]: boolean } = {}
  if (typeof props.prefetch !== 'undefined') prefetch.prefetch = props.prefetch

  if (props.href) {
    return (
      <RoundedButtonContainer
        center={props.center}
        fullWidth={props.fullWidth}
        padding={props.containerPadding}
      >
        <Link
          href={props.href || '/'}
          as={props.as}
          {...prefetch}
          passHref
        >
          <StyledAnchor
            data-testid={dataTestId}
            target={props.externalLink ? '_blank' : '_self'}
            flexGrow={props.flexGrow}
            flexBasis={props.flexBasis}
            fullWidth={props.fullWidth}
            noFlexShrink={props.noFlexShrink}
          >
            <Button
              {...props}
              dataTestId={undefined}
            />
          </StyledAnchor>
        </Link>
      </RoundedButtonContainer>
    )
  }

  return (
    <RoundedButtonContainer
      center={props.center}
      fullWidth={props.fullWidth}
      padding={props.containerPadding}
    >
      <Button
        {...props}
        dataTestId={dataTestId}
      />
    </RoundedButtonContainer>
  )
}

export default RoundedButton
