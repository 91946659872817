import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const Heading2Text = styled.h2<ITypographyProps>`
  margin: 0;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  font-size: ${props => props.theme.fonts.sizes[30]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 41px;
  flex: none;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[36]}px;
    line-height: 49px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.theme.fonts.sizes[40]}px;
    line-height: 55px;
  }
`

const Heading2: FunctionComponent<ITypographyProps> = ({ children, ...props }) => (
  <Heading2Text {...props}>{children}</Heading2Text>
)

export default Heading2
