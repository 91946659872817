import { FC, ReactElement } from 'react'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from 'themes'

import {
  ABVariationsProvider,
  EventHandlerProvider,
  GuidedTourProvider,
  MarketConditionsProvider,
  MenuModalProvider,
  MissingInternetProvider,
  OverlayProvider,
  ProductTourProvider,
  QueryParamsProvider,
  RouteHistoryProvider,
  RouteProtectionProvider,
  SavingProvider,
} from 'services/contexts'

interface IProps {
  children: ReactElement<any, any> | null
}

const Providers: FC<IProps> = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <OverlayProvider>
      <MissingInternetProvider>
        <QueryParamsProvider>
          <MenuModalProvider>
            <MarketConditionsProvider>
              <GuidedTourProvider>
                <RouteHistoryProvider>
                  <RouteProtectionProvider>
                    <EventHandlerProvider>
                      <SavingProvider>
                        <ProductTourProvider>
                          {/* DO NOT REMOVE */}
                          {/* @FIXME: Not currently used for VWO testings (our contract expired), but
                              this subtly delays rendering and useWindowSize will be undefined on first
                              render without it. All the locations in the app assume it will be
                              defined on first render of the component */}
                          {/* ABVariationsProvider needs to be closest to `children` or unexpected
                            things can happen */}
                          <ABVariationsProvider>{children}</ABVariationsProvider>
                        </ProductTourProvider>
                      </SavingProvider>
                    </EventHandlerProvider>
                  </RouteProtectionProvider>
                </RouteHistoryProvider>
              </GuidedTourProvider>
            </MarketConditionsProvider>
          </MenuModalProvider>
        </QueryParamsProvider>
      </MissingInternetProvider>
    </OverlayProvider>
  </ThemeProvider>
)

export default Providers
