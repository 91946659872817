import styled from 'styled-components'

import { ISVGLogoProps } from './types'

export const SVGLogoContainer = styled.div<ISVGLogoProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: ${props => props.width || 400}px;
    height: ${props => props.width || 58}px;
  }

  & path {
    fill: ${props => props.fill || props.theme.colors.teal500};
  }
`

const HomeListerSVGLogo = (props: ISVGLogoProps) => (
  <SVGLogoContainer {...props}>
    <svg
      width="400"
      height="98"
      viewBox="0 0 400 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M302.009 50.9048C299.144 59.8396 292.569 66.8016 283.356 70.7204L282.164 67.9196C290.631 64.3184 296.536 58.0004 299.111 49.9752L302.009 50.9048Z"
        fill="#07BFF1"
      />
      <path
        d="M290.64 24.44L277.24 26.24L277.2 26.36L276.12 35.76C279.52 59.08 279.4 54.08 279.52 57.48C279.68 63.8 275.64 65.88 271.64 65.88C267.64 65.88 266.12 63.8 266.12 59.56C266.56 60.48 267.64 60.72 268.56 60.72C270.92 60.72 272.56 58.44 272.56 56.28C272.56 55.32 271.28 53.76 270.16 52.96C270.04 52.88 269.96 52.84 269.84 52.76C269.64 52.68 269.36 52.56 268.92 52.52C268.8 52.56 268.72 52.64 268.64 52.76L268.76 52.52C268.04 52.48 267 52.64 265.56 53.32C263.32 54.68 261.28 57.56 261.28 61.44C261.28 66.96 265.28 73.04 274.48 73.04C281.92 73.04 292.6 69.16 292.6 57.4C292.64 51.4 291.72 47.88 290.64 24.44Z"
        fill="#07BFF1"
      />
      <path
        d="M266.72 56.88L264.56 54.72C269.44 49.84 274.84 42.64 277.64 26.44L280.64 26.96C277.72 44 271.96 51.64 266.72 56.88Z"
        fill="#07BFF1"
      />
      <path
        d="M268.28 61.04C270.644 61.04 272.56 59.1236 272.56 56.76C272.56 54.3964 270.644 52.48 268.28 52.48C265.916 52.48 264 54.3964 264 56.76C264 59.1236 265.916 61.04 268.28 61.04Z"
        fill="#07BFF1"
      />
      <path
        d="M12.76 73.6H26.56L33.36 42.8H48L41.2 73.6H55.56L71.44 0H57.08L50.12 31.68L35.48 31.84L42.72 0.36C42.72 0.36 34.52 0.36 33.04 0.44C17.32 1.2 0 12 0 24.16C0 30.72 3.56 33 10.08 33C10 32.16 8.24 31.4 8.76 26.8C10.32 13.52 27.52 6.88 27.52 6.88L12.76 73.6Z"
        fill="#07BFF1"
      />
      <path
        d="M225.12 70.8L242.64 1.20001H229.16L215.24 54.56C210.2 59.52 204.96 63.4 197.32 65.04C192.8 66 190 64.92 189.32 61C204.04 54.52 213.24 43.44 213.24 32.32C213.24 27.48 211.16 23.04 202.28 23.04C181.84 23.04 175.36 46.36 175.36 57.48C175.36 59.36 175.56 61 175.92 62.52C175.32 62.76 174.68 63.04 174 63.36C170.96 64.72 168.44 63.12 168.96 60C169.88 54.68 173.76 42.88 173.76 35.04C173.76 27.2 169.08 24.12 162.68 24.12C159 24.12 153.6 25.2 148.84 32.32C147.76 26.32 143.84 24 138.56 24C135.08 24 130.6 24.2 126.4 28.84L127.48 23.16H113.12L110.12 37.52C108.08 38.2 106.08 38.92 104.36 38.92C104.08 38.92 103.68 39.08 103.28 38.88C103.28 28.72 99.08 23.48 90.52 23.48C70 23.48 63.2 46.72 63.2 57.84C63.2 68.96 69.88 73.12 79.16 73.12C91.44 73.12 101.4 61.92 103 44.04C105.16 43.68 107.24 43.56 109.2 42.92L102.56 73.6H116.92L123.68 42.2C125.16 37.36 127.88 33.04 131.76 33.04C133.96 33.04 134.76 35.08 134.76 37.2C134.76 38.08 134.68 39.16 134.48 39.92L127.16 73.6H141.52L148.28 42.2C149.76 37.36 152.48 33.12 156.04 33.12C158.44 33.12 159.64 34.76 159.64 37.96C159.64 44.16 156.24 56.2 155.08 62.68C153.44 71.8 161.2 76.64 172.12 70.48C175.52 68.56 176.96 67.72 177.84 67.24C180.48 71.16 185.08 72.88 190.92 72.88C200.8 72.88 208 67.88 213.4 61.68L209.88 75.2C210.68 75.12 211.36 75.12 212.16 75.12C229.8 75.12 242.36 97.08 256.64 97.08C262.04 97.08 266 91.2 267 81.88C264.12 83.04 261.32 83.36 258.64 83.36C245.96 83.36 235.48 73 225.12 70.8ZM94.68 44.44C93.08 55.84 88 64.56 83.12 64.56C79.32 64.56 78.04 63.12 78.04 56.72C78.04 47.24 84.24 29.56 91.32 29.56C93.92 29.56 94.92 31.12 95.12 35.16C93.32 35.92 92.04 37.68 92.04 40.4C92 42.32 92.56 43.96 94.68 44.44ZM203.08 28.2C204.96 28.2 205.56 29.76 205.56 32.16C205.56 40.2 198.96 51.44 189.6 56.12C191.64 40.48 198 28.2 203.08 28.2Z"
        fill="#07BFF1"
      />
      <path
        d="M254.88 19.32C259.28 19.32 262.84 15.92 262.84 11.68C262.84 7.44 259.24 3.95999 254.88 3.95999C250.52 3.95999 247 7.44 247 11.68C247 15.92 250.48 19.32 254.88 19.32Z"
        fill="#07BFF1"
      />
      <path
        d="M235.88 61.84C235.88 69.96 240.56 73.28 246.56 73.28C251.96 73.28 261.8 71.2 267.48 55.2H263.28C261.8 60 258.2 63.88 254.32 63.88C251.52 63.88 250.24 63.12 250.24 60.4C250.24 59.52 250.32 58.08 250.64 56.84L259.52 23.2H245.16L236.68 55.04C236.2 57.36 235.88 60 235.88 61.84Z"
        fill="#07BFF1"
      />
      <path
        d="M393.28 24.48C388.64 24.48 384.96 26.92 381.56 29.84L382.8 23.2H368.88L362.56 53.76C357.56 58.68 351.84 63.2 344.28 64.84C339.76 65.8 336.92 64.64 336.24 60.76C336.52 60.64 336.8 60.64 337.08 60.52C351.68 54.52 360.2 43.28 360.2 32.4C360.2 27.56 358.12 23.12 349.24 23.12C328.8 23.12 322.32 46.44 322.32 57.56C322.32 58.36 322.36 59.16 322.44 59.88C320.36 60.56 318.48 61.2 315 62.36C312.36 63.24 310.92 63.28 310.92 60.56C310.92 59.68 311 58.64 311.32 57.4L316.92 32H322.96L325.08 23.2H318.52L321.92 10H307.36L304.16 23.2H297.64L295.52 32H302.56L297.44 55.44C296.96 57.76 296.64 59.96 296.64 61.8C296.64 69.92 309.12 71.48 314.68 69.24C318.96 67.48 321.72 66.4 323.92 65.52C326.32 70.64 331.32 72.88 337.92 72.88C348.32 72.88 355.76 67.72 361.24 61.04L358.64 73.6H372.56L380.32 36.48C382.72 34.24 384.48 32.64 387.4 32.64C390.32 32.64 387.6 38.92 393 38.92C397.76 38.92 399.96 35.2 399.96 31.8C399.96 28.12 397.64 24.48 393.28 24.48ZM350.08 28.2C351.96 28.2 352.56 29.76 352.56 32.16C352.56 40.2 346.32 51.84 336.56 55.76C338.64 40.08 345 28.2 350.08 28.2Z"
        fill="#07BFF1"
      />
    </svg>
  </SVGLogoContainer>
)

export default HomeListerSVGLogo
