import * as T from './types'

export const LoadingSpinner = (props: T.ISVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 32 32"
  >
    <circle
      cx="16"
      cy="16"
      fill="none"
      r="14"
      strokeWidth="4"
      style={{ stroke: '#E0F8FF', opacity: 1 }}
    />
    <circle
      cx="16"
      cy="16"
      fill="none"
      r="14"
      strokeWidth="4"
      style={{ stroke: '#07BFF1', strokeDasharray: 90, strokeDashoffset: 60 }}
    />
  </svg>
)

export default LoadingSpinner
