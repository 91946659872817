// font sizes

// These values come from the HomeLister design system. Do not add new values here unless they have
// also been added to the DS: https://www.figma.com/file/6zHr43vLq5VILtfkBCKGpu/%F0%9F%8E%A8-HomeLister-Design-System
export type FontSize = 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 28 | 30 | 32 | 36 | 40 | 44 | 46 | 48

const sizes: Record<FontSize, FontSize> = {
  10: 10,
  12: 12,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  22: 22,
  24: 24,
  28: 28,
  30: 30,
  32: 32,
  36: 36,
  40: 40,
  44: 44,
  46: 46,
  48: 48,
}

// font weights

// These values come from the HomeLister design system. Do not add new values here unless they have
// also been added to the DS: https://www.figma.com/file/6zHr43vLq5VILtfkBCKGpu/%F0%9F%8E%A8-HomeLister-Design-System
export type FontWeight = 300 | 500 | 800 | 900

export type FontWeightName = 'light' | 'medium' | 'heavy' | 'black'

const weights: Record<FontWeightName, FontWeight> = {
  light: 300,
  medium: 500,
  heavy: 800,
  black: 900,
}

// composed fonts object
export type IFonts = {
  sizes: { [key in FontSize]: FontSize }
  weights: { [key in FontWeightName]: FontWeight }
}

const fonts: IFonts = {
  sizes,
  weights,
}

export default fonts
