import { useRouter } from 'next/router'
import { ReactNode, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import NavigationHeader from 'modules/NavigationHeader/NavigationHeader'
import { LAST_STEP } from 'modules/ProductTour'
import ProductTourIntercomStep from 'modules/ProductTour/IntercomStep'

import { OverlayContext, ProductTourContext, QueryParamsContext } from 'services/contexts'
import { useElement, useMonitorInternetConnection, useProtectRoute } from 'services/hooks'
import usePositionIntercomLauncher from 'services/hooks/usePositionIntercomLauncher'
import usePreventScroll from 'services/hooks/usePreventScroll'
import { hideIntercomLauncher } from 'services/intercom'

import Footer from './Footer'
import MissingInternetOverlay from './MissingInternetOverlay'
import Overlay from './Overlay'

const PATHNAME = '/listing/[id]/create/homeDetails/[subStep]'
const SUBSTEP = 'basic-home-details'

interface IPageLayoutProps {
  inListingFlow: boolean
}
const PageLayout = styled.div<IPageLayoutProps>`
  position: relative;
  ${props =>
    props.inListingFlow
    && `
    max-width: ${props.theme.metrics.listingFlowPageMaxWidth}px;
    margin: 0 auto;
  `}
`

interface IMarginProps {
  noMargin?: boolean
}
const MinHeightContainer = styled.div<IMarginProps>`
  /* for IE 11 */
  margin-top: ${props => (props.noMargin ? 0 : props.theme.metrics.headerHeightPhone)}px;
  min-height: calc(70vh - ${props => props.theme.metrics.headerHeightPhone}px);

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    margin-top: ${props => (props.noMargin ? 0 : props.theme.metrics.headerHeight)}px;
    min-height: calc(70vh - ${props => props.theme.metrics.headerHeight}px);
  }
`

const Layout = ({ children }: { children: ReactNode }) => {
  const router = useRouter()

  // hide/show pages based on if they should be behind auth or not
  useProtectRoute()

  // make sure analytics query params are pressent when they need to be
  const inListingFlow
    = router.pathname.includes('/listing/') && router.pathname.includes('/create/')

  const { setParams } = useContext(QueryParamsContext)
  useEffect(() => {
    if (Object.keys(router.query).length) setParams(router.query)
  }, [router])

  // general overlay used for modals
  const { overlay, hideOverlay } = useContext(OverlayContext)
  const [overlayRef, overlayElement] = useElement<HTMLDivElement>()
  usePreventScroll(overlay, overlayElement, 'overlay')
  useEffect(hideOverlay, [router.pathname]) // auto-hide on route change

  // missing internet overlay screen
  const { missingInternet } = useMonitorInternetConnection()
  const [missingInternetOverlayRef, missingInternetOverlayElement] = useElement<HTMLDivElement>()

  const { reenableScroll } = usePreventScroll(
    missingInternet,
    missingInternetOverlayElement,
    'missingInternet',
  )

  // change intercom position based on router.pathname and mobile vs desktop
  usePositionIntercomLauncher()

  // product tour
  const { productTourActive, productTourStep, endProductTour } = useContext(ProductTourContext)
  useEffect(() => {
    if (productTourActive && productTourStep !== LAST_STEP) hideIntercomLauncher(true)
    else hideIntercomLauncher(false)
  }, [productTourActive, productTourStep])
  useEffect(() => {
    // clear the product tour on browser back click
    const { pathname, query } = router

    if (pathname !== PATHNAME && query.substep !== SUBSTEP) {
      endProductTour()
      reenableScroll()
    }
  }, [router.pathname, router.query])

  const isHomePage = router.pathname === '/'
  const isVariationPage = useMemo(() => router.pathname.split('/')[1] === 'd', [router.pathname])
  const isInListingFlow = router.pathname.split('/').includes('create')
  const isGetStartedFlowPage = router.pathname.split('/')[1] === 'get-started'
  const isOpenHousePage = router.pathname.split('/').pop() === 'open-house-form'
  const isHomesForSalePage = router.pathname.split('/').pop() === 'homes-for-sale'

  const showHeader = !isGetStartedFlowPage && !isOpenHousePage
  const showOverlay = overlay || productTourActive
  const showFooter = !isGetStartedFlowPage && !isHomesForSalePage

  return (
    <PageLayout inListingFlow={inListingFlow}>
      {showHeader && <NavigationHeader enableTransparency={isHomePage || isVariationPage} />}
      <MinHeightContainer noMargin={!showHeader || isHomePage || isVariationPage}>
        {children}
      </MinHeightContainer>
      {showOverlay && (
        <Overlay
          ref={overlayRef}
          isInListingFlow={isInListingFlow}
        />
      )}
      <MissingInternetOverlay ref={missingInternetOverlayRef} />
      <ProductTourIntercomStep />
      {showFooter && <Footer />}
    </PageLayout>
  )
}

export default Layout
