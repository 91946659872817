import { ReactNode, createContext, useReducer } from 'react'

enum actions {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}
type Action = { type: actions.OPEN_MODAL } | { type: actions.CLOSE_MODAL }

type ProviderProps = { children: ReactNode }

type State = { modalOpen: boolean }
const initialState = { modalOpen: false }

const menuModalReducer = (state: State, action: Action) => {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return { modalOpen: true }
    case actions.CLOSE_MODAL:
      return { modalOpen: false }
    default:
      throw new Error('Unhandled action type')
  }
}

interface Context {
  modalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

let value: Context = {
  modalOpen: false,
  openModal: () => {},
  closeModal: () => {},
}

const MenuModalContext = createContext<Context>(value)

const MenuModalProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(menuModalReducer, initialState)
  value = {
    modalOpen: state.modalOpen,
    openModal: () => dispatch({ type: actions.OPEN_MODAL }),
    closeModal: () => dispatch({ type: actions.CLOSE_MODAL }),
  }
  return <MenuModalContext.Provider value={value}>{children}</MenuModalContext.Provider>
}

export { MenuModalContext, MenuModalProvider }
