/* eslint-disable no-extend-native */

/* eslint-disable func-names */
import hexToRgba from 'hex-to-rgba'

declare global {
  interface String {
    alpha(alphaValue: number): string
  }
}

// This lets us write things like `props => props.theme.colors.blue500.alpha(0.5)`
String.prototype.alpha = function (alphaValue: number): string {
  // it's better to throw an error than to silently clamp to 0 or 1 because it's very easy to
  // pass a value like "75" instead of "0.75"
  if (alphaValue < 0 || alphaValue > 1) throw new Error('Alpha value must be between 0 and 1')

  return hexToRgba(this as string, alphaValue)
}

export {}
