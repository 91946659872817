import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const Heading3Text = styled.h3<ITypographyProps>`
  margin: 0;
  font-weight: ${props => props.theme.fonts.weights.black};
  font-size: ${props => props.theme.fonts.sizes[24]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 33px;
  flex: none;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[30]}px;
    line-height: 41px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.theme.fonts.sizes[32]}px;
    line-height: 44px;
  }
`

const Heading3: FunctionComponent<ITypographyProps> = ({ children, ...props }) => (
  <Heading3Text {...props}>{children}</Heading3Text>
)

export default Heading3
