import { useContext, useEffect } from 'react'

import { MenuModalContext } from 'services/contexts'

// used to menu modal if it is open when user navigates to a page from menu modal
const useCloseHeaderModal = () => {
  const { modalOpen, closeModal } = useContext(MenuModalContext)
  useEffect(() => {
    if (modalOpen) closeModal()
  }, [])
}

export default useCloseHeaderModal
