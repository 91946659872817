import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ITypographyProps } from '../utils/types'

const CallOut1Text = styled.div<ITypographyProps>`
  font-weight: ${props => props.theme.fonts.weights.black};
  font-size: ${props => props.theme.fonts.sizes[30]}px;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 41px;
  flex: none;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[36]}px;
    line-height: 49px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.theme.fonts.sizes[48]}px;
    line-height: 66px;
  }
`

const CallOut1: FunctionComponent<ITypographyProps> = ({ children, ...props }) => (
  <CallOut1Text {...props}>{children}</CallOut1Text>
)

export default CallOut1
