import SVG from './SVG'
import * as T from './types'

const DeleteX = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 12 12"
    d="M11.85 10.4401C11.9446 10.534 11.9979 10.6618 11.9979 10.7951C11.9979 10.9284 11.9446 11.0562 11.85 11.1501L11.15 11.8501C11.0561 11.9448 10.9283 11.998 10.795 11.998C10.6617 11.998 10.5339 11.9448 10.44 11.8501L5.99997 7.4101L1.55997 11.8501C1.46609 11.9448 1.33829 11.998 1.20497 11.998C1.07166 11.998 0.943858 11.9448 0.849974 11.8501L0.149974 11.1501C0.055318 11.0562 0.0020752 10.9284 0.0020752 10.7951C0.0020752 10.6618 0.055318 10.534 0.149974 10.4401L4.58997 6.0001L0.149974 1.5601C0.055318 1.46621 0.0020752 1.33842 0.0020752 1.2051C0.0020752 1.07178 0.055318 0.94398 0.149974 0.850096L0.849974 0.150096C0.943858 0.0554401 1.07166 0.00219727 1.20497 0.00219727C1.33829 0.00219727 1.46609 0.0554401 1.55997 0.150096L5.99997 4.5901L10.44 0.150096C10.5339 0.0554401 10.6617 0.00219727 10.795 0.00219727C10.9283 0.00219727 11.0561 0.0554401 11.15 0.150096L11.85 0.850096C11.9446 0.94398 11.9979 1.07178 11.9979 1.2051C11.9979 1.33842 11.9446 1.46621 11.85 1.5601L7.40997 6.0001L11.85 10.4401Z"
  />
)

export default DeleteX
