import path from 'path'

/* eslint-disable import/prefer-default-export */
export const addCommas = (value: string | number) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const removeCommas = (value: string) => value.replace(/,/g, '')

export const removeDollarSign = (value: string) => value.replace(/\$/g, '')

export const removeCurrencyFormatting = (value: string) => removeDollarSign(removeCommas(value))

export const capitalizeWord = (value: string) => {
  if (typeof value !== 'string') return ''

  return value.charAt(0).toLocaleUpperCase() + value.slice(1).toLocaleLowerCase()
}

export const sentenceCase = (value: string) => {
  if (typeof value !== 'string') return ''

  return value.charAt(0).toLocaleUpperCase() + value.slice(1).toLocaleLowerCase()
}

export const titleCase = (value: string) => value.split(' ').map(capitalizeWord).join(' ')

export const camelCase = (value: string) => {
  const joined = value.split(' ').join('')
  return joined.charAt(0).toLowerCase() + joined.slice(1)
}

export const removeHyphens = (value: string | number) => String(value).split('-').join('')

export const replaceHyphenWithSpace = (value: string) => value.split('-').join(' ')

export const removeUnderscores = (value: string) => value.replace(/_/g, '')

export const formatListingFlowSubStepFromPath = (strPath: string) => {
  const pathParts = strPath.split('/').pop() || ''
  const withoutQueryParams = pathParts.split('?')[0]
  return titleCase(replaceHyphenWithSpace(withoutQueryParams))
}

export const wrapHTTPS = (uri: string) => {
  const httpsRe = /^(http|https):\/\//
  if (!httpsRe.test(uri)) return `https://${uri}`

  return uri
}

export const removeSpaceBeforeExtension = (filename: string) => {
  const parsedFilename = path.parse(filename)
  return `${parsedFilename.name?.trim()}${parsedFilename.ext}`
}

export const stripWhitespace = (value: string) => value.split(' ').join('')
