import fetch from 'isomorphic-unfetch'
import Cookies from 'js-cookie'

import { IServerError } from 'types'

const TEXT_TYPE = 'text/html; charset=utf-8'
const JSON_TYPE = 'application/json; charset=utf-8'

const fetchWithToken = async <Data>(
  path: string,
  reqToken?: string | undefined,
): Promise<Data | IServerError> => {
  try {
    if (!path) throw new Error('Missing path')

    if (path[0] !== '/') throw new Error('Missing forward slash')

    // use request cookie in server context or grab from document if in browser context
    let token = reqToken
    if (!token && typeof window !== 'undefined') token = Cookies.get('token')

    if (!token) throw new Error('Missing token')

    const ga = Cookies.get('_ga') || ''
    const qs = typeof window !== 'undefined' ? window.location.search : ''

    const headers = {
      Authorization: `Bearer ${token}`,
      Ga: ga,
      Qs: qs,
    }

    const response = await fetch(`${process.env.API_URL}${path}`, {
      method: 'GET',
      headers,
    })

    // Some errors are returned as plaintext
    const responseType = response.headers.get('Content-Type')
    interface IError {
      error: string
    }
    let data: Data | IError | string | undefined

    if (responseType === TEXT_TYPE) data = await response.text()

    if (responseType === JSON_TYPE) data = await response.json()

    if (!data) throw new Error('Unrecognized response Content-Type header')

    // error handling
    if (!response.ok) {
      return {
        message: ((data as IError)?.error ?? response.statusText) || 'Error fetching data.',
        status: String(response.status) || '',
      }
    }

    return data as Data
  } catch (err) {
    return {
      status: 'Response Parsing Error',
      message: (err as Error).message || '',
    }
  }
}

export default fetchWithToken
