import Link from 'next/link'
import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import Anchor from 'components/GlobalStyles/Anchor'
import HorizontalRule from 'components/GlobalStyles/Hr'
import LogoutIcon from 'components/SVG/LogoutOutlined'
import ProfileIcon from 'components/SVG/ProfileOutlined'
import Spacer from 'components/Spacer'

import { useUser } from 'services/swr/useUser'

import DropDown from './DropDown'
import { ContentContainer, IconContainer, ItemRow, TitleText } from './Shared'

const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  padding: 6px 12px;
  width: 100%;
  color: ${props => props.theme.colors.grey23};
  white-space: nowrap;
`
interface IProps {
  disabled?: boolean
  inDropDown?: boolean
  logout(): void
  profileOnly?: boolean
  logoutOnly?: boolean
  transparent?: boolean
}

const MyAccount: FunctionComponent<IProps> = ({
  disabled,
  inDropDown,
  logout,
  profileOnly,
  logoutOnly,
  transparent,
}) => {
  const theme = useContext(ThemeContext)
  const { user } = useUser()

  const renderProfileLink = () => (
    <Link
      href="/account"
      prefetch={false}
      passHref
    >
      <Anchor>
        <ItemRow>
          <IconContainer>
            <ProfileIcon
              width={20}
              height={18}
              fill={theme.colors.yellow4}
            />
          </IconContainer>
          <TitleText>Profile</TitleText>
        </ItemRow>
      </Anchor>
    </Link>
  )

  const renderLogoutLink = () => (
    <ItemRow onClick={logout}>
      <IconContainer>
        <LogoutIcon
          width={20}
          height={18}
          fill={theme.colors.yellow4}
        />
      </IconContainer>
      <TitleText>Logout</TitleText>
    </ItemRow>
  )

  const renderUser = (name: string) => (
    <ContentContainer inDropDown>
      <Text>{`Hello ${name}`}</Text>
      <HorizontalRule />
      <Spacer size={10} />
      {renderProfileLink()}
      <Spacer size={10} />
      <HorizontalRule />
      <Spacer size={10} />
      {renderLogoutLink()}
    </ContentContainer>
  )

  if (!user) return null

  if (profileOnly) return renderProfileLink()

  if (logoutOnly) return renderLogoutLink()

  if (!inDropDown) return renderUser(user.name)

  return (
    <DropDown
      text="MY ACCOUNT"
      textColor={transparent ? theme.colors.white : theme.colors.blue4}
      marginCorrection={-42}
      disabled={disabled}
    >
      {renderUser(user.name)}
    </DropDown>
  )
}

export default MyAccount
