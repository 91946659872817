import { ReactNode, createContext, useReducer } from 'react'

enum actions {
  START_TOUR = 'START_TOUR',
  ADVANCE_TOUR = 'ADVANCE_TOUR',
  REWIND_TOUR = 'REWIND_TOUR',
  END_TOUR = 'END_TOUR',
}
type Action =
  | { type: actions.START_TOUR }
  | { type: actions.END_TOUR }
  | { type: actions.ADVANCE_TOUR }
  | { type: actions.REWIND_TOUR }

type ProviderProps = { children: ReactNode }

type State = { productTourActive: boolean; productTourStep: number }
const initialState = { productTourActive: false, productTourStep: 0 }

const productTourReducer = (state: State, action: Action) => {
  switch (action.type) {
    case actions.START_TOUR:
      return { ...state, productTourActive: true }
    case actions.ADVANCE_TOUR:
      return { ...state, productTourStep: state.productTourStep + 1 }
    case actions.REWIND_TOUR:
      return { ...state, productTourStep: state.productTourStep - 1 }
    case actions.END_TOUR:
      return { ...state, productTourActive: false, productTourStep: 0 }
    default:
      throw new Error('Unhandled action type')
  }
}

interface Context {
  productTourActive: boolean
  productTourStep: number
  startProductTour: () => void
  advanceProductTour: () => void
  rewindProductTour: () => void
  endProductTour: () => void
}

let value: Context = {
  productTourActive: false,
  productTourStep: 0,
  startProductTour: () => {},
  advanceProductTour: () => {},
  rewindProductTour: () => {},
  endProductTour: () => {},
}

const ProductTourContext = createContext<Context>(value)

const ProductTourProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(productTourReducer, initialState)
  value = {
    productTourActive: state.productTourActive,
    productTourStep: state.productTourStep,
    startProductTour: () => dispatch({ type: actions.START_TOUR }),
    advanceProductTour: () => dispatch({ type: actions.ADVANCE_TOUR }),
    rewindProductTour: () => dispatch({ type: actions.REWIND_TOUR }),
    endProductTour: () => dispatch({ type: actions.END_TOUR }),
  }
  return <ProductTourContext.Provider value={value}>{children}</ProductTourContext.Provider>
}

export { ProductTourContext, ProductTourProvider }
