import { ReactNode, createContext, useReducer } from 'react'

import * as T from 'types'
import { BreadcrumbEnum } from 'types/enums'

enum actions {
  PUSH = 'PUSH',
  BACK_TO = 'BACK_TO',
  SET_ROOT = 'SET_ROOT',
  POP = 'POP',
  RESET = 'RESET',
}
type Action =
  | { type: actions.PUSH; path: T.IRoutePath; name: BreadcrumbEnum | undefined }
  | { type: actions.BACK_TO; index: number; name: BreadcrumbEnum }
  | { type: actions.SET_ROOT; path: T.IRoutePath }
  | { type: actions.POP }
  | { type: actions.RESET; name: BreadcrumbEnum }

type State = { paths: T.IRoutePath[]; index: number; name: BreadcrumbEnum | undefined }

type ProviderProps = { children: ReactNode }

const initialState = {
  // set default route path as home
  paths: [{ name: 'Home', pathname: '/', asPath: '/' }] as T.IRoutePath[],
  index: 0,
  name: undefined,
}

const routeHistoryReducer = (state: State, action: Action) => {
  let paths = []

  switch (action.type) {
    case actions.PUSH:
      return {
        ...state,
        paths: [...state.paths, action.path],
        index: state.index + 1,
        name: action.name,
      }
    case actions.BACK_TO:
      paths = [...state.paths].slice(0, action.index + 1)
      return {
        paths,
        index: action.index,
        // when user navigates to root (index 0) reset breadcrumb by declaring name to be undefined.
        name: action.index === 0 ? undefined : action.name,
      }
    case actions.SET_ROOT:
      paths.push(action.path)
      return { paths, index: 0, name: undefined }
    case actions.POP:
      if (state.paths.length === 0) return initialState

      paths = [...state.paths]
      paths.pop()
      return { ...state, paths, index: paths.length - 1 }
    case actions.RESET:
      paths = [state.paths[0]]
      return { paths, index: 0, name: action.name }
    default:
      return state
  }
}

interface Context {
  paths: T.IRoutePath[]
  name: BreadcrumbEnum | undefined
  push: (path: T.IRoutePath, name: BreadcrumbEnum | undefined) => void
  backTo: (index: number, name: BreadcrumbEnum) => void
  setRoot: (path: T.IRoutePath) => void
  popLast: () => void
  reset: (name: BreadcrumbEnum) => void
}

let value: Context = {
  paths: initialState.paths,
  name: initialState.name,
  push: () => {},
  backTo: () => {},
  setRoot: () => {},
  popLast: () => {},
  reset: () => {},
}

const RouteHistoryContext = createContext<Context>(value)

const RouteHistoryProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(routeHistoryReducer, initialState)
  value = {
    paths: state.paths,
    name: state.name,
    push: (path: T.IRoutePath, name: BreadcrumbEnum | undefined) => {
      dispatch({ type: actions.PUSH, path, name })
    },
    backTo: (index: number, name: BreadcrumbEnum) => {
      dispatch({ type: actions.BACK_TO, index, name })
    },
    setRoot: (path: T.IRoutePath) => {
      dispatch({ type: actions.SET_ROOT, path })
    },
    popLast: () => {
      dispatch({ type: actions.POP })
    },
    reset: (name: BreadcrumbEnum) => {
      dispatch({ type: actions.RESET, name })
    },
  }
  return <RouteHistoryContext.Provider value={value}>{children}</RouteHistoryContext.Provider>
}

export { RouteHistoryContext, RouteHistoryProvider }
