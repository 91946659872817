import { FunctionComponent } from 'react'
import styled from 'styled-components'

import Spacer from './Spacer'

interface INoMarginProps {
  noMargin?: boolean
}
interface INoPaddingProps {
  noPadding?: boolean
}
interface INoDisplayProps {
  hideMobile?: boolean
}
interface IWidthProps {
  fullWidth?: boolean
}

const Container = styled.div<INoMarginProps & INoPaddingProps & INoDisplayProps & IWidthProps>`
  padding: ${props => (props.noMargin ? 0 : 100)}px ${props => (props.noPadding ? 0 : 15)}px 0;
  ${props => props.fullWidth && 'width: 100%;'}

  @media (max-width: ${props => props.theme.metrics.phone}px) {
    ${props => props.hideMobile && 'display: none;'}
  }
`

// H1
interface IHasSubtitleProps {
  hasSubtitle: boolean
}
interface ITitleProps {
  boldMobile?: boolean
  bold?: boolean
  titleSize?: number
  titleTabletSize?: number
  titleDesktopSize?: number
  titleWeight?: number
  titleLineHeight?: number
}
interface ITextAlignProps {
  textAlign?: string
  textAlignTablet?: string
  textAlignDesktop?: string
}
interface ITextColorProps {
  textColor?: string
}
const StyledH1 = styled.h1<IHasSubtitleProps & ITextAlignProps & ITitleProps & ITextColorProps>`
  margin: 0;
  font-weight: ${props => props.titleWeight || 300};
  font-size: ${props => props.titleSize || 24}px;
  line-height: ${props => props.titleLineHeight || 34}px;
  text-align: ${props => props.textAlign || 'center'};
  color: ${props => props.theme.colors[props.textColor || 'black']};

  ${props =>
    props.boldMobile
    && `
    font-weight: 900;
    font-size: 23px;
    line-height: ${props.titleLineHeight || 33}px;
  `}

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-weight: ${props => (props.bold ? 900 : props.titleWeight || 300)};
    font-size: ${props => props.titleTabletSize || props.titleSize || 42}px;
    line-height: ${props => props.titleLineHeight || 50}px;
    ${props => props.hasSubtitle && 'margin-bottom: 10px;'}
    ${props => props.textAlignTablet && `text-align: ${props.textAlignTablet}`}
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.titleDesktopSize || props.titleSize || 42}px;
    line-height: 58px;
    ${props => props.textAlignDesktop && `text-align: ${props.textAlignDesktop}`}
  }
`

const StyledH2 = styled(StyledH1).attrs({ as: 'h2' })``

const StyledH3 = styled(StyledH1).attrs({ as: 'h3' })``

interface ISubtitleProps {
  subtitleSize?: number
  subtitleTabletSize?: number
  subtitleDesktopSize?: number
  subtitleWeight?: number
}
const Subtitle = styled.div<ISubtitleProps & ITextAlignProps & ITextColorProps>`
  font-style: normal;
  font-weight: ${props => props.subtitleWeight || 'normal'};
  font-size: ${props => props.subtitleSize || 16}px;
  line-height: 24px;
  text-align: ${props => props.textAlign || 'center'};
  color: ${props => props.theme.colors[props.textColor || 'black']};

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.subtitleTabletSize || 18}px;
    line-height: 28px;
    ${props => props.textAlignTablet && `text-align: ${props.textAlignTablet}`}
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.subtitleDesktopSize || 22}px;
    ${props => props.textAlignDesktop && `text-align: ${props.textAlignDesktop}`}
  }
`

interface IMaxWidthProps {
  maxWidth?: number
}
const MaxWidthContainer = styled.div<IMaxWidthProps>`
  margin: 0 auto;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'auto')};
`

interface IProps {
  bold?: boolean
  boldMobile?: boolean
  fullWidth?: boolean
  headerTwo?: boolean
  headerThree?: boolean
  hideMobile?: boolean
  maxWidth?: number
  noMargin?: boolean
  noPadding?: boolean
  textAlign?: string
  textAlignTablet?: string
  textAlignDesktop?: string
  textColor?: string
  title: string
  titleSize?: number
  titleTabletSize?: number
  titleDesktopSize?: number
  titleWeight?: number
  titleLineHeight?: number
  subtitle?: string
  subtitleMaxWidth?: number
  subtitleWeight?: number
}

const ExtraLargeHeader: FunctionComponent<IProps> = ({
  bold,
  boldMobile,
  fullWidth,
  headerTwo,
  headerThree,
  hideMobile,
  maxWidth,
  noMargin,
  noPadding,
  textAlign,
  textAlignTablet,
  textAlignDesktop,
  textColor,
  title,
  titleSize,
  titleTabletSize,
  titleDesktopSize,
  titleWeight,
  titleLineHeight,
  subtitle,
  subtitleWeight,
  subtitleMaxWidth,
}) => {
  // Title can be H1, H2, or H3 for SEO reasons (they all have the same styles).
  let Title = StyledH1
  if (headerTwo) Title = StyledH2

  if (headerThree) Title = StyledH3

  return (
    <Container
      noMargin={noMargin}
      noPadding={noPadding}
      hideMobile={hideMobile}
      fullWidth={fullWidth}
    >
      <MaxWidthContainer maxWidth={maxWidth}>
        <Title
          bold={bold}
          boldMobile={boldMobile}
          hasSubtitle={!!subtitle}
          textAlign={textAlign}
          textAlignTablet={textAlignTablet}
          textAlignDesktop={textAlignDesktop}
          textColor={textColor}
          titleSize={titleSize}
          titleTabletSize={titleTabletSize}
          titleDesktopSize={titleDesktopSize}
          titleWeight={titleWeight}
          titleLineHeight={titleLineHeight}
        >
          {title}
        </Title>
      </MaxWidthContainer>
      {subtitle && (
        <Spacer
          size={5}
          responsiveSize={10}
        />
      )}
      <MaxWidthContainer maxWidth={subtitleMaxWidth}>
        {subtitle && (
          <Subtitle
            textAlign={textAlign}
            textAlignTablet={textAlignTablet}
            textAlignDesktop={textAlignDesktop}
            textColor={textColor}
            subtitleWeight={subtitleWeight}
          >
            {subtitle}
          </Subtitle>
        )}
      </MaxWidthContainer>
    </Container>
  )
}

export default ExtraLargeHeader
