import isoFetch from 'isomorphic-unfetch'
import Cookies from 'js-cookie'

const getResponse = (path: string) => {
  if (!path) throw new Error('Missing path')

  if (path[0] !== '/') throw new Error('Missing forward slash')

  const ga = Cookies.get('_ga') || ''
  const qs = typeof window !== 'undefined' ? window.location.search : ''

  const headers = {
    Ga: ga,
    Qs: qs,
  }

  return isoFetch(`${process.env.API_URL}${path}`, {
    method: 'GET',
    headers,
  })
}

export default getResponse
