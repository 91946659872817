import { useContext, useEffect, useState } from 'react'

import { OverlayContext } from 'services/contexts'

const useListingFlowDropdown = (shouldUseOverlay = true) => {
  const [progressOpen, setProgressOpen] = useState(false)

  const { overlay, hideOverlay, showOverlay } = useContext(OverlayContext)

  useEffect(() => {
    // this will hide overlay used for responsive view progress modal after navigation
    if (overlay) hideOverlay()
  }, [])

  // PROGRESS
  const toggleProgress = () => {
    if (progressOpen) hideOverlay()
    else if (shouldUseOverlay) showOverlay()

    setProgressOpen(!progressOpen)
  }

  return {
    progressOpen,
    toggleProgress,
  }
}

export default useListingFlowDropdown
