import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext, css, keyframes } from 'styled-components'

import LoadingSpinner from 'components/SVG/LoadingSpinner'

const rotateKeyframes = keyframes`
  100% { transform: rotate(360deg); }
`
interface ISizeProps {
  height: number
  width: number
}
interface ISpinProps {
  spin?: boolean
}

const Container = styled.div``

const IconContainer = styled.div<ISizeProps & ISpinProps>`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.spin
    && css`
      animation: ${rotateKeyframes} 3s 0s linear infinite;
    `}
`

interface IProps {
  fill?: string
  height?: number
  width?: number
  spin?: boolean
}

const Loader: FunctionComponent<IProps> = ({ fill, height, width, spin = true }) => {
  const theme = useContext(ThemeContext)

  return (
    <Container>
      <IconContainer
        height={height || 30}
        width={width || 30}
        spin={spin}
      >
        <LoadingSpinner
          width={width || 30}
          height={height || 30}
          fill={fill || theme.colors.purple}
        />
      </IconContainer>
    </Container>
  )
}

export default Loader
