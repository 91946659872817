import cookie from 'cookie'
import { NextPageContext } from 'next'

const getToken = (ctx: NextPageContext) => {
  const reqCookie = ctx.req ? ctx.req.headers.cookie : undefined
  const { token } = reqCookie ? cookie.parse(reqCookie) : { token: undefined }
  return token
}

export default getToken
