import { ReactNode, createContext, useReducer } from 'react'

enum actions {
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED',
  SET_LOADING = 'SET_LOADING',
}
type Action = { type: actions.SET_AUTHENTICATED } | { type: actions.SET_UNAUTHENTICATED }

type State = { authenticated: boolean }
const initialState = { authenticated: false }

type ProviderProps = { children: ReactNode }

function routeProtectionReducer(state: State, action: Action) {
  switch (action.type) {
    case actions.SET_AUTHENTICATED:
      return { authenticated: true }
    case actions.SET_UNAUTHENTICATED:
      return { authenticated: false }
    default:
      throw new Error('Unhandled action type')
  }
}

interface IContext {
  authenticated: boolean
  setAuthenticated: () => void
  setUnauthenticated: () => void
}

let value: IContext = {
  authenticated: false,
  setAuthenticated: () => {},
  setUnauthenticated: () => {},
}

const RouteProtectionContext = createContext<IContext>(value)

const RouteProtectionProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(routeProtectionReducer, initialState)
  value = {
    authenticated: state.authenticated,
    setAuthenticated: () => dispatch({ type: actions.SET_AUTHENTICATED }),
    setUnauthenticated: () => dispatch({ type: actions.SET_UNAUTHENTICATED }),
  }

  return <RouteProtectionContext.Provider value={value}>{children}</RouteProtectionContext.Provider>
}

export { RouteProtectionContext, RouteProtectionProvider }
