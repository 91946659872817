import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

interface IScrollLocks {
  [key: string]: boolean
}
const initialScrollLocks: IScrollLocks = {
  modal: false,
  missingInternet: false,
  productTour: false,
  progressContent: false,
  headerMenu: false,
}

const usePreventScroll = (
  shouldPrevent: boolean,
  targetElement: HTMLDivElement | null,
  key: keyof IScrollLocks,
) => {
  const [scrollLocks, setScrollLocks] = useState(initialScrollLocks)

  const preventScroll = () => {
    if (targetElement) {
      disableBodyScroll(targetElement)
      setScrollLocks({ ...scrollLocks, [key]: true })
    }
  }

  const enableScroll = () => {
    if (targetElement) {
      enableBodyScroll(targetElement)
      setScrollLocks({ ...scrollLocks, [key]: false })
    }
  }

  useEffect(() => {
    if (!targetElement) return

    if (!scrollLocks[key] && shouldPrevent) preventScroll()
    else if (scrollLocks[key] && !shouldPrevent) enableScroll()
  }, [shouldPrevent, targetElement])

  // clear scroll locks whenever the page changes (e.g. when user clicks back button)
  const router = useRouter()
  useEffect(() => {
    clearAllBodyScrollLocks()
    setScrollLocks(initialScrollLocks)
  }, [router.asPath])

  /**
   * IMPORTANT: you must sometimes manually reenble scroll. E.g.:
   * - if your targetElement contains links or code that navigates to another page, the component
   *   that calls this hook will unmount and the scroll will not be reenabled
   * - if you have a modal whose visibility changes, the targetElement is the modal and the modal
   *   will unmount before the scroll is reenabled
   * To fix, call `reenableScroll` in navigation or modal closing functions before navigating/closing
   */
  const reenableScroll = () => {
    if (targetElement && enableBodyScroll) enableBodyScroll(targetElement)
  }

  return { reenableScroll }
}

export default usePreventScroll
