import * as T from './types'

const ChatBubbleOutlined = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.9991 32.6667L41.9993 25.6667H25.6666C25.0478 25.6667 24.4543 25.4208 24.0168 24.9833C23.5792 24.5457 23.3334 23.9522 23.3334 23.3333V9.33333C23.3334 8.7145 23.5792 8.121 24.0168 7.68342C24.4543 7.24583 25.0478 7 25.6666 7H46.6658C47.2846 7 47.8781 7.24583 48.3157 7.68342C48.7532 8.121 48.9991 8.7145 48.9991 9.33333V32.6667Z"
      stroke={props.fill}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="strokeAsFill"
    />
    <path
      d="M32.6657 35V39.6667C32.6657 40.2855 32.4199 40.879 31.9823 41.3166C31.5447 41.7542 30.9513 42 30.3324 42H13.9997L7 49V25.6667C7 25.0479 7.24582 24.4544 7.68339 24.0168C8.12096 23.5792 8.71443 23.3334 9.33324 23.3334H13.9997"
      stroke={props.fill}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="strokeAsFill"
    />
  </svg>
)

export default ChatBubbleOutlined
