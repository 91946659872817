import { DefaultTheme } from 'styled-components'

import colors from './colors'
import fonts from './fonts'
import metrics from './metrics'
import radii from './radii'
import shadows from './shadows'
import spacings from './spacings'
import zIndices from './zIndices'

// export interface ThemeInterface {
const defaultTheme: DefaultTheme = {
  colors,
  radii,
  fonts,
  metrics,
  spacings,
  shadows,
  zIndices,
}

export { defaultTheme }
