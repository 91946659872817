import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { getComponent } from '../utils'
import { ITypographyProps } from '../utils/types'

const Subheading3Text = styled.div<ITypographyProps>`
  font-weight: ${props => props.theme.fonts.weights.black};
  font-size: ${props => props.theme.fonts.sizes[14]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 19px;
  flex: none;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[16]}px;
    line-height: 22px;
  }
`

const Subheading3: FunctionComponent<ITypographyProps> = ({ children, ...props }) => {
  const Component = getComponent(Subheading3Text, props.tag)
  return <Component {...props}>{children}</Component>
}

export default Subheading3
