import { FunctionComponent, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import Popover from 'components/Popover'
import { DownFilledArrow } from 'components/SVG/filledArrows'

interface IOnHover {
  hover?: boolean
}
interface IDisabledProps {
  disabled?: boolean
}
const TextContainer = styled.div<IDisabledProps>`
  display: flex;
  align-items: center;
  ${props => props.disabled && 'cursor: wait;'}
  height: 40px;
`

interface ITextColor {
  color?: string
}
const StyledText = styled.div<IOnHover & ITextColor>`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => (props.color ? props.color : props.theme.colors.grey23)};
  margin-top: 2px; /* to accomodate border */
  border-bottom: 2px solid ${props => props.theme.colors.transparent};
  transition: color 150ms ease, border-bottom 150ms ease;

  ${props =>
    props.hover
    && `
    color: ${props.theme.colors.blue};
    border-bottom: 2px solid ${props.theme.colors.blue};
  `}
`

const IconContainer = styled.div`
  width: 16px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

// 'one or the other' types
interface ITextProps {
  disabled?: boolean
  marginCorrection?: number
  text: string
  textColor: string
  tipRight?: boolean
  width?: number
  customRenderFn?: never
}

interface ICustomRenderProps {
  disabled?: boolean
  marginCorrection?: number
  text?: never
  textColor?: string
  tipRight?: boolean
  width?: number
  customRenderFn(): JSX.Element
}

type IProps = ITextProps | ICustomRenderProps

const DropDown: FunctionComponent<IProps> = ({
  disabled,
  children,
  customRenderFn,
  marginCorrection,
  text,
  textColor,
  width,
}) => {
  const theme = useContext(ThemeContext)
  const [onHoverState, setOnHoverState] = useState(false)

  const handleMouseOver = () => setOnHoverState(true)
  const handleMouseLeave = () => setOnHoverState(false)

  const textRenderFn = () => (
    <TextContainer
      disabled={disabled}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <StyledText
        color={textColor}
        hover={onHoverState}
      >
        {text}
      </StyledText>
      <IconContainer>
        <DownFilledArrow
          width={10}
          height={6}
          fill={onHoverState ? theme.colors.blue : textColor || theme.colors.grey23}
        />
      </IconContainer>
    </TextContainer>
  )

  // don't show the dropdown if disabled
  if (disabled && customRenderFn) return customRenderFn()

  if (disabled) return textRenderFn()

  return (
    <Popover
      renderFn={customRenderFn || textRenderFn}
      width={width}
      marginRight={marginCorrection}
    >
      {children}
    </Popover>
  )
}

export default DropDown
