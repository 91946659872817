import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { getComponent } from '../utils'
import { ITypographyProps } from '../utils/types'

const Subheading1Text = styled.div<ITypographyProps>`
  font-weight: ${props => props.theme.fonts.weights.medium};
  font-size: ${props => props.theme.fonts.sizes[16]}px;
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  line-height: 22px;
  flex: none;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: ${props => props.theme.fonts.sizes[18]}px;
    line-height: 25px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: ${props => props.theme.fonts.sizes[20]}px;
    line-height: 27px;
  }
`

const Subheading1: FunctionComponent<ITypographyProps> = ({ children, ...props }) => {
  const Component = getComponent(Subheading1Text, props.tag)
  return (
    <Component
      className="subheading1"
      {...props}
    >
      {children}
    </Component>
  )
}

export default Subheading1
