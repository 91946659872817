export type Metric =
  | 'maxPhotoFileSizeMB'
  | 'maxWidth'
  | 'smallPhone'
  | 'phone'
  | 'tablet'
  | 'desktop'
  | 'desktopMedium'
  | 'desktopLarge'
  | 'listingFlowPageMaxWidth'
  | 'contentMaxWidth'
  | 'listingContentAreaMaxWidth'
  | 'maxTextAreaCharacters'
  | 'maxGridWidth'
  | 'footerArticleMaxWidth'
  | 'sidebarWidth'
  | 'dashboardSidebarWidth'
  | 'headerHeight'
  | 'headerHeightTablet'
  | 'headerHeightPhone'
  | 'progressBannerHeight'
  | 'progressBannerHeightPhone'
  | 'modalFooterHeight'
  | 'gutterPhone'
  | 'listingFlowGutter'
  | 'listingFlowGutterPhone'
  | 'listingFlowGutterTablet'
  | 'listingFlowFooterHeight'
  | 'listingFlowFooterHeightPhone'
  | 'publicListingMaxWidth'
  | 'publicListingSidebarWidth'
  | 'publicListingSidebarTabletWidth'
  | 'publicListingSpacer'
  | 'publicListingGutter'
  | 'publicListingGutterPhone'
  | 'photoRatio'
  | 'listingFlowSidebarWidth'
  | 'listingFlowInfoBannerHeight'
  | 'listingFlowInfoBannerHeightPhone'
  | 'listingFlowInfoBannerDesktopLarge'
  | 'defaultModalOverlayOpacity'

export type IMetrics = {
  [key in Metric]: number
}

// Used via Metrics.baseMargin
const metrics: IMetrics = {
  // breakpoints
  maxWidth: 2880,
  smallPhone: 375,
  phone: 415,
  tablet: 768,
  desktop: 1024,
  desktopMedium: 1280,
  desktopLarge: 1440,
  // etc
  listingFlowPageMaxWidth: 1920,
  contentMaxWidth: 1140,
  listingContentAreaMaxWidth: 1080,
  maxTextAreaCharacters: 1000,
  maxGridWidth: 1140,
  footerArticleMaxWidth: 1200,
  sidebarWidth: 385,
  dashboardSidebarWidth: 200,
  headerHeight: 70,
  headerHeightPhone: 70,
  headerHeightTablet: 70,
  progressBannerHeight: 40,
  progressBannerHeightPhone: 40,
  modalFooterHeight: 72,
  gutterPhone: 15,
  listingFlowGutter: 48,
  listingFlowGutterPhone: 20,
  listingFlowGutterTablet: 24,
  listingFlowFooterHeight: 68,
  listingFlowFooterHeightPhone: 127,
  publicListingMaxWidth: 1280,
  publicListingSidebarWidth: 325,
  publicListingSidebarTabletWidth: 223,
  publicListingSpacer: 15,
  publicListingGutter: 30,
  publicListingGutterPhone: 15,
  // this is the ratio used for photos.  The value is suspected to come from a figma which is why it's
  // so precise.
  photoRatio: 62.89308176,
  maxPhotoFileSizeMB: 20,

  // A/B testing
  listingFlowSidebarWidth: 260,
  listingFlowInfoBannerHeightPhone: 104,
  listingFlowInfoBannerHeight: 102,
  listingFlowInfoBannerDesktopLarge: 62,

  // overlay opacity
  defaultModalOverlayOpacity: 0.8,
}

export default metrics
