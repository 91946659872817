// These values come from the HomeLister design system. Do not add new values here unless they have
// also been added to the DS: https://www.figma.com/file/6zHr43vLq5VILtfkBCKGpu/%F0%9F%8E%A8-HomeLister-Design-System
export type Spacing = 4 | 8 | 12 | 16 | 24 | 32 | 36 | 40 | 48 | 56 | 64 | 72 | 80 | 120

const spacings: Record<Spacing, Spacing> = {
  4: 4,
  8: 8,
  12: 12,
  16: 16,
  24: 24,
  32: 32,
  36: 36,
  40: 40,
  48: 48,
  56: 56,
  64: 64,
  72: 72,
  80: 80,
  120: 120,
}

export type ISpacing = {
  [key in Spacing]: Spacing
}

export default spacings
