import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import CloseIcon from 'components/SVG/DeleteX'

interface ICloseButtonProps {
  defaultPadding?: boolean
  positionFixed?: boolean
  closeButtonPadding?: string
  closeButtonHideMobile?: boolean
  closeButtonHideTablet?: boolean
  closeButtonTopPosition?: number
  closeButtonRightPosition?: number
  closeTextFontSize?: string
  closeTextUppercase?: boolean
  closeTextFontWeight?: number
  closeTextLineHeight?: string
}
const CloseContainer = styled.div<ICloseButtonProps>`
  position: ${props => (props.positionFixed ? 'absolute' : 'relative')};
  ${props => props.closeButtonTopPosition && `top: ${props.closeButtonTopPosition}px`};
  ${props => props.closeButtonRightPosition && `right: ${props.closeButtonRightPosition}px`};
  display: ${props => (props.closeButtonHideMobile ? 'none' : 'flex')};
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  ${props => props.closeButtonPadding && props.closeButtonPadding};
  ${props => props.defaultPadding && 'padding: 10px 10px 0 0;'}

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: ${props => (props.closeButtonHideTablet ? 'none' : 'flex')};
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: flex;
  }
`

interface IColorProps {
  color?: string
}
const Circle = styled.div<IColorProps>`
  height: 28px;
  width: 28px;
  border-radius: 26px;
  background: ${props => (props.color ? props.color : props.theme.colors.grey15)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseText = styled.div<ICloseButtonProps>`
  padding-top: 2px; /* font correction */
  font-style: normal;
  font-weight: ${props => (props.closeTextFontWeight ? props.closeTextFontWeight : '800')};
  font-size: ${props => (props.closeTextFontSize ? props.closeTextFontSize : '16px')};
  ${props => props.closeTextUppercase && 'text-transform: uppercase'};
  line-height: ${props => (props.closeTextLineHeight ? props.closeTextLineHeight : '20px')};
  color: ${props => props.theme.colors.black};
`

export interface ICloseProps {
  closeButtonHideMobile?: boolean
  closeButtonHideTablet?: boolean
  closeButtonColor?: string
  closeButtonFixed?: boolean // ignores padding and sets close button position on top of the modal content
  closeButtonPadding?: string
  closeButtonTopPosition?: number
  closeButtonRightPosition?: number
  closeText?: boolean
  closeTextUpperCase?: boolean
  closeTextFontWeight?: number
  closeTextLineHeight?: string
  closeTextFontSize?: string
  defaultPadding?: boolean
  onClick?: () => void
}

const CloseButton: FunctionComponent<ICloseProps> = ({
  closeButtonHideMobile,
  closeButtonHideTablet,
  closeButtonColor,
  closeButtonFixed,
  closeButtonPadding,
  closeButtonTopPosition,
  closeButtonRightPosition,
  closeText,
  closeTextFontSize,
  closeTextLineHeight,
  closeTextUpperCase,
  closeTextFontWeight,
  defaultPadding,
  onClick,
}) => {
  const theme = useContext(ThemeContext)
  return (
    <CloseContainer
      closeButtonHideMobile={closeButtonHideMobile}
      closeButtonHideTablet={closeButtonHideTablet}
      closeButtonRightPosition={closeButtonRightPosition}
      closeButtonTopPosition={closeButtonTopPosition}
      closeButtonPadding={closeButtonPadding}
      onClick={onClick}
      defaultPadding={defaultPadding}
      positionFixed={closeButtonFixed}
    >
      <Circle color={closeButtonColor}>
        <CloseIcon
          width={12}
          height={12}
          fill={theme.colors.black}
        />
      </Circle>
      {closeText && (
        <CloseText
          closeTextFontSize={closeTextFontSize}
          closeTextLineHeight={closeTextLineHeight}
          closeTextFontWeight={closeTextFontWeight}
          closeTextUppercase={closeTextUpperCase}
        >
          Close
        </CloseText>
      )}
    </CloseContainer>
  )
}

export default CloseButton
