import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import intercomSettings, {
  INTERCOM_DEFAULT_HORIZONTAL_PADDING,
  INTERCOM_DEFAULT_VERTICAL_PADDING,
  INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING,
  INTERCOM_LISTING_FLOW_VERTICAL_PADDING,
  INTERCOM_LISTING_FLOW_VERTICAL_PADDING_PHONE,
} from 'themes/intercom'

import { ProductTourContext } from 'services/contexts'

import useWindowSize from './useWindowSize'

const CLASS_NAME = 'intercom-lightweight-app-launcher'
const CLASS_NAME_MESSENGER = 'intercom-launcher-frame'
const LISTING_FLOW_PATH = 'listing/[id]/create'
const MAX_TRIES = 10

let checkerInterval: NodeJS.Timer | undefined

/**
 * @FIXME, the earlier version of this code that relied on intercomSettings changes did not work
 * on page load because the intercom launcher was not initialized yet. It also appeared buggy and
 * would not work on page transition for no obvious reason
 *
 * As a temp workaround until we remove the button entirely in favor of a custom wone, we are
 * setting values in the DOM directly based on classnames, but this is fragile since intercom
 * could change the classnames at any time.
 */

const usePositionIntercomLauncher = () => {
  const router = useRouter()
  const theme = useContext(ThemeContext)
  // product tour
  const { productTourActive } = useContext(ProductTourContext)
  const { width } = useWindowSize()
  const isMobile = width && width < theme.metrics.tablet
  const isLargeDesktop = width && width > theme.metrics.listingFlowPageMaxWidth
  const [intercomLauncherVisible, setIntercomLauncherVisible] = useState(false)

  // minus 16px to properly align intercom launcher icon between "Next" button
  // and edge of the page when viewport is greater than 1920px
  let desktopListingFlowIntercomRight = 0

  if (width && width >= theme.metrics.listingFlowPageMaxWidth) {
    desktopListingFlowIntercomRight
      = (width - theme.metrics.listingFlowPageMaxWidth) / 2 + INTERCOM_DEFAULT_HORIZONTAL_PADDING - 16
  }

  // we will set this value to true if we don't find the launcher in our initial attempt
  const [needsDelayedSettingsUpdate, setNeedsDelayedSettingsUpdate] = useState(false)

  // we will only check for the launcher so many times before giving up
  const [timesAttempted, setTimesAttempted] = useState(0)

  // update intercom launcher settings
  const updateIntercomSettings = () => {
    if (productTourActive) return

    if (!window || typeof window === 'undefined' || !document) return

    const launcher
      = document && (document.getElementsByClassName(CLASS_NAME)?.[0] as HTMLElement | undefined)

    if (!launcher) {
      setIntercomLauncherVisible(false)
      return setNeedsDelayedSettingsUpdate(true)
    }

    setIntercomLauncherVisible(true)

    launcher.style.transition = 'all 1s ease-in-out' // make any on-page shift smoother

    if (router.pathname.includes(LISTING_FLOW_PATH)) {
      if (isMobile) {
        window.intercomSettings = intercomSettings.listingFlowPhoneViewportIntercomSetting
        launcher.style.bottom = `${INTERCOM_LISTING_FLOW_VERTICAL_PADDING_PHONE}px`
        launcher.style.right = `${INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING}px`
      } else {
        if (!isLargeDesktop) {
          window.intercomSettings = intercomSettings.listingFlowIntercomSetting
          launcher.style.bottom = `${INTERCOM_LISTING_FLOW_VERTICAL_PADDING}px`
          launcher.style.right = `${INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING}px`
        }

        if (isLargeDesktop) {
          if (isLargeDesktop) launcher.style.transition = ''

          window.intercomSettings = {
            app_id: process.env.INTERCOM_APP_ID,
            vertical_padding: INTERCOM_LISTING_FLOW_VERTICAL_PADDING,
            horizontal_padding: desktopListingFlowIntercomRight,
          }
          launcher.style.bottom = `${INTERCOM_LISTING_FLOW_VERTICAL_PADDING}px`
          launcher.style.right = `${desktopListingFlowIntercomRight}px`
        }
      }
    } else if (!isMobile) {
      window.intercomSettings = intercomSettings.defaultIntercomSetting
      launcher.style.bottom = `${INTERCOM_DEFAULT_VERTICAL_PADDING}px`
      launcher.style.right = `${INTERCOM_DEFAULT_HORIZONTAL_PADDING}px`
    }
  }

  // Update intercom messenger settings
  const updateIntercomMessengerSettings = () => {
    if (productTourActive) return

    if (!window || typeof window === 'undefined' || !document) return

    const launcher
      = document
      && (document.getElementsByClassName(CLASS_NAME_MESSENGER)?.[0] as HTMLElement | undefined)

    if (!launcher) {
      return setNeedsDelayedSettingsUpdate(true)
    }

    launcher.style.transition = 'all 1s ease-in-out' // make any on-page shift smoother

    if (router.pathname.includes(LISTING_FLOW_PATH)) {
      if (isMobile) {
        window.intercomSettings = intercomSettings.listingFlowPhoneViewportIntercomSetting
        launcher.style.bottom = `${INTERCOM_LISTING_FLOW_VERTICAL_PADDING_PHONE}px`
        launcher.style.right = `${INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING}px`
      } else {
        if (!isLargeDesktop) {
          window.intercomSettings = intercomSettings.listingFlowIntercomSetting
          launcher.style.bottom = `${INTERCOM_LISTING_FLOW_VERTICAL_PADDING}px`
          launcher.style.right = `${INTERCOM_LISTING_FLOW_HORIZONTAL_PADDING}px`
        }

        if (isLargeDesktop) {
          if (isLargeDesktop) launcher.style.transition = ''

          window.intercomSettings = {
            app_id: process.env.INTERCOM_APP_ID,
            vertical_padding: INTERCOM_LISTING_FLOW_VERTICAL_PADDING,
            horizontal_padding: desktopListingFlowIntercomRight,
          }
          launcher.style.bottom = `${INTERCOM_LISTING_FLOW_VERTICAL_PADDING}px`
          launcher.style.right = `${desktopListingFlowIntercomRight}px`
        }
      }
    } else if (!isMobile) {
      window.intercomSettings = intercomSettings.defaultIntercomSetting
      launcher.style.bottom = `${INTERCOM_DEFAULT_VERTICAL_PADDING}px`
      launcher.style.right = `${INTERCOM_DEFAULT_HORIZONTAL_PADDING}px`
    }
  }

  useEffect(() => {
    // base case: clean up if we started an interval and then found the launcher
    // otherwise if this value isn't true, we need to bail out early
    if (!needsDelayedSettingsUpdate) {
      if (checkerInterval) clearInterval(checkerInterval)

      return
    }

    // here we are effectively "pinging" an element in the DOM until we find it
    checkerInterval = setInterval(() => {
      const launcher
        = document && (document.getElementsByClassName(CLASS_NAME)?.[0] as HTMLElement | undefined)
      if (!launcher) return setTimesAttempted(prevTimesAttempted => prevTimesAttempted + 1)

      updateIntercomSettings()
      setNeedsDelayedSettingsUpdate(false)
    }, 1000)

    updateIntercomSettings()
    updateIntercomMessengerSettings()
  }, [needsDelayedSettingsUpdate])

  // updates intercom launcher & messenger settings
  useEffect(() => {
    if (!router.pathname.includes(LISTING_FLOW_PATH)) return

    // to make intercom appear inside the listingflow page when viewport
    // is greater than 1920px
    if (isLargeDesktop) {
      const settingsText = {
        app_id: process.env.INTERCOM_APP_ID,
        horizontal_padding: desktopListingFlowIntercomRight,
        vertical_padding: INTERCOM_LISTING_FLOW_VERTICAL_PADDING,
      }

      window.Intercom('update', settingsText)
    }

    // update intercom launcher
    if (intercomLauncherVisible) updateIntercomSettings()

    // update intercom messenger
    if (!intercomLauncherVisible) updateIntercomMessengerSettings()
  }, [width, router.asPath, intercomLauncherVisible, productTourActive])

  useEffect(() => {
    if (timesAttempted >= MAX_TRIES) setNeedsDelayedSettingsUpdate(false)
  }, [timesAttempted])
}

export default usePositionIntercomLauncher
