import * as T from '../types'

const NoInternet: any = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox={props.viewBox || '0 0 544 344'}
    fill={props.fill}
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M480.905 227.221C478.27 249.796 472.795 267.921 465.28 282.376C455.28 301.636 438.175 309.396 422.895 317.516C418.48 319.858 413.889 321.854 409.165 323.486C381.36 333.136 350.24 330.576 325.795 325.481C308.65 321.905 291.246 319.704 273.75 318.901C268.76 318.676 263.765 318.565 258.765 318.566C251.055 318.576 112.23 321.211 78.43 252.781C70.085 235.901 64.365 215.281 62.31 190.146C56.495 119.086 88.455 72.4162 129.68 41.9112C207.665 -15.7938 314.56 -13.3738 391.395 45.8512C442.575 85.3012 490.495 145.006 480.905 227.221Z"
      fill="#293189"
    />
    <path
      d="M465.28 282.376C455.28 301.636 441.675 314.396 426.395 322.516C421.98 324.858 417.389 326.854 412.665 328.486C384.86 338.136 353.74 335.576 329.295 330.481C312.15 326.905 294.746 324.704 277.25 323.901C272.26 323.676 267.265 323.565 262.265 323.566C254.555 323.576 246.847 323.863 239.14 324.426C189.055 328.106 112.23 321.211 78.4299 252.781L163 88.9812L194.475 169.791L204.52 195.586L232.205 157.596L246.5 137.981L311.6 212.616L330 171.981L457.39 275.941L464.495 281.736L465.28 282.376Z"
      fill="#F2F2F2"
    />
    <path
      opacity="0.1"
      d="M151 141.481L164 112.981L182 154.481L166.5 124.481L151 141.481Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M277.25 323.901C272.26 323.676 267.265 323.565 262.265 323.566L204.52 195.586L277.25 323.901Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M232.206 157.595L246.5 146.481L311.5 221.481L245.5 157.481L232.206 157.595Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M426.395 322.516C421.98 324.858 417.389 326.854 412.665 328.486L311.6 212.616L426.395 322.516Z"
      fill="black"
    />
    <path
      d="M241 52.4812C241.828 52.4812 242.5 51.8096 242.5 50.9812C242.5 50.1528 241.828 49.4812 241 49.4812C240.172 49.4812 239.5 50.1528 239.5 50.9812C239.5 51.8096 240.172 52.4812 241 52.4812Z"
      fill="#F2F2F2"
    />
    <path
      d="M199.5 103.981C200.328 103.981 201 103.31 201 102.481C201 101.653 200.328 100.981 199.5 100.981C198.672 100.981 198 101.653 198 102.481C198 103.31 198.672 103.981 199.5 103.981Z"
      fill="#F2F2F2"
    />
    <path
      d="M301 112.481C301.552 112.481 302 112.033 302 111.481C302 110.929 301.552 110.481 301 110.481C300.448 110.481 300 110.929 300 111.481C300 112.033 300.448 112.481 301 112.481Z"
      fill="#F2F2F2"
    />
    <path
      d="M91 134.981C91.5523 134.981 92 134.533 92 133.981C92 133.429 91.5523 132.981 91 132.981C90.4477 132.981 90 133.429 90 133.981C90 134.533 90.4477 134.981 91 134.981Z"
      fill="#F2F2F2"
    />
    <path
      d="M307 50.4812C307.552 50.4812 308 50.0335 308 49.4812C308 48.9289 307.552 48.4812 307 48.4812C306.448 48.4812 306 48.9289 306 49.4812C306 50.0335 306.448 50.4812 307 50.4812Z"
      fill="#F2F2F2"
    />
    <path
      d="M369 98.4812C369.552 98.4812 370 98.0335 370 97.4812C370 96.9289 369.552 96.4812 369 96.4812C368.448 96.4812 368 96.9289 368 97.4812C368 98.0335 368.448 98.4812 369 98.4812Z"
      fill="#F2F2F2"
    />
    <path
      d="M430 174.481C430.552 174.481 431 174.033 431 173.481C431 172.929 430.552 172.481 430 172.481C429.448 172.481 429 172.929 429 173.481C429 174.033 429.448 174.481 430 174.481Z"
      fill="#F2F2F2"
    />
    <path
      d="M153 60.611H151.62V59.231H150.88V60.611H149.5V61.3515H150.88V62.731H151.62V61.3515H153V60.611Z"
      fill="#F2F2F2"
    />
    <path
      d="M424 212.111H422.62V210.731H421.88V212.111H420.5V212.851H421.88V214.231H422.62V212.851H424V212.111Z"
      fill="#F2F2F2"
    />
    <path
      d="M544 306.981C544 315.266 422.221 343.981 272 343.981C121.779 343.981 0 315.266 0 306.981C0 298.697 121.779 313.981 272 313.981C422.221 313.981 544 298.697 544 306.981Z"
      fill="#3F3D56"
    />
    <path
      opacity="0.1"
      d="M544 306.981C544 315.266 422.221 343.981 272 343.981C121.779 343.981 0 315.266 0 306.981C0 298.697 121.779 313.981 272 313.981C422.221 313.981 544 298.697 544 306.981Z"
      fill="black"
    />
    <path
      d="M272 321.981C422.221 321.981 544 315.265 544 306.981C544 298.697 422.221 291.981 272 291.981C121.779 291.981 0 298.697 0 306.981C0 315.265 121.779 321.981 272 321.981Z"
      fill="#3F3D56"
    />
    <path
      d="M284 285.981C284 302.55 276.613 297.981 267.5 297.981C258.387 297.981 251 302.55 251 285.981C251 269.413 267.5 237.981 267.5 237.981C267.5 237.981 284 269.413 284 285.981Z"
      fill="#FF6584"
    />
    <path
      opacity="0.1"
      d="M275 292.32C275 299.852 271.642 297.775 267.5 297.775C263.358 297.775 260 299.852 260 292.32C260 284.789 267.5 270.502 267.5 270.502C267.5 270.502 275 284.789 275 292.32Z"
      fill="black"
    />
    <rect
      x="244.5"
      y="302.481"
      width="46"
      height="9"
      rx="4.5"
      fill="#2F2E41"
    />
    <rect
      x="244.5"
      y="293.481"
      width="46"
      height="9"
      rx="4.5"
      fill="#2F2E41"
    />
    <path
      d="M68.5 245.264C68.5 272.936 85.8597 295.327 107.313 295.327L68.5 245.264Z"
      fill="#3F3D56"
    />
    <path
      d="M107.313 295.327C107.313 267.345 126.685 244.701 150.626 244.701L107.313 295.327Z"
      fill="#595AD3"
    />
    <path
      d="M82.5627 247.772C82.5627 274.058 93.6327 295.327 107.313 295.327L82.5627 247.772Z"
      fill="#595AD3"
    />
    <path
      d="M107.313 295.327C107.313 259.572 129.705 230.639 157.376 230.639L107.313 295.327Z"
      fill="#3F3D56"
    />
    <path
      d="M99.1477 295.68C99.1477 295.68 104.652 295.511 106.311 294.329C107.97 293.148 114.778 291.738 115.189 293.632C115.601 295.527 123.461 303.054 117.247 303.104C111.033 303.155 102.808 302.136 101.152 301.128C99.4963 300.119 99.1477 295.68 99.1477 295.68Z"
      fill="#A8A8A8"
    />
    <path
      opacity="0.2"
      d="M117.358 302.445C111.144 302.495 102.919 301.477 101.263 300.468C100.002 299.7 99.4996 296.944 99.3315 295.673C99.215 295.678 99.1477 295.68 99.1477 295.68C99.1477 295.68 99.4963 300.119 101.152 301.128C102.808 302.136 111.033 303.154 117.247 303.104C119.041 303.09 119.661 302.452 119.627 301.506C119.377 302.077 118.693 302.434 117.358 302.445Z"
      fill="black"
    />
    <path
      d="M360.714 263.531C360.714 282.545 372.643 297.932 387.385 297.932L360.714 263.531Z"
      fill="#3F3D56"
    />
    <path
      d="M387.385 297.932C387.385 278.703 400.697 263.144 417.147 263.144L387.385 297.932Z"
      fill="#595AD3"
    />
    <path
      d="M370.378 265.255C370.378 283.316 377.984 297.932 387.385 297.932L370.378 265.255Z"
      fill="#595AD3"
    />
    <path
      d="M387.385 297.932C387.385 273.362 402.771 253.481 421.786 253.481L387.385 297.932Z"
      fill="#3F3D56"
    />
    <path
      d="M381.774 298.174C381.774 298.174 385.556 298.058 386.696 297.246C387.836 296.434 392.514 295.465 392.797 296.767C393.08 298.069 398.481 303.241 394.211 303.276C389.941 303.31 384.289 302.611 383.151 301.918C382.013 301.225 381.774 298.174 381.774 298.174Z"
      fill="#A8A8A8"
    />
    <path
      opacity="0.2"
      d="M394.287 302.823C390.017 302.857 384.365 302.157 383.227 301.464C382.361 300.937 382.016 299.043 381.9 298.169C381.82 298.173 381.774 298.174 381.774 298.174C381.774 298.174 382.013 301.225 383.151 301.918C384.289 302.611 389.941 303.31 394.211 303.276C395.444 303.266 395.869 302.827 395.846 302.178C395.675 302.57 395.205 302.815 394.287 302.823Z"
      fill="black"
    />
    <path
      d="M446.906 309.35C446.906 309.35 452.586 308.485 454.157 307.054C455.727 305.623 462.602 303.31 463.265 305.221C463.928 307.131 473.012 313.944 466.582 314.774C460.152 315.604 451.505 315.579 449.664 314.741C447.823 313.904 446.906 309.35 446.906 309.35Z"
      fill="#A8A8A8"
    />
    <path
      opacity="0.2"
      d="M466.614 314.077C460.184 314.907 451.538 314.882 449.697 314.044C448.295 313.407 447.429 310.615 447.096 309.319C446.976 309.339 446.906 309.35 446.906 309.35C446.906 309.35 447.823 313.904 449.664 314.741C451.505 315.579 460.152 315.604 466.582 314.774C468.438 314.535 469 313.796 468.846 312.821C468.66 313.444 467.996 313.899 466.614 314.077Z"
      fill="black"
    />
    <path
      d="M307.13 308.939C307.13 308.939 311.923 308.209 313.249 307.001C314.574 305.793 320.376 303.841 320.936 305.454C321.496 307.066 329.162 312.816 323.735 313.516C318.308 314.217 311.011 314.196 309.457 313.489C307.904 312.782 307.13 308.939 307.13 308.939Z"
      fill="#A8A8A8"
    />
    <path
      opacity="0.2"
      d="M323.762 312.928C318.335 313.629 311.038 313.608 309.485 312.901C308.301 312.363 307.571 310.007 307.29 308.913C307.189 308.93 307.13 308.939 307.13 308.939C307.13 308.939 307.904 312.782 309.457 313.489C311.011 314.196 318.308 314.217 323.735 313.517C325.301 313.315 325.776 312.691 325.646 311.869C325.489 312.394 324.928 312.778 323.762 312.928Z"
      fill="black"
    />
    <path
      d="M61.1946 306.545C61.1946 306.545 64.9263 305.977 65.958 305.037C66.9897 304.097 71.5066 302.577 71.9424 303.832C72.3783 305.088 78.3463 309.564 74.1216 310.109C69.8969 310.654 64.2161 310.638 63.0065 310.088C61.7969 309.537 61.1946 306.545 61.1946 306.545Z"
      fill="#A8A8A8"
    />
    <path
      opacity="0.2"
      d="M74.1428 309.651C69.9181 310.196 64.2373 310.18 63.0277 309.63C62.1065 309.211 61.538 307.377 61.319 306.525C61.2402 306.538 61.1946 306.545 61.1946 306.545C61.1946 306.545 61.7969 309.537 63.0065 310.088C64.2161 310.638 69.8969 310.654 74.1216 310.109C75.3411 309.952 75.7102 309.466 75.6094 308.826C75.4867 309.235 75.0505 309.534 74.1428 309.651Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M191.848 300.659C191.848 315.769 170.786 311.096 144.998 311.096C119.21 311.096 98.4612 315.769 98.4612 300.659C98.4612 285.549 119.52 282.263 145.308 282.263C171.096 282.263 191.848 285.549 191.848 300.659Z"
      fill="black"
    />
    <path
      d="M191.848 296.939C191.848 312.049 170.786 307.375 144.998 307.375C119.21 307.375 98.4612 312.049 98.4612 296.939C98.4612 281.829 119.52 278.542 145.308 278.542C171.096 278.542 191.848 281.829 191.848 296.939Z"
      fill="#3F3D56"
    />
    <path
      d="M195.863 227.711C194.621 228.346 193.439 229.094 192.335 229.947C191.226 230.806 190.376 231.956 189.882 233.268C189.26 231.813 188.752 230.312 188.362 228.778C187.486 226.026 186.45 223.327 185.258 220.696C187.214 219.95 188.91 218.65 190.137 216.954C192.537 219.343 195.004 221.835 196.198 225.004C196.404 225.55 197.002 226.132 197.141 226.621C197.338 227.312 196.387 227.422 195.863 227.711Z"
      fill="#FFBFC5"
    />
    <path
      d="M218.446 285.954C221.067 286.782 223.512 288.09 225.655 289.811C225.816 289.912 225.933 290.071 225.983 290.255C226.037 290.67 225.483 290.839 225.08 290.955C223.897 291.296 223.141 292.408 222.269 293.278C221.398 294.148 219.998 294.83 218.981 294.135C218.714 293.931 218.481 293.685 218.291 293.408L213.996 287.802C215.452 286.988 216.983 286.316 218.568 285.795"
      fill="#F1F1F1"
    />
    <path
      d="M226.166 287.12C226.735 287.7 226.982 288.535 227.54 289.125C227.915 289.483 228.344 289.78 228.811 290.007L232.097 291.816C232.602 292.114 233.133 292.365 233.684 292.566C234.304 292.738 234.934 292.87 235.57 292.959C236.481 293.13 237.372 293.393 238.229 293.744C238.874 294.008 239.592 294.463 239.589 295.159C239.563 295.499 239.424 295.821 239.195 296.074C237.899 297.706 235.606 298.067 233.534 298.287C229.048 298.761 224.457 299.116 220.072 298.059C219.216 297.901 218.407 297.551 217.707 297.035C216.383 295.939 216.12 293.941 216.614 292.295C216.754 291.664 217.127 291.109 217.659 290.742C218.353 290.344 219.219 290.528 220.017 290.467C220.866 290.351 221.689 290.089 222.448 289.692C222.95 289.466 223.527 289.175 223.634 288.578C223.734 288.025 223.084 287.471 223.221 286.977C223.524 285.881 225.643 286.585 226.166 287.12Z"
      fill="#575A89"
    />
    <path
      opacity="0.1"
      d="M226.166 287.12C226.735 287.7 226.982 288.535 227.54 289.125C227.915 289.483 228.344 289.78 228.811 290.007L232.097 291.816C232.602 292.114 233.133 292.365 233.684 292.566C234.304 292.738 234.934 292.87 235.57 292.959C236.481 293.13 237.372 293.393 238.229 293.744C238.874 294.008 239.592 294.463 239.589 295.159C239.563 295.499 239.424 295.821 239.195 296.074C237.899 297.706 235.606 298.067 233.534 298.287C229.048 298.761 224.457 299.116 220.072 298.059C219.216 297.901 218.407 297.551 217.707 297.035C216.383 295.939 216.12 293.941 216.614 292.295C216.754 291.664 217.127 291.109 217.659 290.742C218.353 290.344 219.219 290.528 220.017 290.467C220.866 290.351 221.689 290.089 222.448 289.692C222.95 289.466 223.527 289.175 223.634 288.578C223.734 288.025 223.084 287.471 223.221 286.977C223.524 285.881 225.643 286.585 226.166 287.12Z"
      fill="black"
    />
    <path
      d="M233.791 305.069C234.251 305.372 234.711 305.676 235.171 305.98C235.594 306.206 235.943 306.549 236.174 306.97C236.473 307.673 236.068 308.469 235.618 309.087C234.267 310.934 232.423 312.363 230.298 313.21C229.561 313.516 228.782 313.711 227.987 313.786C227.805 313.814 227.619 313.791 227.45 313.718C227.029 313.496 227.09 312.894 227.085 312.419C227.07 311.038 226.027 309.917 225.09 308.903C224.153 307.889 223.198 306.64 223.412 305.276C223.607 304.473 224.018 303.739 224.602 303.154C225.121 302.502 225.73 301.928 226.411 301.447C227.386 300.809 227.729 301.153 228.647 301.737C230.37 302.835 232.084 303.946 233.791 305.069Z"
      fill="#F1F1F1"
    />
    <path
      d="M229.304 311.795C230.066 311.988 230.874 311.805 231.479 311.305C232.071 310.805 232.552 310.187 232.891 309.49C233.226 308.869 233.518 308.226 233.765 307.565C234.054 306.791 234.321 305.935 235.001 305.466C235.825 304.896 236.987 305.119 237.824 305.668C238.614 306.285 239.34 306.98 239.989 307.742C241.73 309.365 243.84 310.54 246.137 311.163C248.424 311.78 250.743 312.276 253.082 312.648C253.55 312.71 254.003 312.856 254.419 313.078C255.359 313.651 255.673 314.932 255.411 316.002C255.079 317.058 254.484 318.012 253.681 318.774C253.049 319.524 252.265 320.131 251.381 320.555C250.633 320.828 249.846 320.977 249.05 320.996C245.197 321.258 241.326 320.955 237.56 320.096C236.177 319.721 234.77 319.44 233.348 319.256C232.482 319.179 231.609 319.203 230.741 319.144C229.016 319.026 227.328 318.581 225.769 317.833C225.389 317.694 225.069 317.428 224.862 317.081C224.75 316.761 224.727 316.416 224.795 316.084L225.371 311.044C225.421 310.605 225.567 310.06 226.003 309.992C226.418 309.927 226.72 310.352 226.981 310.681C228.023 311.929 229.778 312.313 231.246 311.614"
      fill="#575A89"
    />
    <path
      d="M195.933 248.243C198.462 247.958 201.395 247.91 203.105 249.795C204.154 250.951 204.472 252.57 204.835 254.088C205.402 256.636 206.325 259.093 207.575 261.386C208.379 262.806 209.354 264.139 209.972 265.65C210.604 267.198 210.837 268.874 211.207 270.506C212.689 276.952 216.308 282.708 221.475 286.838C220.563 289.319 217.924 290.737 215.357 291.371C214.238 291.846 212.938 291.492 212.214 290.514C211.013 289.218 209.895 287.848 208.865 286.412C205.166 281.472 201.41 276.427 199.395 270.594C198.688 268.547 197.98 266.157 195.991 265.301C194.856 264.814 193.559 264.966 192.341 265.172C189.62 265.634 186.941 266.321 184.334 267.228C181.555 268.195 178.372 269.385 175.828 267.905C174.306 267.02 173.4 265.32 173.065 263.591C172.814 261.845 172.812 260.073 173.058 258.327C173.075 257.537 173.265 256.76 173.614 256.051C173.966 255.52 174.418 255.063 174.944 254.705C177.215 252.979 179.742 251.618 182.433 250.67C185.003 249.764 187.766 249.457 190.448 249.009C192.269 248.705 194.098 248.45 195.933 248.243Z"
      fill="#575A89"
    />
    <path
      opacity="0.1"
      d="M195.933 248.243C198.462 247.958 201.395 247.91 203.105 249.795C204.154 250.951 204.472 252.57 204.835 254.088C205.402 256.636 206.325 259.093 207.575 261.386C208.379 262.806 209.354 264.139 209.972 265.65C210.604 267.198 210.837 268.874 211.207 270.506C212.689 276.952 216.308 282.708 221.475 286.838C220.563 289.319 217.924 290.737 215.357 291.371C214.238 291.846 212.938 291.492 212.214 290.514C211.013 289.218 209.895 287.848 208.865 286.412C205.166 281.472 201.41 276.427 199.395 270.594C198.688 268.547 197.98 266.157 195.991 265.301C194.856 264.814 193.559 264.966 192.341 265.172C189.62 265.634 186.941 266.321 184.334 267.228C181.555 268.195 178.372 269.385 175.828 267.905C174.306 267.02 173.4 265.32 173.065 263.591C172.814 261.845 172.812 260.073 173.058 258.327C173.075 257.537 173.265 256.76 173.614 256.051C173.966 255.52 174.418 255.063 174.944 254.705C177.215 252.979 179.742 251.618 182.433 250.67C185.003 249.764 187.766 249.457 190.448 249.009C192.269 248.705 194.098 248.45 195.933 248.243Z"
      fill="black"
    />
    <path
      d="M210.41 266.479C211.583 267.071 212.661 267.836 213.606 268.749C214.581 269.899 215.322 271.228 215.787 272.662C215.995 273.141 216.123 273.651 216.165 274.171C216.203 274.698 215.997 275.215 215.605 275.57C215.137 275.871 214.581 276.007 214.027 275.956C213.294 275.962 212.561 275.943 211.828 275.899C211.383 275.895 210.942 275.812 210.526 275.652C210.145 275.458 209.802 275.198 209.511 274.884C208.223 273.687 207.167 272.261 206.398 270.679C205.867 269.509 204.993 266.811 205.829 265.593C206.69 264.338 209.5 265.963 210.41 266.479Z"
      fill="#FFBFC5"
    />
    <path
      d="M174.954 266.061C178.694 267.259 182.675 267.396 186.585 267.762C193.163 268.377 199.66 269.661 205.977 271.595C207.295 271.916 208.538 272.494 209.633 273.297C210.478 274.078 211.194 274.989 211.754 275.995C212.781 277.561 213.712 279.188 214.54 280.867C215.25 282.385 215.792 283.976 216.453 285.517C218.673 290.673 222.184 295.17 226.648 298.575C227.463 299.195 228.372 299.871 228.581 300.874C228.675 301.519 228.57 302.178 228.279 302.762C227.315 305.008 225.313 306.642 223.213 307.892C222.31 308.43 221.317 308.975 220.873 309.928C217.681 308.61 215.308 305.801 213.333 302.968C211.358 300.135 209.606 297.091 207.074 294.742C205.959 293.707 204.692 292.806 203.815 291.562C202.477 289.665 202.115 287.069 200.313 285.605C198.498 284.13 195.894 284.331 193.567 284.559C181.646 285.727 169.633 285.597 157.74 284.172C155.267 283.875 152.727 283.498 150.585 282.227C148.444 280.957 146.776 278.573 147.079 276.102C147.364 273.788 149.291 271.954 151.439 271.048C153.586 270.142 155.96 269.98 158.277 269.719C163.961 269.165 169.559 267.937 174.954 266.061Z"
      fill="#575A89"
    />
    <path
      d="M140.756 270.865C141.049 268.904 141.811 267.046 142.289 265.12C142.529 263.909 142.832 262.711 143.198 261.532C143.21 261.499 143.224 261.466 143.236 261.433C143.482 261.413 143.706 261.285 143.849 261.083C143.914 260.911 143.932 260.725 143.901 260.544C143.891 260.352 143.897 260.159 143.918 259.968C144.7 258.656 145.555 257.389 146.478 256.173C148.097 253.878 149.5 251.44 150.9 249.005C152.048 247.009 153.198 245.009 154.149 242.912C154.861 241.34 155.46 239.715 156.248 238.178C159.052 232.71 164.092 228.682 169.513 225.788C174.713 223.011 180.335 221.14 185.802 218.932C185.943 219.07 186.071 219.221 186.184 219.382C189.138 223.42 189.456 228.726 190.672 233.579C191.163 235.539 191.819 237.492 191.818 239.512C191.816 242.493 190.391 245.267 188.975 247.891C187.709 250.398 186.214 252.784 184.51 255.017C183.673 256.052 182.752 257.016 181.911 258.048C178.464 262.274 176.474 267.47 174.54 272.568C173.419 275.52 171.589 278.139 170.357 281.046C169.884 282.163 169.611 283.368 169.017 284.426C168.422 285.483 167.388 286.414 166.175 286.419C165.82 286.41 165.467 286.354 165.126 286.255C160.734 285.125 157.056 282.219 153.406 279.528C152.755 279.048 152.099 278.571 151.416 278.138C150.726 277.701 150.009 277.307 149.292 276.915C148.749 276.617 148.206 276.32 147.663 276.022C145.883 275.047 144.101 274.07 142.247 273.246C141.689 273.022 141.154 272.747 140.647 272.424C140.642 271.903 140.678 271.381 140.756 270.865Z"
      fill="#595AD3"
    />
    <path
      d="M198.18 227.375C203.769 227.375 208.299 222.845 208.299 217.256C208.299 211.667 203.769 207.136 198.18 207.136C192.591 207.136 188.06 211.667 188.06 217.256C188.06 222.845 192.591 227.375 198.18 227.375Z"
      fill="#FFBFC5"
    />
    <path
      d="M204.987 212.521C205.488 213.397 206.485 213.865 207.479 213.692C208.455 213.496 209.302 212.893 209.805 212.033C210.297 211.179 210.591 210.225 210.667 209.242C210.826 208.48 210.674 207.685 210.245 207.035C209.868 206.67 209.434 206.367 208.962 206.138C208.31 205.73 207.762 205.176 207.363 204.519C207.08 204.051 206.877 203.54 206.622 203.056C205.701 201.308 203.911 199.886 201.936 199.951C200.987 199.982 200.083 200.342 199.148 200.506C196.011 201.056 192.601 199.396 189.69 200.691C188.974 201.039 188.308 201.483 187.711 202.011C186.892 202.686 186.104 203.398 185.349 204.146C184.692 204.706 184.195 205.429 183.909 206.242C183.513 207.727 184.453 209.386 183.855 210.801C183.65 211.206 183.406 211.59 183.125 211.947C182.465 212.915 183.183 216.696 183.11 217.866C183.025 218.438 183.171 219.021 183.515 219.487C183.992 220.019 184.795 220.066 185.51 220.074L186.823 220.09C187.02 220.104 187.217 220.083 187.406 220.03C187.664 219.915 187.891 219.74 188.068 219.52C189.16 218.369 189.397 214.604 190.984 214.618C191.225 214.604 191.464 214.666 191.669 214.795C191.956 215.006 192.041 215.393 192.245 215.686C192.723 216.251 193.515 216.436 194.194 216.141C194.85 215.838 195.42 215.377 195.854 214.8C196.286 214.224 196.811 213.723 197.406 213.317C198.252 212.824 199.208 212.609 200.031 212.052C200.719 211.586 201.11 210.91 201.728 210.395C202.235 209.958 202.973 209.916 203.527 210.294C204.5 210.803 204.543 211.636 204.987 212.521Z"
      fill="#2F2E41"
    />
    <path
      opacity="0.1"
      d="M181.605 230.351C183.969 230.015 186.377 230.208 188.657 230.917C189.418 231.091 190.105 231.498 190.622 232.083C190.916 232.526 191.107 233.029 191.182 233.555C191.647 235.975 191.349 238.48 190.329 240.723C190.181 240.95 190.138 241.229 190.211 241.489C190.313 241.645 190.448 241.778 190.604 241.879C190.892 242.156 191.047 242.542 191.031 242.94C191.015 243.338 190.829 243.711 190.52 243.963C190.331 244.101 190.084 244.22 190.047 244.451C190.007 244.703 190.243 244.9 190.427 245.076C190.975 245.612 191.211 246.39 191.054 247.141C190.955 247.438 190.887 247.745 190.851 248.056C190.889 248.436 191.001 248.806 191.181 249.144C191.489 249.905 191.594 250.734 191.486 251.548C191.388 251.933 191.379 252.336 191.459 252.725C191.569 252.982 191.72 253.221 191.905 253.431C192.633 254.412 192.792 256.053 191.737 256.669C192.663 257.144 193.185 258.154 193.037 259.184C193.667 258.847 194.431 259.277 194.956 259.762C195.481 260.246 196.017 260.839 196.731 260.874C197.228 260.899 197.752 260.641 198.206 260.846C198.896 261.157 198.872 262.276 199.551 262.612C200.146 262.906 200.975 262.426 201.483 262.854C201.659 263.004 201.749 263.234 201.92 263.39C202.305 263.743 202.906 263.593 203.428 263.572C204.686 263.608 205.832 264.307 206.439 265.411C207.022 266.503 207.373 267.703 207.471 268.937C207.605 269.607 207.591 270.299 207.432 270.964C207.258 271.64 206.696 272.146 206.005 272.25C199.794 272.652 193.666 271.163 187.619 269.69C185.843 269.258 184.036 268.752 182.555 267.681C181.209 266.707 180.22 265.331 179.254 263.979C177.475 261.487 175.684 258.975 174.41 256.19C172.771 252.606 172.04 248.685 171.323 244.809C171.077 243.77 170.989 242.7 171.063 241.634C171.278 240.388 171.754 239.202 172.461 238.153C173.316 236.763 174.261 235.43 175.29 234.164C176.855 232.16 179.103 230.803 181.605 230.351Z"
      fill="black"
    />
    <path
      d="M182.015 229.941C184.378 229.605 186.787 229.799 189.066 230.508C189.827 230.681 190.514 231.089 191.031 231.674C191.325 232.116 191.517 232.619 191.591 233.145C192.056 235.565 191.758 238.07 190.738 240.313C190.591 240.54 190.548 240.819 190.62 241.08C190.723 241.236 190.857 241.369 191.014 241.47C191.301 241.746 191.456 242.132 191.44 242.531C191.424 242.929 191.238 243.301 190.93 243.553C190.741 243.691 190.493 243.811 190.456 244.042C190.416 244.293 190.652 244.49 190.836 244.666C191.384 245.203 191.621 245.981 191.464 246.731C191.365 247.028 191.297 247.335 191.261 247.646C191.299 248.027 191.411 248.397 191.591 248.734C191.898 249.496 192.003 250.325 191.896 251.139C191.798 251.524 191.788 251.926 191.868 252.315C191.979 252.573 192.13 252.811 192.315 253.022C193.042 254.003 193.202 255.644 192.147 256.259C193.072 256.734 193.595 257.744 193.447 258.774C194.077 258.438 194.841 258.868 195.366 259.352C195.89 259.837 196.427 260.429 197.14 260.465C197.637 260.49 198.161 260.232 198.615 260.437C199.305 260.748 199.282 261.867 199.96 262.202C200.556 262.497 201.385 262.016 201.892 262.445C202.069 262.594 202.159 262.825 202.329 262.981C202.714 263.334 203.315 263.184 203.837 263.163C205.096 263.198 206.242 263.898 206.848 265.001C207.431 266.093 207.782 267.294 207.881 268.527C208.014 269.198 208.001 269.889 207.842 270.554C207.667 271.231 207.105 271.737 206.414 271.84C200.204 272.242 194.075 270.753 188.029 269.281C186.252 268.849 184.446 268.342 182.965 267.271C181.619 266.298 180.629 264.921 179.664 263.569C177.884 261.077 176.093 258.566 174.82 255.781C173.18 252.197 172.449 248.275 171.733 244.4C171.486 243.361 171.398 242.29 171.473 241.225C171.687 239.979 172.164 238.792 172.87 237.744C173.725 236.353 174.67 235.021 175.7 233.754C177.265 231.751 179.513 230.393 182.015 229.941Z"
      fill="#595AD3"
    />
  </svg>
)

export default NoInternet
