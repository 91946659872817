// These values come from the HomeLister design system. Do not add new values here unless they have
// also been added to the DS: https://www.figma.com/file/6zHr43vLq5VILtfkBCKGpu/%F0%9F%8E%A8-HomeLister-Design-System
export type Radius = 4 | 8 | 12 | 16 | 24 | 50

const radii: Record<Radius, Radius> = {
  4: 4,
  8: 8,
  12: 12,
  16: 16,
  24: 24,
  50: 50,
}

export type IRadii = {
  [key in Radius]: Radius
}

export default radii
